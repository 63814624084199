import type { UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { RuleEffect } from "@jsonforms/core"
import { DatabricksSQLDataSourceJsonSchema } from "src/DataAssets/connect-to-data/schemas/json-schemas/databricks-sql-data-source-json-schema"
import { getSchemaAndCatalogAsDatabaseFromDatabricksSQLConnectionString } from "src/DataAssets/connect-to-data/select-table-names/get-address-from-data-source"
import { Icon } from "src/ui/Icon/Icon"
import { theme } from "src/ui/themes/theme"

export const DatabricksSQLDataSourcePage1UISchema: UISchema<typeof DatabricksSQLDataSourceJsonSchema> = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/type",
      rule: {
        effect: RuleEffect.HIDE,
        condition: {},
      },
    },
    {
      type: "Control",
      scope: "#/properties/name",
      label: "Data Source name",
      options: {
        placeholderText: "Data Source name",
      },
    },
    {
      type: "Group",
      elements: [
        {
          type: "Control",
          scope: "#/properties/connection_string",
          label: "Connection string",
          options: {
            type: "password",
            required: true,
            placeholderText: `databricks://token:<token>@<host>:<port>?http_path=<http_path>&catalog=<catalog>&schema=<schema>`,
          },
          formItemProps: {
            rules: [
              {
                validator(rule, value: unknown) {
                  if (typeof value !== "string") {
                    return Promise.reject(new Error("Please enter a valid connection string"))
                  }
                  const { database, schema } =
                    getSchemaAndCatalogAsDatabaseFromDatabricksSQLConnectionString(value) ?? {}

                  if (!database) {
                    return Promise.reject(new Error("DatabricksSQL connection string must specify a catalog"))
                  }
                  if (!schema) {
                    return Promise.reject(new Error("DatabricksSQL connection string must specify a schema"))
                  }
                  return Promise.resolve()
                },
              },
            ],
            tooltip: {
              title: (
                <span>
                  Must use `databricks://` data-connector. See{" "}
                  <a
                    href="https://docs.greatexpectations.io/docs/oss/guides/connecting_to_your_data/fluent/database/connect_sql_source_data/#determine-your-connection-string-3"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: `${theme.colors.neutralColorPalette.whites.white}`, textDecoration: "underline" }}
                  >
                    GX Databricks SQL documentation
                  </a>{" "}
                  for more details.
                </span>
              ),
            },
          },
        },
      ],
    },
  ],
}

export const DatabricksSQLDataSourcePage2UISchema: UISchema<typeof DatabricksSQLDataSourceJsonSchema> = {
  type: "VerticalLayout",
  elements: [
    {
      label: "Data Asset",
      type: "Control",
      scope: "#/properties/assets",
      options: {
        removeButtonProps: {
          type: "text",
          icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
          children: "",
        },
      },
    },
  ],
}
