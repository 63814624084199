import { PageHeader } from "src/ui/PageHeader"
import { ExpandableContainer } from "src/ui/ExpandableContainer"
import { HeaderTypes } from "src/ui/PageHeader/types"
import { BodyRegular } from "src/ui/typography/Text"
import { Flex, Table } from "antd"
import { InviteUserModal } from "src/users/InviteUserModal"
import {
  BodyContainer,
  FlexContainer,
  StyledColumnHeader,
  StyledTable,
  StyledUserActionsWrapper,
} from "src/users/Users.styles"
import { ErrorState } from "src/ui/error"
import { formatOrgRole, sortRoles } from "src/organizations/roles"
import { useState } from "react"
import { DeleteUserModal } from "src/users/DeleteUserModal"
import { EditUserModal } from "src/users/EditUserModal"
import { OrganizationRole } from "src/api/graphql/graphql"
import { MainContent } from "src/layout/MainContent"
import { UserFragment, UsersDocument } from "src/api/graphql/graphql-operations"
import { useQuery } from "@apollo/client"
import { DropdownButton } from "src/ui/DropdownButton/DropdownButton"
import { nonNull } from "src/common/utils/nonNull"
import { useTheme } from "styled-components"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { UserAlert } from "src/users/UserAlert"

const { Column } = Table

interface RowActionsProps {
  isEditable: boolean
  isDeletable: boolean
  id: string
}

const Users = () => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [userIdToRemove, setUserIdToRemove] = useState("")
  const [userIdToEdit, setUserIdToEdit] = useState("")
  const { data, loading, error } = useQuery(UsersDocument, { notifyOnNetworkStatusChange: true })
  const users = data?.allUsers?.edges.map((e) => e?.node).filter(nonNull) ?? []
  const { spacing } = useTheme()
  const isOnTeamPlan = useIsFeatureEnabled("teamPlanEnabled")
  const isOnDeveloperPlan = !isOnTeamPlan // Users are either on the Team plan or Developer plan (default)
  const showUserAlert = isOnDeveloperPlan

  if (error) {
    console.error(error)
    return <ErrorState />
  }

  const headerData: HeaderTypes = {
    title: "Users",
    rootPath: "users",
  }

  const userColumns = [
    {
      title: "Email",
      dataIndex: "email",
      className: "email-column",
      sorter: (a: UserFragment, b: UserFragment) => (a.email && b.email ? a.email.localeCompare(b.email) : 0),
      render: (text: string) => {
        return (
          <FlexContainer $alignsItemsCenter $gap="xxs">
            <BodyRegular>{text}</BodyRegular>
          </FlexContainer>
        )
      },
    },
    {
      title: "Organizational Role",
      dataIndex: "organizationRole",
      sorter: (a: UserFragment, b: UserFragment) =>
        a.organizationRole && b.organizationRole ? -sortRoles(a.organizationRole, b.organizationRole) : 0,
      render: (role: OrganizationRole) => {
        return (
          <FlexContainer $alignsItemsCenter $gap="xxs">
            <BodyRegular>{formatOrgRole(role)}</BodyRegular>
          </FlexContainer>
        )
      },
    },
  ]

  const onDeleteRequest = (id: string) => {
    setIsDeleteModalVisible(true)
    setUserIdToRemove(id)
  }

  const onEdit = (id: string) => {
    setUserIdToEdit(id)
  }

  const onResetDeleteModal = () => {
    setIsDeleteModalVisible(false)
    setUserIdToRemove("")
  }

  const closeEditModal = () => {
    setUserIdToEdit("")
  }

  const RowActions = ({ isEditable, isDeletable, id }: RowActionsProps) => {
    const options = [
      { value: "Edit", key: "edit", isDisabled: !isEditable, onSelect: () => onEdit(id) },
      { value: "Delete", key: "delete", isDisabled: !isDeletable, onSelect: () => onDeleteRequest(id), danger: true },
    ]

    return (
      <StyledUserActionsWrapper>
        <DropdownButton ariaLabel="actions" buttonConfig={{ icon: "threeDotsVertical" }} dropdownList={options} />
      </StyledUserActionsWrapper>
    )
  }

  return (
    <PageHeader headerContent={headerData}>
      <MainContent>
        <DeleteUserModal
          userIdToRemove={userIdToRemove}
          isModalVisible={isDeleteModalVisible}
          onReset={onResetDeleteModal}
        />
        <EditUserModal
          user={users.find(({ id }) => id === userIdToEdit)}
          visible={!!userIdToEdit}
          closeModal={closeEditModal}
        />
        <BodyContainer>
          <Flex gap={parseInt(spacing.vertical.xs)} vertical>
            {showUserAlert && <UserAlert />}
            <ExpandableContainer
              title="Users"
              subtitle={`${users.length} Total`}
              collapsible={false}
              contentSpacing={false}
              actionMenu={<InviteUserModal />}
              background="white"
            >
              <StyledTable
                dataSource={users}
                loading={loading}
                pagination={{ hideOnSinglePage: true, position: ["bottomRight"] }}
              >
                {userColumns.map((col) => (
                  <Column
                    key={col.dataIndex}
                    dataIndex={col.dataIndex}
                    title={<StyledColumnHeader>{col.title}</StyledColumnHeader>}
                    render={col.render}
                    sorter={col.sorter}
                  />
                ))}
                <Column
                  dataIndex=""
                  title=""
                  render={(user) => {
                    return <RowActions isEditable={true} isDeletable={true} id={user.id} />
                  }}
                />
              </StyledTable>
            </ExpandableContainer>
          </Flex>
        </BodyContainer>
      </MainContent>
    </PageHeader>
  )
}

export default Users
