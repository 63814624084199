import styled from "styled-components"
import { CaptionSemiBold } from "src/ui/typography/Text"
import { InlinePillContainer } from "src/Expectation/InlinePillContainer"
import { Tooltip } from "antd"
import { parseStringWithColor } from "src/Expectation/utils/parseStringWithColor"
import { useAnalytics } from "src/analytics/useAnalytics"
import { useEffect } from "react"

const FunctionArgText = styled(CaptionSemiBold)`
  display: inline-flex;
`

const EvaluationParameters = ({ param, tooltip }: { param: string; tooltip?: string }) => {
  const posthog = useAnalytics()
  const parsedTextObject = parseStringWithColor(param)
  const styledTextMap = parsedTextObject.map((obj, idx) => {
    if (obj.styleType === "param") {
      return (
        <FunctionArgText as="span" key={idx} $colorTextSecondary>
          {obj.value}
        </FunctionArgText>
      )
    }
    return obj.value
  })

  useEffect(() => {
    posthog?.capture("Expectation with Evaluation Param")
  }, [posthog])

  const onHover = () => {
    posthog?.capture("Hover over Evaluation Param value in Validation Result")
  }
  return (
    <Tooltip
      onOpenChange={onHover}
      autoAdjustOverflow
      title={
        tooltip && (
          <FunctionArgText as="span">
            <CaptionSemiBold $gxAccentMedium>&quot;$PARAMETER&quot;:</CaptionSemiBold>
            <CaptionSemiBold $white>&quot;{tooltip}&quot;</CaptionSemiBold>
          </FunctionArgText>
        )
      }
    >
      <InlinePillContainer>
        <CaptionSemiBold as="span" $gxAccentMedium>
          {styledTextMap}
        </CaptionSemiBold>
      </InlinePillContainer>
    </Tooltip>
  )
}

export default EvaluationParameters
