import { useFragment_experimental } from "@apollo/client"
import { ExecutableDefinitionNode } from "graphql"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { JobFragmentDocument, JobFragmentWithTableNamesDocument } from "src/layout/routes/PollForJobs"

export const useJobByID = (jobId: string | undefined) => {
  const isCheckboxAssetCreationEnabled = useIsFeatureEnabled("checkboxAssetCreation")
  // get the latest state of a job from the cache
  const { data: jobDataWithTableNames } = useFragment_experimental({
    fragment: JobFragmentWithTableNamesDocument,
    fragmentName: (JobFragmentWithTableNamesDocument.definitions[0] as ExecutableDefinitionNode).name?.value,
    from: {
      __typename: "Job",
      id: jobId,
    },
  })
  const { data: jobData } = useFragment_experimental({
    fragment: JobFragmentDocument,
    fragmentName: (JobFragmentDocument.definitions[0] as ExecutableDefinitionNode).name?.value,
    from: {
      __typename: "Job",
      id: jobId,
    },
  })

  return isCheckboxAssetCreationEnabled ? jobDataWithTableNames : jobData
}
