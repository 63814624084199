import { CheckCircleTwoTone, LoadingOutlined } from "@ant-design/icons"
import { Col, Flex, Form, Input, Row, Spin, Tooltip } from "antd"
import { SpanSemiBold, SubtitleSemiBold } from "src/ui/typography/Text"
import { Image } from "src/ui/Image"
import { Button } from "src/ui/Button/Button"
import { copyToClipboard } from "src/common/utils/clipboard"
import { AgentSetupMockModal } from "src/agentSetup/AgentSetupMockModal"
import { PYTHON_QUICKSTART_URL, AGENT_DEPLOY_GUIDE_URL } from "src/common/config"
import styled, { useTheme } from "styled-components"
import { useOrganizations } from "src/organizations/useOrganizations"
import { geLogoStyle } from "src/ui/OnboardingSurvey/styles"
import { WistiaVideoPlayer } from "src/agentSetup/WistiaVideoPlayer"
import { theme } from "src/ui/themes/theme"
import { GenerateTokenFormItem } from "src/agentSetup/GenerateTokenFormItem"
import { useAnalytics } from "src/analytics/useAnalytics"

//992px is the point where the Col size changes from lg to md
const ResponsiveDividedModal = styled.div`
  width: 970px;

  @media screen and (width <= 992px) {
    width: 485px;
  }
`

const videoPlayerColumn = (
  <div style={{ padding: "24px 0px" }}>
    <Flex align="center" justify="center" gap={theme.spacing.vertical.xs} style={{ padding: "0px 16px 60px 16px" }}>
      <Image aria-label="Welcome to GX Cloud!" type="geLogoSmall" svgProps={geLogoStyle} style={geLogoStyle} />
      <SubtitleSemiBold>Prepare your environment for GX Cloud</SubtitleSemiBold>
    </Flex>
    <WistiaVideoPlayer videoId="z2t99ny9sg" wrapper="wistia-player-container-agent-setup" />
  </div>
)

export function AgentSetupInstructions({ onHelpClick }: { onHelpClick: () => void }) {
  const theme = useTheme()
  const orgId = useOrganizations().currentOrg?.id ?? ""
  const analytics = useAnalytics()

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer")
    if (newWindow) newWindow.opener = null
  }

  const onPythonApiClick = () => {
    analytics?.capture("agent_setup.prefer_to_use_api_clicked")
    openInNewTab(PYTHON_QUICKSTART_URL)
  }

  const onDeployClick = () => {
    analytics?.capture("agent_setup.setup_guide_clicked")
    openInNewTab(AGENT_DEPLOY_GUIDE_URL)
  }

  const modalContent = (
    <ResponsiveDividedModal>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          style={{
            backgroundColor: theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgPrimary,
            padding: "36px",
          }}
        >
          {videoPlayerColumn}
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ padding: "36px" }}>
          <div style={{ padding: "26px 12px 0 0" }}>
            GX Cloud does not connect directly to your data. The <SpanSemiBold>GX Agent</SpanSemiBold> keeps your data
            secure and lets you:
            <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
              <li style={{ padding: "4px 0" }}>
                <CheckCircleTwoTone
                  twoToneColor={theme.colors.primaryColors.gxAccentMedium}
                  style={{ paddingRight: 8 }}
                />
                Connect to Data Sources
              </li>
              <li style={{ padding: "4px 0" }}>
                <CheckCircleTwoTone
                  twoToneColor={theme.colors.primaryColors.gxAccentMedium}
                  style={{ paddingRight: 8 }}
                />
                Create Expectations and validate data
              </li>
              <li style={{ padding: "4px 0" }}>
                <CheckCircleTwoTone
                  twoToneColor={theme.colors.primaryColors.gxAccentMedium}
                  style={{ paddingRight: 8 }}
                />
                Collaborate with team members
              </li>
            </ul>
          </div>
          <Form layout="vertical">
            <GenerateTokenFormItem />
            <Form.Item label="Organization ID:">
              <Flex justify="space-between" gap={10}>
                <Input value={orgId} aria-label="Organization ID" />
                <Button
                  icon="clipboard"
                  aria-label="Copy Organization ID"
                  title="Copy Organization ID"
                  onClick={() => copyToClipboard(orgId, "Organization ID copied to clipboard")}
                />
              </Flex>
            </Form.Item>
          </Form>
          <Flex vertical align="center" gap="24px" style={{ padding: "24px 0" }}>
            <Button icon="file" type="primary" iconSize="16px" onClick={onDeployClick}>
              Deploy the GX Agent
            </Button>
            <div>
              <Spin
                style={{ paddingRight: "8px", color: theme.colors.primaryColors.gxAccentMedium }}
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
              Waiting for an active GX Agent
            </div>
            <Flex>
              <Tooltip
                title="If you don't want to deploy the GX Agent, connect to a Data Source with the Python API to continue to GX Cloud."
                placement="bottom"
              >
                <Button icon="file" type="link" iconSize="16px" onClick={onPythonApiClick}>
                  I prefer to use the Python API
                </Button>
              </Tooltip>
              <Button icon="questionCircle" type="link" iconSize="16px" onClick={onHelpClick}>
                I need help
              </Button>
            </Flex>
          </Flex>
        </Col>
      </Row>
    </ResponsiveDividedModal>
  )

  return <AgentSetupMockModal content={modalContent} />
}
