import { Result } from "antd"
import { AppModal, StyledCopyButton } from "src/ui/AppModal/AppModal"
import { CodeSnippetEditor } from "src/ui/CodeSnippetEditor/CodeSnippetEditor"
import { CodeSnippetAlert } from "src/ui/CodeSnippetEditor/CodeSnippetAlert"
import { copyToClipboard } from "src/common/utils/clipboard"
import { LoadingState } from "src/ui/LoadingState/LoadingState"
import { BodyRegular } from "src/ui/typography/Text/Text"
import { useQuery } from "@apollo/client"
import { RunCheckpointModalDocument } from "src/api/graphql/graphql-operations"

interface RunCheckpointSnippetModalProps {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
  checkpointId: string
}

export function RunCheckpointSnippetModal(props: RunCheckpointSnippetModalProps) {
  const { data, loading, error } = useQuery(RunCheckpointModalDocument, {
    variables: {
      id: props.checkpointId,
    },
    skip: !props.isVisible,
  })

  const snippet = data?.checkpoint?.snippet || ""

  return (
    <AppModal
      open={props.isVisible}
      title="Run Checkpoint"
      width={750}
      onCancel={(e) => {
        e.stopPropagation()
        props.setIsVisible(false)
      }}
      cancelText="Done"
      cancelButtonProps={{ type: "primary" }}
      okButtonProps={{ style: { display: "none" } }}
    >
      {error && (
        <Result
          status="warning"
          title="There was a problem fetching this Checkpoint."
          extra={<BodyRegular>{error?.message}</BodyRegular>}
        />
      )}
      {!error && loading && <LoadingState loading absolute={false} />}
      {!error && !loading && (
        <>
          <CodeSnippetAlert />
          <p>Edit configuration and run the following code snippet:</p>
          <CodeSnippetEditor
            value={!snippet ? "Checkpoint code snippet not found" : snippet}
            language="python"
            readOnly
            fontSize={12}
            showLineNumbers={false}
            height="250px"
          />
          <StyledCopyButton
            icon="clipboard"
            aria-label="Copy"
            disabled={!snippet}
            onClick={() => copyToClipboard(snippet)}
          >
            Copy snippet
          </StyledCopyButton>
        </>
      )}
    </AppModal>
  )
}
