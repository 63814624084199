import React, { FunctionComponent, useMemo } from "react"
import { CheckpointRunSelector } from "src/Checkpoints/CheckpointRunSelector"
import { Icon } from "src/ui/Icon"
import { theme } from "src/ui/themes/theme"
import { TableProps, Tooltip as AntTooltip } from "antd"
import {
  StyledCollapsePanel,
  StyledPanelContents,
  StyledSecondaryContainer,
  StyledSelectedRuntimeLbl,
  StyledTable,
} from "src/Checkpoints/CheckpointListItem.styles"
import { pluralize } from "src/common/utils/strings"
import { CheckpointHeader } from "src/Checkpoints/CheckpointHeader"
import { useOrganizationSlug } from "src/organizations/useOrganizationSlug"
import { CheckpointFragment, ValidationResultFragment } from "src/api/graphql/graphql-operations"
import { formatRanAtTime } from "src/common/utils/formatTime"
import styled, { css } from "styled-components"
import { first } from "lodash-es"
import { SLACK_ACTION } from "src/Checkpoints/words"
import { SlackAction } from "src/Checkpoints/EditCheckpointDrawer"

const StyledValidatedTextContainer = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.spacing.vertical.xxs};
    font-weight: ${theme.typography.semibold.smallLabel.fontWeight};
    font-size: ${theme.typography.semibold.smallLabel.fontSize};
  `};
`

export type UserEvent =
  | React.MouseEvent<HTMLButtonElement>
  | React.MouseEvent<HTMLElement>
  | React.KeyboardEvent<HTMLSpanElement>

type ValidationRun = Pick<ValidationResultFragment, "expectationSuiteName" | "dataAssetName"> &
  Partial<Pick<ValidationResultFragment, "success">> & {
    dataAssetId?: string
    validationId?: string
    expectationSuiteId?: string
    expectationUrl?: string
  }

interface CheckpointListItemProps {
  checkpoint: CheckpointFragment
  expanded: boolean
  selectedRunId: string | null
  setSelectedRunId: (id: string | null) => void
}

export const CheckpointListItem = ({
  checkpoint,
  expanded,
  selectedRunId,
  setSelectedRunId,
  ...props
}: CheckpointListItemProps) => {
  const { navigateInOrg } = useOrganizationSlug()
  const validations = checkpoint.validations
  const checkpointRun = first(checkpoint.runs)
  const alerts =
    checkpoint.actionList
      ?.map<SlackAction>((action) => JSON.parse(action || "{}"))
      .filter((action) => {
        return action?.action?.class_name === SLACK_ACTION
      }).length ?? 0

  const lookbackTooltip =
    "This view shows the latest run in the past 30 days. If you'd like to see older runs, click on a validation"

  const onPillSelected = (id: string | null) => {
    setSelectedRunId(id === selectedRunId ? null : id)
  }

  const handleRowClick = (record: ValidationRun) => {
    record.expectationUrl && navigateInOrg(record.expectationUrl, { state: true })
  }
  const isPillSelected = checkpointRun?.runId === selectedRunId

  const resultsFromSelectedCheckpointRun = useMemo<ValidationRun[]>((): ValidationRun[] => {
    const selectedRunResults: ValidationRun[] =
      checkpointRun?.results.map(({ success, expectationSuiteName, dataAssetName, validationId, id }) => {
        const validation = checkpoint.validations.find(({ id }) => validationId === id)
        const expectationUrl = `data-assets/${validation?.dataAsset?.id}/validations/expectation-suites/${
          validation?.expectationSuiteV2?.id
        }/results/${encodeURIComponent(id)}`

        return {
          ...(validation && {
            dataAssetId: validation.dataAsset?.id,
            expectationSuiteId: validation.expectationSuiteV2?.id,
          }),
          dataAssetName: dataAssetName || "None",
          expectationSuiteName: expectationSuiteName || "Unknown",
          expectationUrl,
          success,
          key: id,
        }
      }) ?? []

    if (isPillSelected) {
      return selectedRunResults
    }
    const validationsAsRuns: ValidationRun[] = validations.map((validation) => ({
      key: validation.id,
      expectationUrl:
        validation.dataAsset && validation.expectationSuiteV2
          ? `data-assets/${validation.dataAsset.id}/validations/expectation-suites/${validation.expectationSuiteV2.id}/results`
          : undefined,
      validationId: validation.id,
      dataAssetId: validation.dataAsset?.id,
      expectationSuiteId: validation.expectationSuiteV2?.id,
      dataAssetName: validation.dataAsset?.name || "None",
      expectationSuiteName: validation.expectationSuiteV2?.name ?? "",
    }))
    return validationsAsRuns
  }, [checkpointRun?.results, isPillSelected, validations, checkpoint.validations])

  const getSubtitle = () => {
    const spacer = "   -   "
    const baseText = `${resultsFromSelectedCheckpointRun.length} ${pluralize(
      "Validation",
      resultsFromSelectedCheckpointRun.length,
    )}`
    const alertsText = alerts ? `${spacer}${alerts} ${pluralize("Alert", alerts)}` : ""
    const notSelectedText = isPillSelected ? "" : `${spacer}Latest Validation configuration`
    return `${baseText}${alertsText}${notSelectedText}`
  }

  const latestRanAt = checkpointRun?.ranAt ?? ""

  const getSecondaryHeaderRow = () => {
    return (
      <StyledValidatedTextContainer>
        {"Last validated at "}
        {formatRanAtTime(latestRanAt)}
        <StyledSecondaryContainer>
          <AntTooltip overlayInnerStyle={{ width: 400 }} title={lookbackTooltip}>
            <StyledSelectedRuntimeLbl>Recent run</StyledSelectedRuntimeLbl>
          </AntTooltip>
          {checkpointRun && (
            <CheckpointRunSelector
              ariaLabel="Latest Checkpoint run"
              expanded={expanded}
              run={checkpointRun}
              setSelectedRunId={onPillSelected}
              isPillSelected={isPillSelected}
            />
          )}
        </StyledSecondaryContainer>
      </StyledValidatedTextContainer>
    )
  }

  return (
    <StyledCollapsePanel
      key={checkpoint.id}
      header={
        <CheckpointHeader
          id={checkpoint.id}
          title={checkpoint.name}
          secondaryHeaderLine={getSecondaryHeaderRow()}
          subtitle={getSubtitle()}
        />
      }
      {...props}
    >
      <StyledPanelContents $isDisabled={!selectedRunId}>
        <StyledTable<FunctionComponent<TableProps<ValidationRun>>>
          size="small"
          tableLayout="fixed"
          dataSource={resultsFromSelectedCheckpointRun}
          pagination={false}
          onRow={(record) => {
            return {
              onClick: () => handleRowClick(record),
            }
          }}
          rowClassName={(record) => (record.expectationUrl ? "selectable" : "not-selectable")}
          columns={[
            {
              sorter: statusSorter,
              title: "Status",
              dataIndex: "success",
              key: "success",
              render: renderSuccessIcon,
              width: "60px",
            },
            {
              sorter({ expectationSuiteName: a }, { expectationSuiteName: b }) {
                return a?.toLowerCase().localeCompare(b?.toLowerCase() ?? "") ?? 0
              },
              title: "Expectation Suite",
              dataIndex: "expectationSuiteName",
              key: "expectationSuiteName",
            },
            {
              sorter({ dataAssetName: a }, { dataAssetName: b }) {
                return a?.toLowerCase().localeCompare(b?.toLowerCase() ?? "") ?? 0
              },
              title: "Asset Name",
              dataIndex: "dataAssetName",
              key: "dataAssetName",
            },
          ]}
          $isDisabled={false}
        />
      </StyledPanelContents>
    </StyledCollapsePanel>
  )
}

function renderSuccessIcon(success?: boolean) {
  if (success === undefined) {
    return null
  }
  return success ? (
    <div style={{ display: "flex" }}>
      <Icon small={true} color={theme.colors.success.gxSuccess} name="checkCircleSolid" title="Success" />
    </div>
  ) : (
    <div style={{ display: "flex" }}>
      <Icon small={true} color={theme.colors.error.gxError} name="closeCircleSolid" title="Failure" />
    </div>
  )
}

function statusSorter({ success: a }: ValidationRun, { success: b }: ValidationRun): 0 | 1 | -1 {
  if (a === b) {
    return 0
  }
  if (a === undefined || b === undefined) {
    return a === undefined ? 1 : -1
  }
  return a && !b ? -1 : 1
}
