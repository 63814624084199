import { Drawer } from "src/ui/Drawer/Drawer"
import { EDIT_EXPECTATION_SUITE_TITLE } from "src/expectationSuites/words"
import { useMutation, useQuery } from "@apollo/client"
import { GetExpectationSuiteNameDocument, UpdateExpectationSuiteNameDocument } from "src/api/graphql/graphql-operations"
import { LoadingState } from "src/ui/LoadingState"
import { useState } from "react"
import { getDomainErrors } from "src/errors/parseErrors"
import { FormItemProps } from "antd/lib"
import { DrawerProps, Form, Input } from "antd"

const INPUT_NAME = "name"

interface EditExpectationSuiteDrawerProps extends DrawerProps {
  id: string
  onClose: () => void
}

function EditExpectationSuiteDrawer({ id, onClose: _onClose, ...props }: EditExpectationSuiteDrawerProps) {
  const formID = `edit-expectation-suite-form-${id}`
  const [form] = Form.useForm()
  const [nameTakenError, setnameTakenError] = useState(false)

  const onClose = () => {
    // reset state
    form.resetFields()
    setnameTakenError(false)

    // pass through from props
    _onClose()
  }

  const nameValue = Form.useWatch(INPUT_NAME, form)
  const q = useQuery(GetExpectationSuiteNameDocument, { variables: { id }, skip: !props.open })
  const [mutate, m] = useMutation(UpdateExpectationSuiteNameDocument, {
    variables: { input: { id, name: nameValue } },
    onCompleted: () => {
      onClose()
    },
    onError: (e) => {
      getDomainErrors(e).forEach((err) => {
        if (err.code === "taken") {
          setnameTakenError(true)
        }
      })
    },
  })

  const initialName = q.data?.expectationSuiteV2?.name

  const showForm = Boolean(initialName) && !q.error

  const extraNameInputProps: Partial<FormItemProps> = nameTakenError
    ? { validateStatus: "error", help: "An Expectation Suite with this name already exists." }
    : {}

  const footer = (
    <Drawer.Footer>
      <Drawer.FooterButton form={formID} type="primary" htmlType="submit" disabled={m.loading} loading={m.loading}>
        Save
      </Drawer.FooterButton>
    </Drawer.Footer>
  )

  return (
    <Drawer title={EDIT_EXPECTATION_SUITE_TITLE} destroyOnClose onClose={onClose} {...props} footer={footer}>
      <LoadingState loading={q.loading} />
      {showForm && (
        <Form
          id={formID}
          form={form}
          layout="horizontal"
          onValuesChange={() => setnameTakenError(false)}
          onFinish={() => mutate()}
        >
          <Form.Item
            label="Name"
            name={INPUT_NAME}
            initialValue={initialName}
            rules={[{ required: true, message: "Name is required" }]}
            {...extraNameInputProps}
          >
            <Input value={form.getFieldValue(INPUT_NAME)} required />
          </Form.Item>
        </Form>
      )}
    </Drawer>
  )
}

export { EditExpectationSuiteDrawer }
