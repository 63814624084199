import styled from "styled-components"

export const SkeletonContainer = styled.div<{ $column?: boolean }>`
  display: flex;
  ${({ $column }) => $column && `flex-direction: column;`}
  gap: ${({ theme }) => theme.spacing.scale.xxl};
  ${({ theme, $column }) =>
    $column &&
    `flex-direction: column;
    gap: ${theme.spacing.scale.xxs};
  `}
  background-color: ${(props) => props.theme.colors.neutralColorPalette.whites.white};
  padding: ${({ theme }) => theme.spacing.scale.xxs} ${({ theme }) => theme.spacing.scale.xs};
  border: 1px solid ${(props) => props.theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder};
  border-radius: ${({ theme }) => theme.spacing.scale.xxxs};
`
