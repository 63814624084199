import { ConditionalParam } from "src/Expectation/ConditionalParam"
import EvaluationParameters from "src/Expectation/EvaluationParameters"
import { ParamHistory, ParamTypes } from "src/Expectation/Expectation"
import { findParamListMarker } from "src/Expectation/utils/findParamListMarker"
import React from "react"

interface AddDynamicStyledTextProps {
  template: string
  params: ParamTypes
  getDynamicStyleTag: GetDynamicStyleTagType
  truncate?: boolean | null
  isExpectationDeleted?: boolean
  isDeletionAction?: boolean
}

type EvaluationParam = {
  value: {
    $PARAMETER: string
  }
}

export type GetDynamicStyleTagType = (
  dynamicString: string | number | string[],
  id?: string,
  history?: ParamHistory,
) => React.ReactNode

function styleExpectationString(
  stringValue: string,
  params: ParamTypes,
  getDynamicStyleTag: GetDynamicStyleTagType,
): string | React.ReactNode {
  if (stringValue.startsWith("$")) {
    const { dynamicValue, evaluation_parameter, history } = findParamValue(stringValue.substring(1), params)

    switch (typeof dynamicValue) {
      case "object": {
        if ("$PARAMETER" in dynamicValue) {
          return <EvaluationParameters key={stringValue} param={`${dynamicValue["$PARAMETER"]}`} /> // Evaluation Parameter for Expectation Suite View
        }
        return JSON.stringify(dynamicValue) // fallback unknown objects to string
      }
      // eslint-disable-next-line no-fallthrough
      case "number":
      case "string": {
        const isConditionalParam = stringValue.startsWith("$row_condition")
        if (isConditionalParam) {
          return <ConditionalParam key={stringValue} conditionalParam={`${dynamicValue}`} />
        }
        if (evaluation_parameter) {
          const evaluationParameterValue = evaluation_parameter?.["value"]?.["$PARAMETER"] ?? ""
          return <EvaluationParameters key={stringValue} param={`${dynamicValue}`} tooltip={evaluationParameterValue} /> // Evaluation Parameter for Validation Result View
        }
        return getDynamicStyleTag(dynamicValue, stringValue, history)
      }
    }
  }
  // Special cases for stringValues
  if (stringValue === "(**$kwargs)") {
    if (params.kwargs?.value) {
      return ` ( ${JSON.stringify(params.kwargs.value)} )`
    }
    return ""
  }
  if (stringValue === " ") {
    return ""
  }
  if (stringValue === ", ") {
    return ""
  }
  return stringValue
}

export function addDynamicStyledText({ template, params, getDynamicStyleTag, truncate }: AddDynamicStyledTextProps) {
  const brokenString = template.split(/(\$\w+)|(\(\*\*\$kwargs\))/).filter((value) => value)

  if (truncate && brokenString.find(findParamListMarker())) {
    /*
        We know that there are over 10 params initially, and we now account for the dashes in between.
        There could be at least one param not related to the list, we estimate there are at least 7 params in a list.
        This not an exact estimation.
      */
    const expectationSummary = brokenString
      .filter(findParamListMarker(true))
      .map((stringValue) => styleExpectationString(stringValue, params, getDynamicStyleTag))
    const paramsList = brokenString
      .filter(findParamListMarker())
      .slice(0, 13)
      .map((stringValue) => styleExpectationString(stringValue, params, getDynamicStyleTag))
    return [...expectationSummary, ...paramsList]
  }

  return brokenString.map((stringValue) => styleExpectationString(stringValue, params, getDynamicStyleTag))
}

export function findParamValue(rawDynamicValue: string, params: ParamTypes) {
  let param, columnListArrayParams
  if (rawDynamicValue.startsWith("removed_item")) {
    param = params[rawDynamicValue] ?? {}
    columnListArrayParams = params[rawDynamicValue]?.value
  } else {
    const trimmedDynamicValue = rawDynamicValue.substring(0, 11)
    param = params[trimmedDynamicValue] ?? {}
    columnListArrayParams = params[trimmedDynamicValue]?.value
  }

  if (rawDynamicValue.includes("column_list_") && Array.isArray(columnListArrayParams)) {
    const digitRegexp = /\d+/
    const matchingIndex = rawDynamicValue.match(digitRegexp)
    if (matchingIndex) {
      const arrIndex = parseInt(matchingIndex[0], 10)
      return { dynamicValue: columnListArrayParams[arrIndex] }
    }
  }
  let evaluation_parameter
  if ("evaluation_parameter" in param) {
    evaluation_parameter = (param as { evaluation_parameter: EvaluationParam })?.["evaluation_parameter"]
  }
  const history = ("history" in param && (param.history as ParamHistory)) || undefined
  return { dynamicValue: params[rawDynamicValue]?.value, evaluation_parameter, history }
}
