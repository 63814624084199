import styled from "styled-components"
import { Table } from "antd"

const StyledTable = styled(Table)`
  .ant-table-tbody > tr {
    display: table-row;
  }

  tr > .name-column {
    width: 80%;
  }

  tr > .role-column {
    width: 20%;
  }

  .ant-pagination {
    padding: 0 16px;
  }
`

const StyledColumnHeader = styled.div`
  font-weight: ${({ theme }) => theme.typography.semibold.label.fontWeight};
  line-height: ${({ theme }) => theme.typography.semibold.label.lineHeight};
`

const StyledButtonContainer = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row-reverse;
`

const StyledTokenActionsWrapper = styled.div`
  display: flex;
  gap: 10px;
`

export { StyledTable, StyledColumnHeader, StyledButtonContainer, StyledTokenActionsWrapper }
