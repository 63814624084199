import { Form, message } from "antd"
import { Drawer, ScrollableFlex } from "src/ui/Drawer/Drawer"
import { useCallback, useState } from "react"
import { ApolloCache, ApolloError, FetchResult, useMutation } from "@apollo/client"
import { findIndex, isEqual } from "lodash-es"
import {
  DataAssetWithRunsFragment,
  DatasourcesWithRunsDocument,
  DatasourcesWithRunsQuery,
  UpdateDataAssetDocument,
  UpdateDataAssetMutation,
} from "src/api/graphql/graphql-operations"
import stringify from "json-stable-stringify"
import { EditDataAssetDrawerHeader } from "src/DataAssets/connect-to-data/DataCRUDDrawerHeaders"
import { AlertBanner } from "src/ui/Alert/AlertBanner"
import { JsonForm } from "src/DataAssets/connect-to-data/JsonForm"
import { JSONSchema } from "json-schema-to-ts"
import type { UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { DataAssetConfigData } from "src/DataAssets/connect-to-data/schemas/data-asset-schemas"
import { SupportedDataSource } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"
import { MESSAGE_DURATION_SECONDS } from "src/common/config"

type Props = {
  data: DataAssetConfigData
  onClose: () => void
  dataAsset: { id: string; name: string }
  dataSourceType: SupportedDataSource
  schema: JSONSchema
  uiSchema: UISchema<unknown>
}

export function EditDataAssetForm({ data: initialData, dataAsset, onClose, dataSourceType, schema, uiSchema }: Props) {
  const [data, setData] = useState<Record<string, unknown>>(initialData ?? {})
  const [dataSourceSaveError, setDataSourceSaveError] = useState<string>()
  const [form] = Form.useForm()

  const onChange = useCallback(
    (newData: Record<string, unknown>) => {
      if (!isEqual(data, newData)) {
        setData(newData)
      }
    },
    [data, setData],
  )

  const [updateDataAsset, { reset: resetUpdateDataAssetMutation, loading }] = useMutation(UpdateDataAssetDocument, {
    update: updateCache,
    onError: (error: ApolloError) => {
      setDataSourceSaveError(error.message)
    },
    onCompleted: () => {
      message.success(`Successfully saved Data Asset`, MESSAGE_DURATION_SECONDS)
      onFinish()
    },
  })
  const onFinish = useCallback(() => {
    resetUpdateDataAssetMutation()
    setData({})
    form.resetFields()
    onClose()
  }, [form, onClose, resetUpdateDataAssetMutation])

  const onSubmit = useCallback(async () => {
    const formValidationResult = await form
      .validateFields()
      .then((values: Record<string, unknown>) => values)
      .catch((errorInfo: { errorFields: unknown[] }) => errorInfo)

    // annoying that AntD doesn't expose type of errorInfo
    if ("errorFields" in formValidationResult) {
      return // nothing to do; validateFields will have already rendered error messages on form fields
    }
    return updateDataAsset({
      variables: {
        input: {
          id: dataAsset.id,
          config: stringify(data),
        },
      },
    })
  }, [form, updateDataAsset, dataAsset.id, data])

  return (
    <>
      <ScrollableFlex vertical gap="middle">
        <EditDataAssetDrawerHeader dataSourceType={dataSourceType} dataAssetName={dataAsset.name} />
        <Form form={form}>
          <JsonForm jsonSchema={schema} data={data} updateData={onChange} uiSchema={uiSchema} />
          {dataSourceSaveError && (
            <AlertBanner description="There was an error saving your Data Asset" message={dataSourceSaveError} />
          )}
        </Form>
      </ScrollableFlex>
      <Drawer.Footer>
        <Drawer.FooterButton type="primary" loading={loading} onClick={onSubmit}>
          Save
        </Drawer.FooterButton>
      </Drawer.Footer>
    </>
  )
}

function updateCache(cache: ApolloCache<unknown>, result: FetchResult<UpdateDataAssetMutation>) {
  const dataAsset = result.data?.updateAsset?.asset
  const dataSourceId = dataAsset?.datasourceId
  if (!dataAsset) {
    return undefined
  }
  cache.updateQuery({ query: DatasourcesWithRunsDocument }, (cachedQuery: DatasourcesWithRunsQuery | null) => {
    if (!cachedQuery) {
      return undefined
    }
    const indexOfDataSource = findIndex(cachedQuery.datasourcesV2, ({ id }) => id === dataSourceId)
    if (!(indexOfDataSource >= 0)) {
      return undefined
    }
    const dataSource = cachedQuery.datasourcesV2[indexOfDataSource]
    const indexOfDataAsset = findIndex(dataSource.assets, (da) => da?.id === dataAsset.id)
    if (!(indexOfDataAsset >= 0)) {
      return undefined
    }

    const dataAssetWithRuns: DataAssetWithRunsFragment | null = dataSource.assets[indexOfDataAsset]
    if (!dataAssetWithRuns) {
      return undefined
    }
    const updatedDataAsset = { ...dataAssetWithRuns, ...dataAsset }

    return {
      ...cachedQuery,
      datasourcesV2: [
        ...cachedQuery.datasourcesV2.slice(0, indexOfDataSource),
        {
          ...dataSource,
          assets: [
            ...dataSource.assets.slice(0, indexOfDataAsset),
            updatedDataAsset,
            ...dataSource.assets.slice(indexOfDataAsset + 1),
          ],
        },
        ...cachedQuery.datasourcesV2.slice(indexOfDataSource + 1),
      ],
    }
  })
}
