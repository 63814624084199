import { Form, message, Radio } from "antd"
import { useCallback, useEffect } from "react"
import { userAssignableRoles, formatOrgRole } from "src/organizations/roles"
import { Button } from "src/ui/Button/Button"
import { FeedbackMessageDoNotUse } from "src/ui/FeedbackMessage"
import { useForm } from "antd/lib/form/Form"
import { StyledRadioGroup } from "src/users/UserModal.styles"
import { StyledFormItem } from "src/ui/Form/FormItem"
import { ButtonWrapper } from "src/users/Users.styles"
import { LabelRegular } from "src/ui/typography/Text"
import { AppModal } from "src/ui/AppModal/AppModal"
import { RoleTooltip } from "src/common/tooltips/RoleTooltip"
import { useMutation } from "@apollo/client"
import { UpdateUserDocument, UserFragment } from "src/api/graphql/graphql-operations"
import { MESSAGE_DURATION_SECONDS } from "src/common/config"
import { StyledTooltipIcon } from "src/ui/Tooltip/Tooltip"

const EditUserModal = ({
  user,
  visible,
  closeModal,
}: {
  user?: UserFragment
  visible: boolean
  closeModal: () => void
}) => {
  const [form] = useForm()
  const [updateUser, { loading, error, reset: resetMutation }] = useMutation(UpdateUserDocument, {
    onCompleted: () => {
      close()
      message.success("Updated user successfully", MESSAGE_DURATION_SECONDS)
    },
    onError: () => undefined,
  })

  useEffect(() => {
    // i hate antd
    form.setFields([{ name: "role", value: user?.organizationRole }])
  }, [form, user?.organizationRole])

  const close = useCallback(() => {
    form.resetFields()
    resetMutation()
    closeModal()
  }, [resetMutation, closeModal, form])

  const onFinish = () => {
    if (loading || error || !user) return
    const { role } = form.getFieldsValue()
    updateUser({
      variables: {
        input: {
          id: user.id,
          organizationRole: role,
        },
      },
    })
  }

  return (
    <AppModal
      open={visible}
      onCancel={close}
      title="Edit User"
      footer={
        <ButtonWrapper>
          <Button onClick={close} type="default">
            Cancel
          </Button>
          <Button type="primary" onClick={onFinish} loading={loading}>
            Update User
          </Button>
        </ButtonWrapper>
      }
    >
      <Form form={form} layout="horizontal">
        <StyledFormItem label="User">
          <LabelRegular>{user?.email}</LabelRegular>
        </StyledFormItem>
        <StyledFormItem
          label="Organization Role"
          name="role"
          tooltip={{
            icon: <StyledTooltipIcon name="questionCircle" width="18px" />,
            title: RoleTooltip,
          }}
        >
          <StyledRadioGroup>
            {userAssignableRoles.map((role) => (
              <Radio key={role} value={role}>
                {formatOrgRole(role)}
              </Radio>
            ))}
          </StyledRadioGroup>
        </StyledFormItem>
      </Form>
      {error && (
        <FeedbackMessageDoNotUse
          title="Error changing role"
          type="error"
          description="There was a problem changing this user's role. Try again later."
        />
      )}
    </AppModal>
  )
}

export { EditUserModal }
