import { AppModal, StyledCopyButton } from "src/ui/AppModal/AppModal"
import { CodeSnippetEditor } from "src/ui/CodeSnippetEditor/CodeSnippetEditor"
import { copyToClipboard } from "src/common/utils/clipboard"
import { FeedbackMessageDoNotUse } from "src/ui/FeedbackMessage"
import styled from "styled-components"
import { CodeSnippetAlert } from "src/ui/CodeSnippetEditor/CodeSnippetAlert"

import { useQuery } from "@apollo/client"
import { RunCheckpointSnippetDocument } from "src/api/graphql/graphql-operations"
import { SplitterOptionsInput } from "src/api/graphql/graphql"
const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`

interface ValidateSplitterModalProps {
  open: boolean
  onCancel: () => void
  onOk: () => void
  expectationSuiteID: string
  assetRefID: string
  splitterOptions?: SplitterOptionsInput
  batchType: string
  selectedCheckpointId: string
}
export function ValidateSplitterSnippetModal({
  open,
  onCancel,
  onOk,
  expectationSuiteID,
  assetRefID,
  splitterOptions,
  batchType,
  selectedCheckpointId,
}: ValidateSplitterModalProps) {
  // TODO handle loading state
  const { data, error } = useQuery(RunCheckpointSnippetDocument, {
    variables: {
      expectationSuiteID: expectationSuiteID,
      assetRefID: assetRefID,
      checkpointID: selectedCheckpointId, // not required
      ...(batchType === "custom" && splitterOptions ? { splitterOptions } : undefined), // not required
    },
    skip: !assetRefID || !expectationSuiteID || !open,
  })

  const codeSnippet = data?.snippets?.runCheckpoint || undefined

  const displaySnippet = (
    <>
      <p>Run the following code snippet:</p>
      <CodeSnippetEditor
        value={!codeSnippet ? "Validate code snippet not found" : codeSnippet}
        language="python"
        readOnly
        fontSize={12}
        showLineNumbers={false}
        height="250px"
      />
      <StyledCopyButton
        icon="clipboard"
        aria-label="Copy"
        disabled={!codeSnippet}
        onClick={() => codeSnippet && copyToClipboard(codeSnippet)}
      >
        Copy snippet
      </StyledCopyButton>
      {error && (
        <FeedbackMessageDoNotUse
          title="Error"
          type="error"
          description="An error occurred when trying to get the snippet. Try again later"
        />
      )}
    </>
  )

  const onModalCancel = () => {
    onCancel()
  }

  return (
    <AppModal
      open={open}
      title="Validation Snippet"
      width={750}
      onCancel={() => onModalCancel()}
      onOk={() => onOk()}
      cancelText="Done"
      cancelButtonProps={{ type: "primary" }}
      okButtonProps={{ style: { display: "none" } }}
    >
      <FlexContainer>
        {Boolean(selectedCheckpointId) && <CodeSnippetAlert />}
        {Boolean(selectedCheckpointId) && displaySnippet}
      </FlexContainer>
    </AppModal>
  )
}
