import React from "react"
import { Form, Alert, FormInstance } from "antd"
import { theme } from "src/ui/themes/theme"
import { JsonForm } from "src/DataAssets/connect-to-data/JsonForm"
import {
  BATCH_DEFINITION_TEXT,
  BatchDefinition,
  getAssetsWithDateTimeColumns,
} from "src/DataAssets/AssetDetails/Splitters/splitterUtils"
import { BatchDefinitionJsonSchema } from "src/Expectation/batch-definition-json-schema"
import {
  BatchDefinitionUISchemaRegistryEntry,
  WholeTableBatchDefinitionUISchemaRegistryEntry,
} from "src/DataAssets/connect-to-data/schemas/ui-schemas/batch-definition-ui-schema"
import { BatchDefinitionColumnNameRegistryEntry } from "src/Expectation/uiForms/customRenderers/BatchDefinitionColumnNameControl"
import { batchDefinitionSegmentedUISchema } from "src/Expectation/uiForms/schemas/batch-definition-ui-schemas"
import { DataAssetWithLatestMetricRunFragment } from "src/api/graphql/graphql"
import { useFragment_experimental } from "@apollo/client"
import { DataAssetWithLatestMetricRunFragmentDoc } from "src/api/graphql/graphql-operations"
import { ExecutableDefinitionNode } from "graphql"
import { useParams } from "react-router-dom"

interface BatchDefinitionFormProps {
  batchDefinitionForm: FormInstance
  batchDefinitionData: BatchDefinition
  setBatchDefinitionData: (data: BatchDefinition) => void
  dataAssetWithLatestMetricRun?: DataAssetWithLatestMetricRunFragment
}

export const BatchDefinitionForm: React.FC<BatchDefinitionFormProps> = ({
  batchDefinitionForm,
  batchDefinitionData,
  setBatchDefinitionData,
}) => {
  const { assetId: _assetId = "" } = useParams<{
    assetId: string
  }>()
  const dataAssetId = decodeURIComponent(_assetId)
  const dataAssetWithLatestMetricRun = useFragment_experimental({
    fragment: DataAssetWithLatestMetricRunFragmentDoc,
    fragmentName: (DataAssetWithLatestMetricRunFragmentDoc.definitions[0] as ExecutableDefinitionNode).name?.value,
    from: { __typename: "AssetRef", id: dataAssetId },
  })
  const filteredDataAssetDataWithLatestMetricRun = getAssetsWithDateTimeColumns(dataAssetWithLatestMetricRun.data)

  return (
    <Form form={batchDefinitionForm}>
      <Form.Item>
        <Alert
          style={{ marginBottom: theme.spacing.vertical.s }}
          showIcon
          message="Define batch intervals"
          type="warning"
          description={BATCH_DEFINITION_TEXT}
        />

        <JsonForm
          jsonSchema={BatchDefinitionJsonSchema}
          uiSchema={batchDefinitionSegmentedUISchema}
          customRendererRegistryEntries={[BatchDefinitionColumnNameRegistryEntry]}
          uiSchemaRegistryEntries={[
            BatchDefinitionUISchemaRegistryEntry,
            WholeTableBatchDefinitionUISchemaRegistryEntry,
          ]}
          data={batchDefinitionData}
          updateData={setBatchDefinitionData}
          config={{
            dataAssetWithLatestMetricRun: filteredDataAssetDataWithLatestMetricRun,
            form: batchDefinitionForm,
          }}
        />
      </Form.Item>
    </Form>
  )
}

export default BatchDefinitionForm
