/* eslint-disable react-refresh/only-export-components */ // FIXME
import React, { useState } from "react"
import { Table, TableProps, Tooltip, Typography } from "antd"
import { uniq } from "lodash-es"
import styled, { useTheme } from "styled-components"
import {
  UNEXPECTED_VALUES_COPY_QUERY_DISABLED_TEXT,
  UNEXPECTED_VALUES_COPY_QUERY_TEXT,
  getUnexpectedValuesTableFooterText,
} from "src/Expectation/words"
import { Button } from "src/ui/Button/Button"
import { copyToClipboard } from "src/common/utils/clipboard"
import { UnexpectedIndexQueryModal } from "src/Expectation/UnexpectedIndexQueryModal"

type JSONString = string

interface UnexpectedValuesTableProps {
  // columnName is the Expectation's  column name. (table-level / batch-level Expectations are not supported)
  columnName: string
  // result is the result field of an Expectation Validation Result
  result?: JSONString | null
  unexpectedIndexQuery?: string
}

interface TableRow {
  key: string
  value: string
  count: string
  rowIndex: string
  copyButton: JSX.Element
}

// UnxpectedCountItem appears in the "partial_unexpected_counts" field of an Expectation Validation Result
interface UnexpectedCountItem {
  count: number
  value: unknown
}

// UnexpectedIndexListItem can be a compound or simple value
// UnexpectedIndexListItem is a number if result_format['result_format'] is 'COMPLETE' and result_format['unexpected_index_column_names'] is omitted.
type UnexpectedIndexListItem = Record<string, unknown> | number

const StyledTable = styled(Table)`
  width: 100%;

  tbody > tr > :nth-child(3) {
    white-space: pre;
    overflow: hidden;
    max-height: 300px;
  }
`

// getRowIndex returns the value to display in the "Row Index" column.
// It returns undefined if unable to construct a valid value.
function getRowIndex(columnName: string, resultObj: object, value: unknown): string | undefined {
  // some basic validation to ensure these values are available
  ;["unexpected_list", "unexpected_index_list"].forEach((col) => {
    if (!(col in resultObj)) {
      return undefined
    }

    // @ts-expect-error ts can't infer that col is a key of resultObj
    const val: unknown = resultObj[col]

    if (!val) {
      return undefined
    }

    if (!Array.isArray(val)) {
      return undefined
    }

    if (val.length === 0) {
      return undefined
    }
  })

  if (!("unexpected_list" in resultObj) || !("unexpected_index_list" in resultObj)) {
    return undefined
  }

  const unexpectedList = resultObj.unexpected_list as unknown[]
  const unexpectedIndexList = resultObj.unexpected_index_list as UnexpectedIndexListItem[]

  const displayArray: UnexpectedIndexListItem[] = []

  for (let i = 0; i < unexpectedIndexList.length; i++) {
    const indexRecord = JSON.parse(JSON.stringify(unexpectedIndexList[i]))
    if (typeof indexRecord === "number" && unexpectedList[i] === value) {
      displayArray.push(indexRecord)
    } else if (typeof indexRecord === "object" && unexpectedList[i] === value) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete indexRecord[columnName] // hide redundant column name
      displayArray.push(indexRecord)
    }
  }

  return JSON.stringify(displayArray)
}

// parseRows extracts values from the JSON result and transforms them into an antd-compatible list format
// At the time of writing, we have decided:
// - "partial_unexpected_counts" determines which rows to include
// - The "row index" column is detmerined by mapping "unexpected_index_list" to "unexpected_list" (1-to-1) and filtering based on the "unexpected_list" value
// - The 1-to-1 mapping is a known assumption. If this assumption is violated, our strategy will need to change.
export function parseRows(result: JSONString, columnName: string): TableRow[] {
  try {
    const obj = JSON.parse(result)
    const counts: UnexpectedCountItem[] = obj.partial_unexpected_counts ?? []

    return counts.map((item: UnexpectedCountItem) => {
      const rowIndex = getRowIndex(columnName, obj, item.value) ?? ""

      return {
        key: JSON.stringify(item), // should be unique
        value: String(item.value),
        count: String(item.count),
        rowIndex,
        copyButton: (
          <Tooltip title="Copy Row Index">
            <Button icon="clipboard" aria-label="Copy" onClick={() => copyToClipboard(rowIndex)} size="small" />
          </Tooltip>
        ),
      }
    })
  } catch {
    return []
  }
}

function getSnippetButton(
  disabled: boolean,
  onClick?: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLButtonElement>,
): JSX.Element {
  const title = disabled ? UNEXPECTED_VALUES_COPY_QUERY_DISABLED_TEXT : UNEXPECTED_VALUES_COPY_QUERY_TEXT

  return (
    <Tooltip title={title}>
      {/** span is required for tooltip to render on disabled button https://github.com/react-component/tooltip/issues/18#issuecomment-411476678 */}
      <span>
        <Button
          type="default"
          onClick={onClick}
          disabled={disabled}
          aria-label="Copy Query"
          icon="fileSearchAlt"
          size="small"
          style={{ pointerEvents: disabled ? "none" : "auto" }}
        />
      </span>
    </Tooltip>
  )
}

export function UnexpectedValuesTable(props: UnexpectedValuesTableProps) {
  const { result, columnName, unexpectedIndexQuery } = props

  const allowCopyUnexpectedIndexQuery = unexpectedIndexQuery !== undefined
  const [openUnexpectedIndexQueryModal, setOpenUnexpectedIndexQueryModal] = useState(false)

  const snippetButton = getSnippetButton(!allowCopyUnexpectedIndexQuery, () => setOpenUnexpectedIndexQueryModal(true))

  if (!result || !columnName) {
    return null
  }

  const result_obj = JSON.parse(result)
  const recordCount: number = (result_obj.partial_unexpected_counts ?? []).length
  const totalRecordCount: number = uniq(result_obj.unexpected_list ?? []).length
  const showFooter = totalRecordCount > recordCount

  const Footer: TableProps<object>["footer"] = () => {
    const theme = useTheme()

    return (
      <Typography.Text italic style={{ fontSize: theme.typography.semibold.smallLabel.fontSize }}>
        {getUnexpectedValuesTableFooterText(recordCount, totalRecordCount, allowCopyUnexpectedIndexQuery)}
      </Typography.Text>
    )
  }

  const footer = showFooter ? Footer : undefined

  const columns = [
    {
      title: "Unexpected Value",
      dataIndex: "value",
      key: "value",
      ellipsis: true,
      width: "20%",
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      ellipsis: true,
      width: "15%",
    },
    {
      title: "Row Index",
      dataIndex: "rowIndex",
      key: "rowIndex",
      ellipsis: true,
      width: "60%",
    },
    {
      title: snippetButton,
      dataIndex: "copyButton",
      key: "copyButton",
      width: "40px",
    },
  ]

  const rows: TableRow[] = parseRows(result, columnName)

  return (
    <>
      <StyledTable
        bordered
        dataSource={rows}
        columns={columns}
        aria-label="unexpected values table"
        pagination={false}
        size="small"
        footer={footer}
        tableLayout="fixed"
      />
      {unexpectedIndexQuery && (
        <UnexpectedIndexQueryModal
          open={openUnexpectedIndexQueryModal}
          onCancel={() => setOpenUnexpectedIndexQueryModal(false)}
          onOk={() => setOpenUnexpectedIndexQueryModal(false)}
          unexpectedIndexQuery={unexpectedIndexQuery}
        />
      )}
    </>
  )
}
