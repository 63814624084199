import { Space, Radio, DatePicker, Form, Select, Alert, Flex, notification } from "antd"
import { useApolloClient } from "@apollo/client"
import {
  AggregateSuiteValidationResultDocument,
  ExpectationSuiteDocument,
  JobFragment,
} from "src/api/graphql/graphql-operations"

import { ValidateSubsetDrawerFooter } from "src/DataAssets/AssetDetails/Splitters/ValidateSubsetDrawerFooter"
import { useCallback, useEffect, useState } from "react"
import { LabelRegular, SpanSemiBold, LabelLink } from "src/ui/typography/Text"
import { Icon } from "src/ui/Icon"
import styled from "styled-components"
import { useRunCheckpointJob } from "src/DataAssets/AssetDetails/Expectations/useRunCheckpointJob"
import { SplitterUnion } from "src/api/graphql/graphql"
import { NOTIFICATION_INFINITE_DURATION_SECONDS, NOTIFICATION_WITH_LINK_DURATION_SECONDS } from "src/common/config"
import { ValidateSplitterSnippetModal } from "src/DataAssets/AssetDetails/Splitters/ValidateSplitterSnippetModal"
import { AgentNotConnectedModal } from "src/DataAssets/AssetDetails/AgentNotConnectedModal"
import { useAnalytics } from "src/analytics/useAnalytics"
import { SplitterError, getSplitterError } from "src/DataAssets/AssetDetails/Splitters/splitterUtils"
import { useCheckpointList } from "src/DataAssets/AssetDetails/Splitters/useCheckpointList"
import { useLazyAgentStatus } from "src/common/hooks/useAgentStatus"
import { JobStateObserver } from "src/common/components/JobStateObserver"
import { VALIDATE_ERROR_DESCRIPTION, VALIDATE_ERROR_TEXT, VALIDATE_SUCCESS_TEXT } from "src/DataAssets/words"
import { Drawer } from "src/ui/Drawer/Drawer"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { BatchDefinitionDescription } from "src/DataAssets/AssetDetails/Splitters/BatchDefinitionDescription"
import { useGetSplitterData } from "src/DataAssets/AssetDetails/Splitters/useGetSplitterData"
import { AlertInfo } from "src/ui/Alert"
import { useIsGXAgentEnabled } from "src/common/hooks/useIsGXAgentEnabled"
import { RadioGroup } from "src/ui/Radio/RadioGroup.tsx"

export type SplitterDateConfig = {
  year?: number | undefined
  month?: number | undefined
  day?: number | undefined
}

// Interfaces and Types
interface ValidateSubsetDrawerProps {
  expectationSuiteId: string
  assetId: string
  open: boolean
  close: () => void
}

export type SplitterType = SplitterUnion["__typename"]

enum BatchType {
  latest = "latest",
  custom = "custom",
}

// Custom Hooks

// Styled Components
const SplitterInfoMessage = styled(Flex)`
  display: flex;
  flex-direction: row;
  align-items: center;

  * {
    padding: 2px;
  }
`

// Main Component
export const ValidateSubsetDrawer = ({ expectationSuiteId, assetId, open, close }: ValidateSubsetDrawerProps) => {
  const [batchType, setBatchType] = useState<BatchType>(BatchType.latest)
  const [selectedSplitterConfig, setSelectedSplitterConfig] = useState<SplitterDateConfig>({
    year: undefined,
    month: undefined,
    day: undefined,
  })

  const [form] = Form.useForm()
  const checkpointList = useCheckpointList(expectationSuiteId, assetId)
  const [openValidateSplitterModal, setOpenValidateSplitterModal] = useState(false)
  const [selectedCheckpointId, setSelectedCheckpointId] = useState<string>("")
  const [jobId, setJobId] = useState<string | null>()
  const { runJob, error: createJobError } = useRunCheckpointJob({
    onCompleted: (data) => setJobId(data.createRunCheckpointJob.jobId),
  })
  const [isLoadingCurrentJobRequest, setIsLoadingCurrentJobRequest] = useState(false)
  const analytics = useAnalytics()
  const [splitterError, setSplitterError] = useState<SplitterError | null>()

  const [notificationApi, notificationContextHolder] = notification.useNotification()

  const { splitterColumns, isSplitterSupported, dateFormat, picker, splitterMethod, splitterOptionsString } =
    useGetSplitterData({ isVisible: open })

  const splitterOptions =
    isSplitterSupported && splitterOptionsString ? { [splitterOptionsString]: selectedSplitterConfig } : undefined

  /**
   ** Get agent status to show error modal if agent is not active
   */
  const { executeFunction: loadAgentStatus, loading: isLoadingAgentStatus } = useLazyAgentStatus()
  const agentEnabled = useIsGXAgentEnabled()

  const [isAgentErrorModalVisible, setIsAgentErrorModalVisible] = useState(false)

  const reset = useCallback(() => {
    // Reset states when job is finished
    setJobId(null)
    setSplitterError(null)
    setIsLoadingCurrentJobRequest(false)
    if (checkpointList.length > 1) {
      setSelectedCheckpointId("")
    }
  }, [checkpointList.length])

  const successMessage = (validationResultUrl: string) => {
    return (
      <>
        <LabelLink to={validationResultUrl} strong>
          See results
        </LabelLink>
      </>
    )
  }

  /**
   ** Show success or error messages when job is completed
   */
  const displayError = useCallback(() => {
    const errorMsg = VALIDATE_ERROR_TEXT
    notificationApi.error({
      message: errorMsg,
      description: VALIDATE_ERROR_DESCRIPTION,
      duration: NOTIFICATION_INFINITE_DURATION_SECONDS,
      placement: "top",
    })
    reset()
  }, [notificationApi, reset])

  useEffect(() => {
    if (createJobError) {
      displayError()
    }
  }, [displayError, createJobError])
  const client = useApolloClient()
  const onJobComplete = (job: JobFragment) => {
    const validationResultId = job.createdResources?.find(
      ({ entityType }) => entityType === "SuiteValidationResult",
    )?.entityId

    const valResultUrl = `/data-assets/${assetId}/validations/expectation-suites/${expectationSuiteId}/results/${validationResultId}`

    notificationApi.success({
      message: VALIDATE_SUCCESS_TEXT,
      description: successMessage(valResultUrl),
      duration: NOTIFICATION_WITH_LINK_DURATION_SECONDS,
      placement: "top",
    })
    client.refetchQueries({
      include: [ExpectationSuiteDocument, AggregateSuiteValidationResultDocument],
    })
    reset()
  }

  const onJobError = (job: JobFragment) => {
    displayError()
    setSplitterError(getSplitterError(job?.errorMessage || ""))
  }

  // Event Handlers
  const onCheckpointChange = (value: string) => {
    setSelectedCheckpointId(value)
  }

  /**
   ** Set selected Checkpoint when list length is 1
   */
  useEffect(() => {
    if (!selectedCheckpointId && checkpointList.length === 1 && checkpointList[0]?.key) {
      setSelectedCheckpointId(checkpointList[0].key)
    }
  }, [selectedCheckpointId, checkpointList])

  const runJobWithAgent = async function (checkpointId: string) {
    const res = await loadAgentStatus()

    const isAgentConnected = res.data?.agentStatus.active

    if (isAgentConnected) {
      // run job with splitter options if custom batch is selected
      batchType === BatchType.latest ? runJob(checkpointId) : runJob(checkpointId, splitterOptions)
      setIsLoadingCurrentJobRequest(true)
      analytics?.capture("checkpoint.run_from_agent")
    } else {
      setIsAgentErrorModalVisible(true)
      reset()
    }
  }

  const runJobWithRunner = async function (checkpointId: string) {
    // run job with splitter options if custom batch is selected
    batchType === BatchType.latest ? runJob(checkpointId) : runJob(checkpointId, splitterOptions)
    setIsLoadingCurrentJobRequest(true)
    analytics?.capture("checkpoint.run_from_runner")
  }

  // run this on Validation
  const tryToRunJobUsing = async function () {
    const runJobFn = agentEnabled ? runJobWithAgent : runJobWithRunner
    runJobFn(selectedCheckpointId)
  }

  const setDateOptions = (dateString: string) => {
    const selectedSplitter = parseDate(dateString) as SplitterDateConfig
    setSelectedSplitterConfig(selectedSplitter)
  }
  const onValidateRun = () => {
    tryToRunJobUsing()
  }

  function parseDate(inputString: string): SplitterDateConfig {
    const parts = inputString.split("-")
    return {
      year: parseInt(parts[0]),
      month: parts[1] ? parseInt(parts[1]) : undefined,
      day: parts[2] ? parseInt(parts[2]) : undefined,
    }
  }

  const onValidateSnippet = () => {
    setOpenValidateSplitterModal(true)
  }

  const getButtonLabel = () => (isLoadingCurrentJobRequest ? "Validating" : "Validate")

  const footer = (
    <ValidateSubsetDrawerFooter
      onValidate={onValidateRun}
      onValidateSnippet={onValidateSnippet}
      validationLoading={isLoadingCurrentJobRequest}
      buttonLabel={getButtonLabel()}
      validationButtonsDisabled={
        (batchType === BatchType.custom && !selectedSplitterConfig.year) ||
        isLoadingCurrentJobRequest ||
        isLoadingAgentStatus ||
        !selectedCheckpointId
      }
    />
  )

  const batchDefinitionEnabled = useIsFeatureEnabled("batchDefinitionEnabled")

  return (
    <>
      {notificationContextHolder}
      <Drawer title="Validate Data Asset" open={open} onClose={close} footer={footer} size="large">
        {batchDefinitionEnabled ? (
          <BatchDefinitionDescription isSplitterSupported={isSplitterSupported} />
        ) : (
          <AlertInfo
            message={
              <SplitterInfoMessage>
                <span>
                  {"This Data Asset is split by "}
                  <SpanSemiBold>{splitterMethod}</SpanSemiBold> on column <SpanSemiBold>{splitterColumns}</SpanSemiBold>
                </span>
              </SplitterInfoMessage>
            }
          />
        )}

        <Form
          style={{ marginTop: 40 }}
          form={form}
          name="selectCheckpointValidationDrawer"
          layout="vertical"
          validateTrigger={["onBlur"]}
          preserve={false}
        >
          {checkpointList.length > 1 && (
            <Form.Item name="checkpoint" label="Select a checkpoint:" id="checkpoint" rules={[{ required: true }]}>
              <Select
                placeholder="Select a checkpoint"
                onChange={onCheckpointChange}
                style={{ width: "50%" }}
                defaultValue={checkpointList[0]?.value}
                options={checkpointList}
              />
            </Form.Item>
          )}

          <div style={{ marginBottom: 20, marginTop: 20 }}>
            <LabelRegular>Specify a single Batch to validate:</LabelRegular>
          </div>
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <RadioGroup onChange={(e) => setBatchType(e.target.value)} value={batchType}>
              <Space direction="vertical">
                <Radio key="latest" value="latest">
                  Latest Batch
                </Radio>
                <Radio disabled={!isSplitterSupported} key="custom" value="custom">
                  Custom Batch
                </Radio>
              </Space>
            </RadioGroup>
            {batchType === BatchType.latest ? null : (
              <Form.Item
                name="datepicker"
                label={`${splitterMethod}:`}
                id="datepicker"
                rules={[{ required: true, message: `Use the format ${dateFormat}` }]}
              >
                <DatePicker
                  picker={picker}
                  style={{ width: "50%" }}
                  placeholder={dateFormat}
                  format={dateFormat}
                  onChange={(date, dateString) => setDateOptions(dateString)}
                />
              </Form.Item>
            )}
          </Space>
        </Form>
        <ValidateSplitterSnippetModal
          splitterOptions={splitterOptions}
          batchType={batchType}
          expectationSuiteID={expectationSuiteId}
          assetRefID={assetId}
          open={openValidateSplitterModal}
          onCancel={() => setOpenValidateSplitterModal(false)}
          onOk={() => setOpenValidateSplitterModal(false)}
          selectedCheckpointId={selectedCheckpointId}
        />
        {splitterError && (
          <Alert
            style={{ position: "absolute", bottom: 100, width: "95%" }}
            message={<SplitterInfoMessage>{splitterError?.errorMessage}</SplitterInfoMessage>}
            type={splitterError?.alertLevel}
            showIcon
            closable
            icon={<Icon small name="closeCircleSolid" />}
          />
        )}
        <AgentNotConnectedModal isVisible={isAgentErrorModalVisible} setIsVisible={setIsAgentErrorModalVisible} />
        {jobId && <JobStateObserver jobId={jobId} onComplete={onJobComplete} onError={onJobError} />}
      </Drawer>
    </>
  )
}
