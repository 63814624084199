/* eslint-disable react-refresh/only-export-components */ // FIXME
import { Card, Col, Descriptions, Row } from "antd"
import { DataAssetDocument } from "src/api/graphql/graphql-operations"
import { MetricsTable } from "src/DataAssets/AssetDetails/MetricsTable"
import styled from "styled-components"
import { BodyRegular } from "src/ui/typography/Text"
import { getImageLogo } from "src/DataAssets/getImageLogo"
import { formatLocalDate } from "src/common/utils/formatTime"
import { DatasourceTypeV2 } from "src/api/graphql/graphql"
import { DataAssetTabs } from "src/DataAssets/AssetDetails/DataAssetTabs"
import { HeaderTypes } from "src/ui/PageHeader/types"
import { MainContent } from "src/layout/MainContent"
import { PageHeader } from "src/ui/PageHeader/PageHeader"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { Button } from "src/ui/Button/Button"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { useState } from "react"
import { Param } from "src/ui/Param/Param"
import { useGetBatchDefinition, getSplitterColumn } from "src/DataAssets/AssetDetails/Splitters/splitterUtils"
import { SplitterType } from "src/DataAssets/AssetDetails/Splitters/ValidateSubsetDrawer"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { CreateExpectationDrawerContextProvider } from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawerContext"
import { CreateExpectationForAssetDrawer } from "src/Expectation/CreateExpectationDrawer/CreateExpectationForAssetDrawer"
import { CREATE_EXPECTATION_DRAWER_TITLE } from "src/expectationSuites/ExpectationSuiteDetails/CreateExpectationDrawer/words"
import { getDataAssetBreadcrumbs } from "src/DataAssets/AssetDetails/DataAssetBreadcrumbs"
import { SupportedDataSourceJson } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"
import { parseJSONStringConfig } from "src/DataAssets/AssetDetails/utils"

const StyledLogo = styled.img`
  width: ${({ theme }) => theme.spacing.scale.xs};
  height: ${({ theme }) => theme.spacing.scale.xs};
  padding-right: ${({ theme }) => theme.spacing.vertical.xxs};
`

export function getDataSourceLogo(dataSourceType: DatasourceTypeV2 | undefined) {
  return dataSourceType ? getImageLogo(dataSourceType) : ""
}

export function getTableNameOrQueryFromConfig(config: string | null | undefined): JSX.Element | null {
  if (config && config !== "Unknown") {
    const parsed_config = JSON.parse(config)
    if (parsed_config.type === "table") {
      return <Descriptions.Item label="Table name">{parsed_config.table_name}</Descriptions.Item>
    }
    // syntax GrayHighlight for query only
    if (parsed_config.type === "query") {
      return (
        <Descriptions.Item label="Query">
          <Param>{parsed_config.query}</Param>
        </Descriptions.Item>
      )
    }
  }
  return null
}

export function OverviewTab() {
  const { assetId: _assetId = "" } = useParams<{
    assetId: string
  }>()
  const dataAssetId = decodeURIComponent(_assetId)

  const { data: dataAssetQuery } = useQuery(DataAssetDocument, {
    variables: {
      id: dataAssetId,
    },
    skip: !dataAssetId,
  })
  const dataAsset = dataAssetQuery?.dataAsset

  const createdOnDate = dataAsset?.createdAt ? formatLocalDate(dataAsset.createdAt) : "Unknown"
  const dataSourceType = dataAsset?.datasourceV2.type
  const dataSourceName = dataAsset?.datasourceV2 ? dataAsset.datasourceV2.name : "Unknown"
  const dataAssetName = dataAsset?.name
  const configForDataAssetInfo = dataAsset?.datasourceV2 ? dataAsset.config : "{}"
  const config = dataAsset?.datasourceV2?.config ?? "{}"
  const isEditor = useRequireRole("EDITOR")
  const splitterType: SplitterType = dataAsset?.splitter?.["__typename"] as SplitterType
  const splitter = useGetBatchDefinition(splitterType)
  const splitterColumnInfo = getSplitterColumn(dataAsset?.splitter)
  const isMulticolumnSplitter = splitterColumnInfo.isMulticolumn
  const [openCreateExpectationDrawer, setOpenCreateExpectationDrawer] = useState(false)

  const batchDefinitionEnabled = useIsFeatureEnabled("batchDefinitionEnabled")

  const overviewTabActions = () => {
    if (isEditor) {
      return (
        <Button
          key="plus"
          type="primary"
          icon="plus"
          disabled={!dataAssetId}
          onClick={() => setOpenCreateExpectationDrawer(true)}
        >
          {CREATE_EXPECTATION_DRAWER_TITLE}
        </Button>
      )
    }
    return []
  }

  const headerContent: HeaderTypes = {
    title: dataAssetName ?? "",
    rootPath: "data-assets",
    navigateBackTo: "/data-assets",
    rightActions: {
      reactNode: overviewTabActions(),
    },
    customBreadcrumbs: getDataAssetBreadcrumbs({
      dataSourceName,
      dataSourceType,
      config: parseJSONStringConfig(config) as SupportedDataSourceJson,
      tooltipPlacement: "right",
    }),
    footer: <DataAssetTabs currentTab="overview" />,
  }

  const batchInfoLabel = batchDefinitionEnabled ? "Batch interval" : "Split by"
  const batchColumnLabel = `${batchDefinitionEnabled ? "Batch column" : "Split on column"}${isMulticolumnSplitter ? "s" : ""}`

  const assetSummaryCard = (
    <Card title="Data Asset Information">
      <Descriptions column={1} layout="vertical">
        <Descriptions.Item label="Data Source">
          <Row wrap={false}>
            <StyledLogo src={getDataSourceLogo(dataSourceType)} alt={`${dataSourceType} logo`} />
            <BodyRegular style={{ wordBreak: "break-word" }}>{dataSourceName}</BodyRegular>
          </Row>
        </Descriptions.Item>
        {getTableNameOrQueryFromConfig(configForDataAssetInfo)}
        {Boolean(splitter) && <Descriptions.Item label={batchInfoLabel}>{splitter}</Descriptions.Item>}
        {Boolean(splitterColumnInfo.column) && (
          <Descriptions.Item label={batchColumnLabel}>
            <Param>{splitterColumnInfo.column}</Param>
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Date created">{createdOnDate}</Descriptions.Item>
      </Descriptions>
    </Card>
  )
  const metricsTable = dataAsset && <MetricsTable assetId={dataAssetId} />

  return (
    <PageHeader headerContent={headerContent}>
      <MainContent>
        {dataAssetId && (
          <CreateExpectationDrawerContextProvider
            open={openCreateExpectationDrawer}
            onClose={() => setOpenCreateExpectationDrawer(false)}
          >
            <CreateExpectationForAssetDrawer assetId={dataAssetId} assetName={dataAssetName} />
          </CreateExpectationDrawerContextProvider>
        )}
        <Row gutter={[24, 24]}>
          <Col xs={24} xl={6}>
            {assetSummaryCard}
          </Col>
          <Col xs={24} xl={18} className="sentry-mask">
            {metricsTable}
          </Col>
        </Row>
      </MainContent>
    </PageHeader>
  )
}
