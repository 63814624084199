import styled from "styled-components"
import { Button } from "src/ui/Button/Button"

export const StyledFilterSummary = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 50px;
`

export const StyledClearAllButton = styled(Button)`
  padding-bottom: 0;
  opacity: 1;
  visibility: visible;

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
`

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledCatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
