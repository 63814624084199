import { Descriptions } from "antd"
import { Param } from "src/ui/Param/Param"
import React, { CSSProperties } from "react"
import { useGetSplitterData } from "src/DataAssets/AssetDetails/Splitters/useGetSplitterData"
import { pluralize } from "src/common/utils/strings"

type BatchDefinitionDescriptionProps = {
  style?: CSSProperties
  isSplitterSupported: boolean
}

export const BatchDefinitionDescription: React.FC<BatchDefinitionDescriptionProps> = ({
  style,
  isSplitterSupported = true,
}) => {
  const { splitter, splitterColumns } = useGetSplitterData({ isVisible: true })

  const column = pluralize("column", splitterColumns?.split(",").length || 1)
  const batchColumnLabel = `Batch ${column}`

  return (
    <Descriptions style={{ ...style }} column={5} layout="vertical">
      <Descriptions.Item label="Batch interval">{isSplitterSupported ? splitter : "Unsupported"}</Descriptions.Item>
      <Descriptions.Item label={batchColumnLabel}>
        <Param>{splitterColumns}</Param>
      </Descriptions.Item>
    </Descriptions>
  )
}
