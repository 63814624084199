import styled from "styled-components"
import { LabelRegular, LabelSemiBold } from "src/ui/typography/Text"

const StyledDescription = styled.div`
  margin-bottom: 10px;
`

export const RoleTooltip = (
  <LabelRegular $white>
    <StyledDescription>
      <LabelSemiBold $white>Viewers</LabelSemiBold> - can view Checkpoints and Validation Results.
    </StyledDescription>
    <StyledDescription>
      <LabelSemiBold $white>Editors</LabelSemiBold> - can create and update Expectations, create access tokens.
    </StyledDescription>
    <StyledDescription>
      <LabelSemiBold $white>Admins</LabelSemiBold> - have full access, can manage users and org-wide Settings.
    </StyledDescription>
  </LabelRegular>
)
