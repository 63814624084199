import { useLDClient } from "launchdarkly-react-client-sdk"

export function FeatureFlagList() {
  const ld = useLDClient()
  let flagsObj = ld?.allFlags() ?? []

  flagsObj = Object.keys(flagsObj)
    .sort()
    .reduce((obj, key) => {
      //@ts-expect-error - ignore; not production code
      obj[key] = flagsObj[key]
      return obj
    }, {})

  return (
    <div>
      <h1>Feature Flags</h1>
      <table>
        {Object.keys(flagsObj).map((flag) => (
          <tr key={flag}>
            <td>{flag}</td>
            <td style={{ color: flagsObj[flag].toString() === "true" ? "green" : "red" }}>
              {JSON.stringify(flagsObj[flag], null, 2)}
            </td>
          </tr>
        ))}
      </table>
    </div>
  )
}
