/* eslint-disable react-refresh/only-export-components */ // FIXME
import { Button } from "src/ui/Button/Button"
import { CheckpointFilter } from "src/Checkpoints/CheckpointFilter"
import { Skeleton } from "antd"
import { EmptyState } from "src/ui/EmptyState"
import { HeaderTypes } from "src/ui/PageHeader/types"
import styled from "styled-components"
import { NewCheckpointSnippetModal } from "src/Checkpoints/NewCheckpointSnippetModal"
import { useState } from "react"
import { PageHeader } from "src/ui/PageHeader"
import { ErrorState } from "src/ui/error"
import { MainContent } from "src/layout/MainContent"
import { Collapse } from "src/ui/Collapse/Collapse"
import { CheckpointListItem } from "src/Checkpoints/CheckpointListItem"
import { useCheckpoints } from "src/Checkpoints/useCheckpoints"
import { ListAndFilterLayout } from "src/ui/ListAndFilterLayout/ListAndFilterLayout"
import { useQuery } from "@apollo/client"
import { CheckpointFragment, NewCheckpointSnippetDocument } from "src/api/graphql/graphql-operations"
import { first, orderBy } from "lodash-es"
import { exists } from "src/common/utils/typeguards"
import { compareDesc } from "date-fns"
import { OrderDirection } from "src/DataAssets/utils"
import { AlertNotificationsButton } from "src/Checkpoints/AlertNotificationsButton"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"

const StyledErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
`

export function Checkpoints() {
  // page header and NoData components use this query with a cache-only fetch policy
  useQuery(NewCheckpointSnippetDocument)
  const { checkpoints, loading: showLoading, error: showError, zeroBeforeFilter, zeroAfterFilter } = useCheckpoints()
  const [activeCollapseKeys, setActiveCollapseKeys] = useState<string[]>([])
  const showNoData = !showError && !showLoading && zeroBeforeFilter
  const showNoFilterResults = !showError && !showLoading && !zeroBeforeFilter && zeroAfterFilter
  const showCheckpointList = !showError && !showLoading && !zeroBeforeFilter && !zeroAfterFilter
  const hideAlerts = useIsFeatureEnabled("hideAlertsEnabled")

  const headerContent: HeaderTypes = {
    title: "Checkpoints",
    rootPath: "checkpoints",
    rightActions: {
      reactNode: zeroBeforeFilter ? undefined : (
        <>
          {hideAlerts ? null : <AlertNotificationsButton />}
          <NewCheckpointButtonAndModal key="new-checkpoint-button" />
        </>
      ),
    },
  }

  const onChange = (key: string | string[]) => {
    setActiveCollapseKeys(Array.isArray(key) ? key : [key])
  }

  if (checkpoints.length > 0 && activeCollapseKeys.length === 0) {
    setActiveCollapseKeys([checkpoints[0].id])
  }

  return (
    <PageHeader headerContent={headerContent}>
      <MainContent>
        {showError && (
          <StyledErrorWrapper>
            <ErrorState errorMessage="There was a problem loading Checkpoints" />
          </StyledErrorWrapper>
        )}
        {showLoading && (
          <ListAndFilterLayout filter={<CheckpointFilter />}>
            <CheckpointsLoading loading={showLoading} />
          </ListAndFilterLayout>
        )}
        {showNoData && <NoData />}
        {showNoFilterResults && (
          <ListAndFilterLayout filter={<CheckpointFilter />}>
            <NoFilterResults />
          </ListAndFilterLayout>
        )}
        {showCheckpointList && (
          <CheckpointsList activeCollapseKeys={activeCollapseKeys} checkpoints={checkpoints} onChange={onChange} />
        )}
      </MainContent>
    </PageHeader>
  )
}

function CheckpointsLoading({ loading }: { loading: boolean }) {
  return (
    <>
      {[...new Array(4)].map((_, i) => (
        <Skeleton key={`checkpoint-loading-${i}`} loading={loading} active title={false} />
      ))}
    </>
  )
}

const NoDataContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

function NoData() {
  return (
    <NoDataContainer>
      <EmptyState title="Start monitoring data by creating new Checkpoints" />
      <NewCheckpointButtonAndModal />
    </NoDataContainer>
  )
}

function NoFilterResults() {
  return (
    <NoDataContainer>
      <EmptyState title="No results match the filter criteria" />
    </NoDataContainer>
  )
}

function NewCheckpointButtonAndModal() {
  const { data, loading } = useQuery(NewCheckpointSnippetDocument, { fetchPolicy: "cache-only" })
  const snippet = data?.snippets?.createCheckpoint ?? undefined
  const [isVisible, setIsVisible] = useState(false)
  return (
    <Button type="primary" icon="plus" onClick={() => setIsVisible(true)} loading={loading}>
      New Checkpoint
      <NewCheckpointSnippetModal isVisible={isVisible} close={() => setIsVisible(false)} snippet={snippet} />
    </Button>
  )
}

interface CheckpointsListProps {
  checkpoints: CheckpointFragment[]
  activeCollapseKeys: string[]
  onChange: (key: string | string[]) => void
}

export function sortCheckpoints(checkpoints: CheckpointFragment[], sortOrder: OrderDirection = "desc") {
  return orderBy(
    checkpoints,
    [
      (checkpoint) => {
        const mostRecentRun = checkpoint.runs
          .map((run) => (run.ranAt ? Date.parse(run.ranAt) : null))
          .filter(exists)
          .sort((a, b) => compareDesc(a, b))
          .shift()

        if (!mostRecentRun) {
          return sortOrder === "asc" ? Infinity : 0
        }

        return mostRecentRun
      },

      (checkpoint) => checkpoint.name.toLowerCase(),
    ],
    [sortOrder, "asc"], // Specify sort order for each criterion
  )
}

export function CheckpointsList({ checkpoints, activeCollapseKeys, onChange }: CheckpointsListProps) {
  checkpoints = sortCheckpoints(checkpoints)
  const [selectedRunId, setSelectedRunId] = useState<string | null>(() => first(checkpoints[0].runs)?.runId ?? null)
  return (
    <ListAndFilterLayout filter={<CheckpointFilter />} stickyFilter={false}>
      <Collapse accordion={true} defaultActiveKey={[checkpoints[0].id]} onChange={onChange}>
        {checkpoints.map((checkpoint) => (
          <CheckpointListItem
            key={checkpoint.id}
            expanded={activeCollapseKeys.includes(checkpoint.id)}
            checkpoint={checkpoint}
            selectedRunId={selectedRunId}
            setSelectedRunId={setSelectedRunId}
          />
        ))}
      </Collapse>
    </ListAndFilterLayout>
  )
}
