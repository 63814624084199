import { AppModal, StyledCopyButton } from "src/ui/AppModal/AppModal"
import { CodeSnippetEditor } from "src/ui/CodeSnippetEditor/CodeSnippetEditor"
import { copyToClipboard } from "src/common/utils/clipboard"
import { FeedbackMessageDoNotUse } from "src/ui/FeedbackMessage"
import styled from "styled-components"
import { CodeSnippetAlert } from "src/ui/CodeSnippetEditor/CodeSnippetAlert"
import { Select } from "antd"
import { LabelSemiBold } from "src/ui/typography/Text"
import { Icon } from "src/ui/Icon"
import { useEffect, useState } from "react"
import { useQuery } from "@apollo/client"
import { RunCheckpointSnippetDocument, GetAllCheckpointsDocument } from "src/api/graphql/graphql-operations"

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const DropdownContainer = styled(FlexContainer)`
  gap: ${({ theme }) => theme.spacing.vertical.xxs};
`

interface ValidateExpectationsModalProps {
  open: boolean
  onCancel: () => void
  onOk: () => void
  expectationSuiteID: string
  assetRefID: string
}
export function ValidateExpectationsModal({
  open,
  onCancel,
  onOk,
  expectationSuiteID,
  assetRefID,
}: ValidateExpectationsModalProps) {
  const [selectedCheckpoint, setSelectedCheckpoint] = useState<string | undefined>()

  // TODO handle loading state
  const { data: checkpointData } = useQuery(GetAllCheckpointsDocument, {
    variables: {
      expectationSuiteID: expectationSuiteID,
      assetRefID: assetRefID,
    },
    skip: !assetRefID || !expectationSuiteID,
  })

  // TODO handle loading state
  const { data, error } = useQuery(RunCheckpointSnippetDocument, {
    variables: {
      expectationSuiteID: expectationSuiteID,
      assetRefID: assetRefID,
      checkpointID: selectedCheckpoint, // not required
    },
    skip: !assetRefID || !expectationSuiteID,
  })

  const codeSnippet = data?.snippets?.runCheckpoint || undefined

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkpointList =
    checkpointData?.allCheckpointsByAssetRefIdAndOptionalExpectationSuiteId?.map((item) => ({
      label: item?.name,
      value: item?.id,
    })) ?? []

  /**
   ** Set selected Checkpoint when list length is 1
   */
  useEffect(() => {
    if (!selectedCheckpoint && checkpointList.length === 1) {
      setSelectedCheckpoint(checkpointList[0].value)
    }
  }, [checkpointList, selectedCheckpoint])

  const displaySnippet = (
    <>
      <p>Run the following code snippet:</p>
      <CodeSnippetEditor
        value={!codeSnippet ? "Validate code snippet not found" : codeSnippet}
        language="python"
        readOnly
        fontSize={12}
        showLineNumbers={false}
        height="250px"
      />
      <StyledCopyButton
        icon="clipboard"
        aria-label="Copy"
        disabled={!codeSnippet}
        onClick={() => codeSnippet && copyToClipboard(codeSnippet)}
      >
        Copy snippet
      </StyledCopyButton>
      {error && (
        <FeedbackMessageDoNotUse
          title="Error"
          type="error"
          description="An error occurred when trying to get the snippet. Try again later"
        />
      )}
    </>
  )

  const onModalCancel = () => {
    onCancel()
    setSelectedCheckpoint(undefined)
  }

  const checkpointDropdown = (
    <DropdownContainer>
      <LabelSemiBold>
        <LabelSemiBold $gxError>*</LabelSemiBold> Select a Checkpoint to validate
      </LabelSemiBold>
      <Select
        placeholder="- Select One -"
        suffixIcon={<Icon name="chevronDown" />}
        defaultValue={undefined}
        style={{ width: 300 }}
        onChange={(value) => {
          setSelectedCheckpoint(value)
        }}
        options={checkpointList}
      />
    </DropdownContainer>
  )

  return (
    <AppModal
      open={open}
      title="Validate Expectations"
      width={750}
      onCancel={() => onModalCancel()}
      onOk={() => onOk()}
      cancelText="Done"
      cancelButtonProps={{ type: "primary" }}
      okButtonProps={{ style: { display: "none" } }}
    >
      <FlexContainer>
        {Boolean(selectedCheckpoint) && <CodeSnippetAlert />}
        {checkpointList.length > 1 && checkpointDropdown}
        {Boolean(selectedCheckpoint) && displaySnippet}
      </FlexContainer>
    </AppModal>
  )
}
