import { SpanSemiBold } from "src/ui/typography/Text"
import { Alert as AlertAntD, Flex } from "antd"
import styled, { css } from "styled-components"
import { graphql } from "src/api/graphql"
import { useQuery } from "@apollo/client"
import { ReactNode } from "react"
import { nonNull } from "src/common/utils/nonNull"
import { AppLink } from "src/ui/AppLink/AppLink"
import { PRICING_URL } from "src/common/config"
import { useAnalytics } from "src/analytics/useAnalytics"

const StyledAlertAntD = styled(AlertAntD)`
  ${({ theme }) => css`
    padding: ${theme.spacing.scale.xxs};
  `}
`

interface UserAlertReturn {
  message: ReactNode
  type: "info" | "warning"
}

export const GetUserCountLimitDocument = graphql(`
  query getUserCountLimit {
    organization {
      userCountLimit
    }
  }
`)

export const GetUsersDocument = graphql(`
  query getUsers {
    allUsers {
      edges {
        node {
          id
        }
      }
    }
  }
`)

const useUserAlert = (): UserAlertReturn | null => {
  const { data: userCountLimitData } = useQuery(GetUserCountLimitDocument, {})
  const { data: usersData } = useQuery(GetUsersDocument, { notifyOnNetworkStatusChange: true })
  const users = usersData?.allUsers?.edges.map((e) => e?.node).filter(nonNull) ?? []
  const analytics = useAnalytics()

  if (!userCountLimitData?.organization?.userCountLimit) {
    return null
  }

  const getIsBelowLimit = () =>
    userCountLimitData?.organization && users.length < userCountLimitData?.organization?.userCountLimit
  const getBelowLimitMessageContent = () => {
    return (
      <>
        You have{" "}
        <SpanSemiBold>
          {users.length} out of {userCountLimitData?.organization?.userCountLimit}
        </SpanSemiBold>{" "}
        users in your <SpanSemiBold>Developer plan</SpanSemiBold>. Your limit is{" "}
        <SpanSemiBold>{userCountLimitData?.organization?.userCountLimit}</SpanSemiBold>.
      </>
    )
  }

  const getAtLimitMessageContent = () => {
    return (
      <Flex justify="space-between" flex="1">
        <span>
          You have reached the limit of <SpanSemiBold>{userCountLimitData?.organization?.userCountLimit}</SpanSemiBold>{" "}
          users in your <SpanSemiBold>Developer plan</SpanSemiBold>.
        </span>
        <AppLink
          to={PRICING_URL}
          underline
          onClick={() => analytics?.capture("banner_pricing_link.clicked", { userCount: users.length })}
        >
          Upgrade to add more users.
        </AppLink>
      </Flex>
    )
  }

  return {
    message: getIsBelowLimit() ? getBelowLimitMessageContent() : getAtLimitMessageContent(),
    type: getIsBelowLimit() ? "info" : "warning",
  }
}

export const UserAlert = () => {
  const userAlert = useUserAlert()
  if (!userAlert) {
    return null
  }
  return <StyledAlertAntD message={userAlert.message} type={userAlert.type} showIcon />
}
