import { Collapse as AntDCollapse, CollapseProps as CollapsePropsAntd } from "antd"
import styled from "styled-components"

import { Theme } from "src/ui/themes/theme"

const StyledCollapse = styled(AntDCollapse)<{ $background?: keyof Theme["colors"]["neutralColorPalette"] }>`
  &.ant-collapse {
    background-color: ${({ $background, theme }) =>
      $background ? theme.colors.neutralColorPalette[$background] : "white"};
    border-radius: ${({ theme }) => theme.spacing.cornerRadius.medium};
  }

  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    min-height: ${({ theme }) => theme.spacing.scale.xxl};
    align-items: center;

    > .ant-collapse-expand-icon {
      align-self: baseline;
    }
  }

  &.ant-collapse > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`

interface CollapseProps extends Partial<CollapsePropsAntd> {
  children?: React.ReactNode
  background?: keyof Theme["colors"]["neutralColorPalette"]
  defaultActiveKey?: string[] | number[]
}

export const Collapse = ({ defaultActiveKey = [0], background, children, ...props }: CollapseProps) => {
  return (
    <StyledCollapse defaultActiveKey={defaultActiveKey} $background={background} {...props}>
      {children}
    </StyledCollapse>
  )
}
