/* eslint-disable react-refresh/only-export-components */ // FIXME
import { ColumnsType } from "antd/lib/table/interface"
import { ReactElement } from "react"
import { formatTime } from "src/common/utils/formatTime"
import { Icon } from "src/ui/Icon"
import styled from "styled-components"
import { LabelRegular } from "src/ui/typography/Text"
import { formatNumber } from "src/common/utils/formatNumber"
export type HeaderRow = StringSchema | BooleanSchema | NumberSchema

type BooleanSchema = {
  schema: {
    type: "boolean"
  }
  value: boolean
}

type StringSchema = {
  schema: {
    type: "string"
  }
  value: string
}

type NumberSchema = {
  schema: {
    type: "number"
  }
  value: number
}

const ObservedValueContainer = styled.div`
  display: flex;
  align-items: center;
`

const StatusIcon = styled(Icon)`
  margin-left: ${({ theme }) => theme.spacing.horizontal.xxs};
  height: ${({ theme }) => theme.spacing.horizontal.s};
`

const ObservedValueText = styled(LabelRegular)`
  word-break: break-all;
`

const ObservedValueContent = ({
  observedValue,
  success,
}: {
  observedValue: string | boolean | number
  success: boolean
}) => {
  return (
    <ObservedValueContainer>
      <ObservedValueText>{observedValue}</ObservedValueText>
      <StatusIcon color={success ? "green" : "red"} small name={success ? "check" : "close"} />
    </ObservedValueContainer>
  )
}

export function getTableData(headerRowJson: string[], rowsJson: string[][]) {
  /*
   * This function takes in the header rows and the rows of the table and returns the table columns and the table data source
   */

  const headerRows = headerRowJson.map((row) => JSON.parse(row))
  const rows = rowsJson.map((row) => row.map((cell) => JSON.parse(cell)))
  const filteredAndSortedRows = sortByObservedValue(rows).filter((row) => `${row[0].value}`.toLowerCase() !== "success")
  const successStatus = getSuccessStatus(rows)
  const tableColumns = getColumnHeaders(headerRows, formatTitleDate)
  const rowLabels = getRowLabels(filteredAndSortedRows)
  const columnLabels = getColumnLabels(headerRows)
  const tableDatasource = getTableDatasource({ columnLabels, rowLabels, rows: filteredAndSortedRows, successStatus })
  return [tableColumns, tableDatasource]
}

function isBooleanSchema(row: HeaderRow): row is BooleanSchema {
  return row.schema.type === "boolean"
}

function getSuccessStatus(rows: HeaderRow[][]): boolean[] | undefined {
  const successRow = rows
    .find((row) => {
      if (typeof row[0].value === "string") {
        return row[0].value.toLowerCase() === "success"
      }
    })
    ?.filter(isBooleanSchema) // Remove label which is the first element in the array
  const successStatus = successRow?.map((row): boolean => row.value)
  return successStatus
}

function getColumnHeaders(
  headerRows: HeaderRow[],
  formatFunction: (value: string) => string | ReactElement,
): ColumnsType<object> | undefined {
  return headerRows.map((header, ind) => {
    const stringValue = `${header.value}`

    return {
      title: formatFunction(stringValue),
      dataIndex: stringValue,
      key: stringValue,

      ...(ind > 0 ? { width: 160 } : {}), // fix width for all columns except the first one
      ...(ind === 0 ? { fixed: "left", width: 100 } : {}), // fix width for the first column
    }
  })
}

function formatTitleDate(value: string) {
  return formatTime(value)
}

function getColumnLabels(headerRows: HeaderRow[]) {
  return headerRows.map((header) => `${header.value}`)
}

function getRowLabels(rows: HeaderRow[][]) {
  const rowsResult = rows.map((rowValues) => `${rowValues[0].value}`)
  return rowsResult
}

function formatStringNumber(input: string): boolean {
  if (isNaN(Number(input)) || input.length > 16) {
    return true
  }
  return false
}

const formatCellContent =
  (rowValues?: HeaderRow[], successStatus?: boolean[]) =>
  (accumulator: Record<string, HeaderRow["value"] | ReactElement>, cell: string, ind2: number) => {
    const successIndex = ind2 - 1 // success status is one index behind after remonving the label
    const columnLabel = `${rowValues?.[0].value}`
    let value = rowValues?.[ind2] && rowValues[ind2].value !== null ? rowValues[ind2].value : "None"
    if (!formatStringNumber(String(value))) {
      value = formatNumber(String(value))
    }

    if (columnLabel.toLowerCase() === "observed value" && successStatus?.[successIndex] !== undefined) {
      accumulator[cell] = (
        <ObservedValueContent key={ind2} observedValue={value} success={successStatus[successIndex]} />
      )
    } else {
      accumulator[cell] = value
    }
    return accumulator
  }

function getTableDatasource({
  columnLabels,
  rowLabels,
  rows,
  successStatus,
}: {
  columnLabels: string[]
  rowLabels: string[]
  rows: HeaderRow[][]
  successStatus?: boolean[]
}) {
  const tableDatasource = rowLabels.map((label) => {
    const rowValues = rows.find((row) => row[0].value === label)
    const rowDatasource = columnLabels.reduce(formatCellContent(rowValues, successStatus), {})
    return rowDatasource
  })

  return tableDatasource
}

function sortByObservedValue(rows: HeaderRow[][]) {
  const observedValueArray = rows.find((row) => row[0].value === "Observed Value")
  if (observedValueArray) {
    return [observedValueArray, ...rows.filter((row) => row[0].value !== "Observed Value")]
  }
  return rows
}
