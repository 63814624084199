import { useEffect } from "react"
import { LoadingState } from "src/ui/LoadingState"
import { useOrganizationSlug } from "src/organizations/useOrganizationSlug"

import { useQuery } from "@apollo/client"
import { OrganizationDocument } from "src/api/graphql/graphql-operations"
import { useJobListItems } from "src/GXAgent/useJobListItems"
import { useHasDataSources } from "src/common/hooks/useHasDataSources"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { useIsGXAgentEnabled } from "src/common/hooks/useIsGXAgentEnabled"

export const RootRouteRedirect = () => {
  const { navigateInOrg, orgSlug } = useOrganizationSlug()
  const { loading: loadingJobs, noData: noAgentJobs } = useJobListItems()
  const useAgent = useIsGXAgentEnabled()
  const isGxRunnerEnabled = useIsFeatureEnabled("gxRunnerEnabled")

  const { loading: loadingDataSources, hasDataSources } = useHasDataSources()
  const { loading: loadingOrg, data } = useQuery(OrganizationDocument, {
    variables: { slug: orgSlug },
  })

  useEffect(() => {
    function getRedirectRoute(): string {
      if (data?.organization?.hasDefaultName) {
        return "/welcome"
      }
      if (useAgent && noAgentJobs && !hasDataSources) {
        return "/agent-setup"
      }
      return "/data-assets"
    }

    //only redirect if query loading is completed
    if (!loadingOrg && !loadingDataSources && !loadingJobs) {
      const redirectRoute = getRedirectRoute()
      navigateInOrg(redirectRoute)
    }
  }, [
    loadingOrg,
    loadingDataSources,
    loadingJobs,
    navigateInOrg,
    data?.organization?.hasDefaultName,
    noAgentJobs,
    hasDataSources,
    useAgent,
    isGxRunnerEnabled,
    // slackRedirectRoute
  ])

  return <LoadingState loading={loadingOrg || loadingDataSources} />
}
