import { List } from "antd"
import { EmptyState } from "src/ui/EmptyState"
import { HeaderTypes } from "src/ui/PageHeader/types"
import { PageHeader } from "src/ui/PageHeader"
import { ErrorState } from "src/ui/error"
import { InfoDivider, ListItemSubtitle } from "src/expectationSuites/ExpectationSuiteList/ExpectationSuiteList.styles"
import { ExpectationSuiteFilter } from "src/expectationSuites/ExpectationSuiteList/ExpectationSuiteFilter"
import { NewSuiteAction } from "src/expectationSuites/ExpectationSuiteList/NewSuiteAction"
import { ExpectationSuiteActions } from "src/expectationSuites/ExpectationSuiteList/ExpectationSuiteActions"
import {
  ExpectationSuiteListItemProps,
  useExpectationSuiteListItems,
} from "src/expectationSuites/ExpectationSuiteList/useExpectationSuiteListItems"
import { pluralize } from "src/common/utils/strings"
import { MainContent } from "src/layout/MainContent"
import { BodySemiBoldLink, CaptionLink } from "src/ui/typography/Text"
import { MainContentList, MainContentListItemMeta } from "src/ui/List/MainContentList"
import { exists } from "src/common/utils/typeguards"

function headerData(suitesCount?: number, loading?: boolean): HeaderTypes {
  return {
    title: "Expectation Suites",
    subtitle: exists(suitesCount) && !loading ? `${suitesCount} ${pluralize("Expectation Suite", suitesCount)}` : "",
    rootPath: "expectation-suites",
    rightActions: { reactNode: <NewSuiteAction /> },
  }
}

function ExpectationSuiteListItem({ suiteItem }: { suiteItem: ExpectationSuiteListItemProps }) {
  const suitesText = `${suiteItem.expectationCount} ${pluralize("Expectation", suiteItem.expectationCount)}`
  const checkpointsText = `${suiteItem.checkpointCount} ${pluralize("Checkpoint", suiteItem.checkpointCount)}`

  const getDescription = () => {
    return (
      <>
        {suitesText} <InfoDivider as="span">-</InfoDivider>{" "}
        {suiteItem.checkpointCount > 0 ? (
          <CaptionLink to={suiteItem.checkpointsURL}>{checkpointsText}</CaptionLink>
        ) : (
          <ListItemSubtitle>{checkpointsText}</ListItemSubtitle>
        )}
      </>
    )
  }

  return (
    <List.Item
      key={suiteItem.id}
      actions={[<ExpectationSuiteActions id={suiteItem.id} title={suiteItem.title} key={suiteItem.id} />]}
    >
      <MainContentListItemMeta
        title={
          <BodySemiBoldLink to={`expectation-suites/${encodeURIComponent(suiteItem.id)}`}>
            {suiteItem.title}
          </BodySemiBoldLink>
        }
        description={getDescription()}
      />
    </List.Item>
  )
}

function ExpectationSuiteList() {
  const { items: suites, loading, error, noData } = useExpectationSuiteListItems()
  const noSearchResults = !loading && suites.length === 0

  if (error) {
    return (
      <PageHeader headerContent={headerData()}>
        <MainContent>
          <ErrorState />
        </MainContent>
      </PageHeader>
    )
  }

  if (noData) {
    return (
      <PageHeader headerContent={headerData()}>
        <MainContent>
          <EmptyState title="You don't have any Expectation Suites yet">
            To get started, create an Expectation Suite with the button in the top-right corner
          </EmptyState>
        </MainContent>
      </PageHeader>
    )
  }
  return (
    <PageHeader headerContent={headerData(suites.length, loading)}>
      <MainContent>
        <MainContentList<ExpectationSuiteListItemProps>
          header={<ExpectationSuiteFilter />}
          itemLayout="horizontal"
          dataSource={suites}
          renderItem={(item) => <ExpectationSuiteListItem suiteItem={item} />}
          loading={loading}
          locale={{ emptyText: `${noSearchResults && "No Expectation Suites found"}` }}
        />
      </MainContent>
    </PageHeader>
  )
}

export { ExpectationSuiteList }
