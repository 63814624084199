import { Outlet, ScrollRestoration, useLocation } from "react-router-dom"
import { Layout } from "antd"

import { Menu } from "src/ui/Menu/Menu"
import { Alert } from "src/ui/Alert"
import { LoadingState } from "src/ui/LoadingState"
import styled from "styled-components"
import { useLayoutContext } from "src/layout/layoutContext"
import { MaintenanceScreen } from "src/ui/MaintenanceScreen"
import { PollForJobs } from "src/layout/routes/PollForJobs"
import { useGetFeatureFlagVariations } from "src/common/hooks/useGetFeatureFlagVariations"
import { useIsGXAgentEnabled } from "src/common/hooks/useIsGXAgentEnabled"
import { useOptionalOrganizationSlug } from "src/organizations/useOrganizationSlug"
import { PollForAgentStatus } from "src/layout/routes/PollForAgentStatus"
import { UpgradeBanner } from "src/banners/UpgradeBanner"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { useTrackAnalyticsPageView } from "src/analytics/useTrackAnalyticsPageView"

const ContentWrapper = styled(Layout.Content)`
  /* full-page loading state is positioned absolutely relative to this */
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
`
const StyledLayout = styled(Layout)`
  background: ${({ theme }) => `${theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgPrimary}`};
`

export const MainLayout = () => {
  const { loading, alertConfig, setAlertConfig } = useLayoutContext()
  useTrackAnalyticsPageView()
  const location = useLocation()

  // This should be refactored at some point. We don't show the menu for the welcome page, but there is
  // a series of redirects that happens upon login that means we need to check for more than just the welcome page.
  // Keeping this naive implementation for now; we can refactor when we start creating new components that exist
  // outside of the concept of an org that also don't depend on the same menu component.
  const pagesWithoutMenu = ["/login", "/welcome", "agent-setup"]
  const orgSlugUtil = useOptionalOrganizationSlug()
  if (orgSlugUtil.exists) {
    pagesWithoutMenu.push(`/${orgSlugUtil.orgSlug}/`)
  }
  const isRootPath = location.pathname === "/"
  const isPageWithoutMenu = pagesWithoutMenu.some((path) => location.pathname.endsWith(path)) || isRootPath
  const maintenanceDetails = useGetFeatureFlagVariations("maintenanceEnabled")

  const showMenu = !maintenanceDetails?.enabled && !isPageWithoutMenu
  const isGXAgentEnabled = useIsGXAgentEnabled()
  const isGXRunnerEnabled = useIsFeatureEnabled("gxRunnerEnabled")

  return (
    <StyledLayout>
      <ScrollRestoration />
      {showMenu ? <Menu /> : null}
      <ContentWrapper>
        {maintenanceDetails?.enabled ? (
          <MaintenanceScreen completionDate={maintenanceDetails?.data.endTime} />
        ) : (
          <>
            {(isGXAgentEnabled || isGXRunnerEnabled) && <PollForJobs />}
            {isGXAgentEnabled && <PollForAgentStatus />}
            {loading && <LoadingState loading={loading} />}
            <>
              <UpgradeBanner />
              <Outlet />
            </>
            {!!alertConfig && <Alert alertInfo={alertConfig} setAlert={setAlertConfig} />}
          </>
        )}
      </ContentWrapper>
    </StyledLayout>
  )
}
