import { JsonFormsUISchemaRegistryEntry, JsonSchema } from "@jsonforms/core"
import type { UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { PostgresDataAssetQueryJsonSchema } from "src/DataAssets/connect-to-data/schemas/json-schemas/postgres-data-asset-query-json-schema"
import { PostgresDataAssetTableJsonSchema } from "src/DataAssets/connect-to-data/schemas/json-schemas/postgres-data-asset-table-json-schema"

export const PostgresQueryAssetUISchema: UISchema<typeof PostgresDataAssetQueryJsonSchema> = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/query",
    },
    {
      type: "Control",
      scope: "#/properties/name",
      label: "Data Asset name",
      options: { placeholderText: "name for the Data Asset" },
    },
  ],
}

// TODO: actually use this
export const PostgresQueryAssetUISchemaRegistryEntry: JsonFormsUISchemaRegistryEntry = {
  uischema: PostgresQueryAssetUISchema,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  tester: (schema: JsonSchema, schemaPath: string, path: string) => {
    const rank = schema.title === "QueryAsset" && schemaPath === "#" ? 2 : -1
    // console.log("PostgresQueryAsset Tester", { rank, schema, schemaPath, path })
    return rank
  },
}

export const PostgresTableAssetUISchema: UISchema<typeof PostgresDataAssetTableJsonSchema> = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/table_name",
      label: "Table name",
      options: {
        required: true,
        placeholderText: "table name as defined in the database",
        rules: [
          {
            pattern: new RegExp("^(?! ).*(?<! )$"), // no leading or trailing spaces
            message: "Table name cannot start or end with a space",
          },
        ],
      },
    },
    {
      type: "Control",
      scope: "#/properties/name",
      label: "Data Asset name",
      options: { placeholderText: "name for the Data Asset", rules: [] },
    },
  ],
}

// TODO: actually use this
export const PostgresTableAssetUISchemaRegistryEntry = (hideQueryAsset: boolean): JsonFormsUISchemaRegistryEntry => {
  return hideQueryAsset
    ? {
        uischema: PostgresTableAssetUISchema,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        tester: (schema: JsonSchema, schemaPath: string, path: string) => {
          const rank = schema.title === "TableAsset" && schemaPath === "#/properties/assets" ? 2 : -1
          // console.log("TableAssetUISchema Tester", { rank, schema, schemaPath, path })
          return rank
        },
      }
    : {
        uischema: PostgresTableAssetUISchema,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        tester: (schema: JsonSchema, schemaPath: string, path: string) => {
          const rank = schema.title === "TableAsset" && schemaPath === "#" ? 2 : -1
          // console.log("TableAssetUISchema Tester", { rank, schema, schemaPath, path })
          return rank
        },
      }
}
