import styled from "styled-components"
import { AppModal } from "src/ui/AppModal/AppModal"
import { Icon } from "src/ui/Icon"
import { LabelRegular, SubtitleSemiBold } from "src/ui/typography/Text"
import { FeedbackMessageDoNotUse } from "src/ui/FeedbackMessage"
import { message } from "antd"
import { useMutation, useQuery } from "@apollo/client"
import { CheckpointsDocument, CheckpointsQuery, DeleteCheckpointDocument } from "src/api/graphql/graphql-operations"
import { MESSAGE_DURATION_SECONDS } from "src/common/config"

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.warning.colorWarning};
`

const FlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => `${theme.spacing.horizontal.xs}`};
`

interface DeleteCheckpointModalProps {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
  checkpointId: string
}

export const DeleteCheckpointModal = ({ isVisible, setIsVisible, checkpointId }: DeleteCheckpointModalProps) => {
  const { data } = useQuery(CheckpointsDocument)
  const checkpointToDelete = data?.checkpoints.find((checkpoint) => checkpoint.id === checkpointId)
  const [deleteCheckpoint, { loading, error, reset: resetMutation }] = useMutation(DeleteCheckpointDocument, {
    variables: {
      id: checkpointId,
    },
    onCompleted: () => {
      message.success(`Successfully deleted Checkpoint ${checkpointToDelete?.name ?? ""}`, MESSAGE_DURATION_SECONDS)
      setIsVisible(false)
    },
    update(cache) {
      cache.updateQuery(
        {
          query: CheckpointsDocument,
        },
        (data: CheckpointsQuery | null) => {
          if (!data) return null
          const associatedSuiteIds =
            data.checkpoints
              .find((checkpoint) => checkpoint.id === checkpointId)
              ?.validations.map((validation) => validation.expectationSuiteV2?.id) ?? []
          associatedSuiteIds.forEach((suiteId) => {
            cache.modify({
              id: `ExpectationSuiteV2:${suiteId}`,
              fields: {
                checkpointCount(count: number) {
                  if (count < 1) return count
                  return count - 1
                },
              },
            })
          })
          return {
            ...data,
            checkpoints: data?.checkpoints.filter((checkpoint) => checkpoint.id !== checkpointId) ?? [],
          }
        },
      )
      cache.evict({ id: `Checkpoint:${checkpointId}` })
    },
  })
  const onReset = () => {
    setIsVisible(false)
    error && resetMutation()
  }
  return (
    <AppModal
      open={isVisible}
      title={
        <FlexRowContainer>
          <StyledIcon size="28px" name="exclamationCircle" />
          <SubtitleSemiBold>Delete Checkpoint</SubtitleSemiBold>
        </FlexRowContainer>
      }
      width={750}
      okText="Delete"
      onCancel={onReset}
      cancelText="Cancel"
      okButtonProps={{
        danger: true,
        loading: loading,
      }}
      onOk={() => deleteCheckpoint()}
    >
      <>
        {error && (
          <FeedbackMessageDoNotUse title="Error deleting Checkpoint" type="error" description={error.message} />
        )}
        <LabelRegular>This Checkpoint will be permanently deleted from GX Cloud.</LabelRegular>
      </>
    </AppModal>
  )
}
