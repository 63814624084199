import { isDate } from "date-fns"
import { Expectation_ValidationResultFragment } from "src/api/graphql/graphql-operations"
import { formatTime } from "src/common/utils/formatTime"
import { StringValueType } from "src/api/graphql/graphql"

type ObjectWithRuntime = { runtime: string }

export function sortData<T extends ObjectWithRuntime>(values: T[]): { values: T[] } {
  values.sort((a, b) => {
    return Date.parse(a.runtime) - Date.parse(b.runtime)
  })
  return { values: values }
}

export function getXAxisValues(values: { values: ObjectWithRuntime[] }): number[] {
  return values.values.map((_, i) => i)
}

export function getLabelExpression(values: { values: ObjectWithRuntime[] }): string {
  const labelExprArray = values.values.map((result, index) => {
    return "datum.value == " + index + " ? '" + formatTime(result.runtime) + "' : "
  })
  return labelExprArray.join("") + "''"
}

export function getTickSizeExpression(values: { values: ObjectWithRuntime[] }): string {
  const tickSizeExprArray = values.values.map((result, index) => {
    if (result.runtime !== "") {
      return "datum.value == " + index + " ? 5 : "
    }
    return ""
  })
  return tickSizeExprArray.join("") + "0"
}

export function addIndexToData<T extends Record<string, unknown>>(values: { values: Array<T & { index?: number }> }) {
  // TODO - convert this to a pure function
  values.values.forEach((item, index) => {
    item["index"] = index
  })
}

export function isStringDate(dateStr: string): boolean {
  return isNaN(Number(dateStr)) && isDate(new Date(dateStr))
}

export type RenderedContentValue = Array<unknown> | boolean | Date | number | object | string | undefined

export type RenderedContentSchemaType = "array" | "boolean" | "datetime" | "number" | "object" | "string" | "undefined"

export type CastRenderedContentValueResult = {
  value: RenderedContentValue
  approximate: boolean
}

export function castRenderedContentValue(
  value: boolean | number | object | string | undefined,
  schemaType: "array",
): { value: Array<unknown>; approximate: false }

export function castRenderedContentValue(
  value: boolean | number | object | string | undefined,
  schemaType: "boolean",
): { value: boolean; approximate: false }

export function castRenderedContentValue(
  value: boolean | number | object | string | undefined,
  schemaType: "datetime",
): { value: Date; approximate: false }

export function castRenderedContentValue(
  value: boolean | number | object | string | undefined,
  schemaType: "number",
): { value: number | string; approximate: true | false }

export function castRenderedContentValue(
  value: boolean | number | object | string | undefined,
  schemaType: "object",
): { value: object; approximate: false }

export function castRenderedContentValue(
  value: boolean | number | object | string | undefined,
  schemaType: "string",
): { value: string; approximate: false }

export function castRenderedContentValue(
  value: boolean | number | object | string | undefined,
  schemaType: "undefined",
): { value: undefined; approximate: false }

export function castRenderedContentValue(
  value: boolean | number | object | string | undefined,
  schemaType: RenderedContentSchemaType,
): CastRenderedContentValueResult

export function castRenderedContentValue(
  value: boolean | number | object | string | undefined,
  schemaType: RenderedContentSchemaType,
): CastRenderedContentValueResult {
  switch (schemaType) {
    case "array":
      return { value: Array(value), approximate: false }
    case "boolean":
      return { value: Boolean(value), approximate: false }
    case "datetime":
      return { value: new Date(value as string), approximate: false }
    case "number": {
      let number = Number(value)
      if ((isNaN(number) && value?.toString().startsWith("≈")) || value?.toString().startsWith("~")) {
        number = Number(value?.toString().substring(1))
        return { value: number, approximate: true }
      }
      if (isNaN(number)) {
        return { value: String(value), approximate: false }
      }
      return { value: number, approximate: false }
    }
    case "object":
      return { value: Object(value), approximate: false }
    case "string":
      return { value: String(value), approximate: false }
    case "undefined":
      return { value: undefined, approximate: false }
    default:
      return { value: value, approximate: false }
  }
}

export function getObservedValueFromExpectationValidationResult(
  expectationValidationResult: Expectation_ValidationResultFragment,
  fallbackType: string,
): CastRenderedContentValueResult {
  const expectationValidationResultRenderedContent = expectationValidationResult.renderedContent
  const diagnosticContentBlock = expectationValidationResultRenderedContent?.find(
    (contentBlock) =>
      contentBlock?.name === "atomic.diagnostic.observed_value" && contentBlock?.valueType === "StringValueType",
  )
  let diagnosticObservedValue = undefined
  if (diagnosticContentBlock?.value?.__typename === "StringValueType") {
    diagnosticObservedValue = diagnosticContentBlock?.value
  }
  const observedValueTemplate = diagnosticObservedValue?.template
  const paramsJson = diagnosticObservedValue?.params || "{}"
  const diagnosticParams = JSON.parse(paramsJson)
  const parsedObservedValue = diagnosticParams?.observed_value?.value
  const observedValueSchemaType = diagnosticParams?.observed_value?.schema.type

  // python API currently doesn't render observed value as a templated string
  // so we use the fallbackType below to determine the observed value type in that case
  const rawObservedValue = parsedObservedValue ? parsedObservedValue : observedValueTemplate
  const schemaType = observedValueSchemaType ? observedValueSchemaType : fallbackType
  return castRenderedContentValue(rawObservedValue, schemaType)
}

export function getPrescriptiveSummaryFromExpectationValidationResult(
  expectationValidationResult: Expectation_ValidationResultFragment,
): StringValueType {
  const expectationConfigurationRenderedContent = expectationValidationResult.expectationConfig?.renderedContent
  const prescriptiveContentBlock = expectationConfigurationRenderedContent?.find(
    (contentBlock) =>
      contentBlock?.name === "atomic.prescriptive.summary" && contentBlock?.valueType === "StringValueType",
  )
  if (prescriptiveContentBlock && prescriptiveContentBlock?.value?.__typename === "StringValueType") {
    return prescriptiveContentBlock.value
  }
  throw new Error("Content block with valueType StringValueType had __typename that was not StringValueType")
}

export function getParamValueFromStringValueType(
  stringValueType: StringValueType,
  param_name: string,
): CastRenderedContentValueResult {
  const params = JSON.parse(stringValueType.params || "")
  const param = params?.[param_name] ? params?.[param_name] : undefined
  const parsedParam = param ? param.value : undefined
  const schemaType = param ? param.schema.type : "undefined"
  return castRenderedContentValue(parsedParam, schemaType)
}

export function getObservedValueTooltip(observedValue: CastRenderedContentValueResult): RenderedContentValue {
  let observedValueTooltip = observedValue.value
  if (observedValue.approximate) {
    observedValueTooltip = "≈ " + observedValue.value
  }
  if (observedValueTooltip === 0) {
    return 0
  }
  return observedValueTooltip || "N/A"
}

export function getBatchIdFromDomainKwargs(expectationValidationResult: Expectation_ValidationResultFragment) {
  const domainKwargs: string | null | undefined = expectationValidationResult.expectationConfig?.domain?.domainKwargs
  const dKwargs = domainKwargs ? JSON.parse(domainKwargs) : null
  const batchId = dKwargs?.batch_id ?? "N/A"
  return batchId
}
