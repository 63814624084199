import { ConfigProvider, Menu, message, notification, Popover } from "antd"
import { useCallback, useEffect, useState } from "react"
import { useRunCheckpointJob } from "src/DataAssets/AssetDetails/Expectations/useRunCheckpointJob"
import { useJobState } from "src/common/hooks/useJobState/useJobState"
import { useTheme } from "styled-components"
import { Icon } from "src/ui/Icon/Icon"
import { Button } from "src/ui/Button/Button"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { RunCheckpointSnippetModal } from "src/Checkpoints/RunCheckpointSnippetModal"
import {
  RUN_CHECKPOINT_IN_PROGRESS_LABEL,
  RUN_CHECKPOINT_LABEL,
  RUN_CHECKPOINT_VIA_SNIPPET_LABEL,
} from "src/Checkpoints/words"
import { useAnalytics } from "src/analytics/useAnalytics"
import { Image } from "src/ui/Image"
import { AgentNotConnectedModal } from "src/DataAssets/AssetDetails/AgentNotConnectedModal"
import { useLazyAgentStatus } from "src/common/hooks/useAgentStatus"
import { MESSAGE_DURATION_SECONDS, NOTIFICATION_INFINITE_DURATION_SECONDS } from "src/common/config"
import {
  VALIDATE_ERROR_DESCRIPTION,
  VALIDATE_ERROR_TEXT,
  VALIDATE_JOB_CREATION_ERROR_DESCRIPTION,
  VALIDATE_SUCCESS_TEXT,
} from "src/DataAssets/words"
import { CheckpointDocument } from "src/api/graphql/graphql-operations"
import { useIsGXAgentEnabled } from "src/common/hooks/useIsGXAgentEnabled"

interface RunCheckpointButtonProps {
  checkpointId: string
}

function RunCheckpointButton({ checkpointId }: RunCheckpointButtonProps) {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const isEditor = useRequireRole("EDITOR")
  const [isRunModalVisible, setIsRunModalVisible] = useState(false)
  const analytics = useAnalytics()

  const {
    runJob,
    loading: mutationLoading,
    error: createJobError,
  } = useRunCheckpointJob({ refetchQueries: [CheckpointDocument] })

  const jobState = useJobState({
    type: "ValidateData",
    checkpointId: checkpointId,
  })
  const isJobRunning = mutationLoading || (jobState?.status && ["inProgress", "queued"].includes(jobState.status))

  const [loading, setLoading] = useState(isJobRunning)

  useEffect(() => {
    setLoading(isJobRunning)
  }, [isJobRunning])

  const [messageApi, contextHolder] = message.useMessage()
  const [notificationApi, notificationContextHolder] = notification.useNotification()

  const displayCreateJobError = useCallback(() => {
    notificationApi.error({
      message: VALIDATE_ERROR_TEXT,
      description: VALIDATE_JOB_CREATION_ERROR_DESCRIPTION,
      duration: NOTIFICATION_INFINITE_DURATION_SECONDS,
      placement: "top",
    })
  }, [notificationApi])

  useEffect(() => {
    if (createJobError) {
      displayCreateJobError()
    }
  }, [displayCreateJobError, createJobError])

  useEffect(() => {
    if (jobState?.status === "error") {
      notificationApi.error({
        message: VALIDATE_ERROR_TEXT,
        description: VALIDATE_ERROR_DESCRIPTION,
        duration: NOTIFICATION_INFINITE_DURATION_SECONDS,
        placement: "top",
      })
    } else if (jobState?.status === "complete") {
      messageApi.destroy()
      messageApi.success(VALIDATE_SUCCESS_TEXT, MESSAGE_DURATION_SECONDS)
    }
  }, [jobState, messageApi, notificationApi])

  /**
   ** Get agent status to show error modal if agent is not active
   */
  const { executeFunction: loadAgentStatus } = useLazyAgentStatus()
  const agentEnabled = useIsGXAgentEnabled()

  const [isAgentErrorModalVisible, setIsAgentErrorModalVisible] = useState(false)

  const runJobWithAgent = async function (checkpointId: string) {
    const res = await loadAgentStatus()
    const isAgentConnected = res.data?.agentStatus.active

    if (isAgentConnected) {
      runJob(checkpointId)
      analytics?.capture("checkpoint.run_from_agent")
    } else {
      setIsAgentErrorModalVisible(true)
    }
    setLoading(false)
  }
  const runJobWithRunner = async function (checkpointId: string) {
    runJob(checkpointId)
    analytics?.capture("checkpoint.run_from_runner")
    setLoading(false)
  }

  const tryToRunJobUsing = async function (checkpointId: string) {
    const runFn = agentEnabled ? runJobWithAgent : runJobWithRunner
    runFn(checkpointId)
  }

  const runCheckpointMenu = (
    <div onClick={(e) => e.stopPropagation()} role="presentation">
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              itemHoverBg: theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgPrimary,
              itemSelectedBg: theme.colors.neutralColorPalette.backgroundsAndBorders.gxSurfaceSecondary,
            },
          },
        }}
      >
        {contextHolder}
        {notificationContextHolder}
        <Menu
          selectable={false}
          items={[
            {
              key: "1",
              label: RUN_CHECKPOINT_LABEL,
              icon: (
                <Icon
                  name="play"
                  size="16px"
                  paddingRight="10px"
                  className="anticon anticon-desktop ant-menu-item-icon"
                />
              ),
              onClick: () => {
                setLoading(true)
                setOpen(false)
                tryToRunJobUsing(checkpointId)
                messageApi.destroy()
              },
            },
            { key: "2", type: "divider" },
            {
              key: "3",
              label: RUN_CHECKPOINT_VIA_SNIPPET_LABEL,
              icon: (
                <Image
                  type="code"
                  svgProps={{ height: "14px" }}
                  className="anticon anticon-desktop ant-menu-item-icon"
                  title="Use code snippet"
                />
              ),
              onClick: () => {
                setOpen(false)
                setIsRunModalVisible(true)
                analytics?.capture("checkpoint.view_snippet")
              },
            },
          ]}
        />
      </ConfigProvider>
    </div>
  )

  return (
    <>
      <RunCheckpointSnippetModal
        isVisible={isRunModalVisible}
        setIsVisible={setIsRunModalVisible}
        checkpointId={checkpointId}
      />
      <AgentNotConnectedModal isVisible={isAgentErrorModalVisible} setIsVisible={setIsAgentErrorModalVisible} />
      {isEditor && (
        <Popover
          placement="bottomRight"
          content={runCheckpointMenu}
          overlayInnerStyle={{ padding: 0 }}
          open={open}
          trigger="click"
          onOpenChange={(newOpen) => setOpen(newOpen)}
        >
          <Button
            type="text"
            title={isJobRunning ? RUN_CHECKPOINT_IN_PROGRESS_LABEL : RUN_CHECKPOINT_LABEL}
            aria-label={RUN_CHECKPOINT_LABEL}
            icon="play"
            loading={loading}
            disabled={loading || !checkpointId}
            onClick={(e) => {
              e.stopPropagation()
            }}
          />
        </Popover>
      )}
    </>
  )
}

export { RunCheckpointButton }
