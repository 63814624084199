import { Grid, Skeleton, Table } from "antd"
import React, { useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { EmptyState } from "src/ui/EmptyState"
import { HeaderTypes } from "src/ui/PageHeader/types"
import styled from "styled-components"
import { ExpectationList } from "src/expectationSuites/ExpectationSuiteDetails/ExpectationList"
import { groupExpectations } from "src/common/utils/groupExpectations"
import { useAnchorScroll } from "src/expectationSuites/ExpectationSuiteDetails/useAnchorScroll"
import { FilterBox } from "src/ui/FilterBox/FilterBox"
import { getExpectationGroupName } from "src/common/utils/getExpectationGroupName"
import { sortAscWithTableExpectationsFirst } from "src/common/utils/sortAscWithTableExpectationsFirst"
import { PageHeader } from "src/ui/PageHeader"
import { AppLink } from "src/ui/AppLink/AppLink"
import { LabelSemiBold, LabelLink } from "src/ui/typography/Text"
import { uniq, uniqBy } from "lodash-es"
import { ApolloError, useApolloClient, useQuery } from "@apollo/client"
import { ExpectationChangesDocument, ExpectationSuiteDocument } from "src/api/graphql/graphql-operations"
import { MainContent } from "src/layout/MainContent"
import { ListAndFilterLayout } from "src/ui/ListAndFilterLayout/ListAndFilterLayout"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { EmptyExpectationSuite } from "src/expectationSuites/EmptyExpectationSuite/EmptyExpectationSuite"
import { useUrlParams } from "src/common/utils/url-params"
import { ViewModes, ViewToggle, ViewModeTypes } from "src/ui/Button/ViewToggle"
import { ErrorState } from "src/ui/error/ErrorState"
import { useExpectationHistoryQuery } from "src/common/hooks/useExpectationHistoryQuery"
import { CreateExpectationDrawerContextProvider } from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawerContext"
import { CreateExpectationForSuiteDrawer } from "src/Expectation/CreateExpectationDrawer/CreateExpectationForSuiteDrawer"
import { nonNull } from "src/common/utils/nonNull"

const Container = styled.div<{ $lg?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const StyledTable = styled(Table)`
  td {
    cursor: pointer;
  }

  border: 1px solid ${({ theme }) => theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder};
  border-radius: ${({ theme }) => theme.spacing.cornerRadius.medium};
  overflow: hidden;

  .ant-table-body {
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
  }
`

const { useBreakpoint } = Grid

function ExpectationSuiteDetails() {
  const { expectationSuiteId: _expectationSuiteId = "" } = useParams<{
    expectationSuiteId?: string
  }>()
  const expectationSuiteId = decodeURIComponent(_expectationSuiteId)

  const { lg } = useBreakpoint()
  const [search, setSearch] = useState("")
  const [{ viewMode }, setParams] = useUrlParams<{ viewMode: ViewModes }>({ viewMode: "currentView" })
  const {
    data,
    loading,
    error: expectationSuiteError,
  } = useQuery(ExpectationSuiteDocument, {
    variables: {
      id: expectationSuiteId,
    },
    skip: !expectationSuiteId,
  })

  const expectationChanges = useExpectationHistoryQuery(search, expectationSuiteId)
  const expectationsLoading = loading || (viewMode === "changeLog" && expectationChanges.expectationsChangesLoading)
  const isEditor = useRequireRole("EDITOR")
  const [openAddExpectationModal, setOpenAddExpectationModal] = useState(false)
  const { selectedAnchor, onAnchorSelection } = useAnchorScroll(expectationsLoading)
  const checkpoints = uniqBy(data?.expectationSuiteV2?.validations?.flatMap((v) => v.checkpoints) ?? [], "id")

  const linkToCheckpoints =
    "checkpoints/?expectationSuite=" +
    encodeURIComponent(expectationSuiteId) +
    checkpoints.map((c) => "&checkpoint=" + encodeURIComponent(c.id)).join("")

  const groupList = useMemo(() => {
    const listResult =
      data?.expectationSuiteV2?.expectations
        ?.filter(nonNull)
        .map(getExpectationGroupName)
        .filter((group) => group.toLowerCase().includes(search.toLowerCase()))
        .sort(sortAscWithTableExpectationsFirst) ?? []
    return uniq(listResult)
  }, [data?.expectationSuiteV2?.expectations, search])

  const headerData: HeaderTypes = {
    title: data?.expectationSuiteV2?.name ?? "Expectation Suite",
    subtitle: "",
    rootPath: "expectation-suites",
    rightActions: {
      ctaButton: isEditor
        ? {
            type: "primary",
            children: "New Expectation",
            icon: "plus",
            onClick: () => {
              setOpenAddExpectationModal(true)
            },
          }
        : undefined,
    },
  }

  const isErrorPresent =
    Boolean(expectationSuiteError) || (expectationChanges.expectationsChangesError && viewMode === "changeLog")

  const expectationGroups = useMemo(
    () => groupExpectations(data?.expectationSuiteV2?.expectations, search),
    [data?.expectationSuiteV2?.expectations, search],
  )

  const onSearch = (value: string) => {
    setSearch(value)
  }
  const client = useApolloClient()
  const handleViewToggleClick = (value: ViewModes) => {
    if (value === "changeLog") {
      client.refetchQueries({ include: [ExpectationChangesDocument] })
    }
    setParams({ viewMode: value })
  }

  const defaultViewMode = viewMode && ViewModeTypes.includes(viewMode) ? viewMode : "currentView"
  const filter = (
    <Container $lg={lg}>
      <ViewToggle defaultValue={defaultViewMode} onToggleClick={handleViewToggleClick} />
      <FilterBox
        onSearch={onSearch}
        list={groupList}
        loading={expectationsLoading}
        onSelect={onAnchorSelection}
        selectedItem={selectedAnchor}
        title="Table of contents"
      />
      <StyledTable
        scroll={{
          y: 300,
        }}
        pagination={false}
        loading={expectationsLoading}
        columns={[
          {
            title: <LabelSemiBold>Checkpoints ({checkpoints.length})</LabelSemiBold>,
            dataIndex: "name",
            key: "name",
            render: (checkpointName, record: { key?: React.Key }) => (
              <AppLink
                style={{ display: "block" }}
                to={`checkpoints/?checkpoint=${encodeURIComponent(record.key as string)}`}
              >
                {checkpointName}
              </AppLink>
            ),
          },
        ]}
        dataSource={checkpoints.map((checkpoint) => ({ key: checkpoint.id, name: checkpoint.name }))}
      />
      {checkpoints.length > 0 && <LabelLink to={linkToCheckpoints}>Go to checkpoints &gt;</LabelLink>}
    </Container>
  )

  if (isErrorPresent) {
    return (
      <ErrorState
        includeTryAgainSubtitle={false}
        errorMessage={
          expectationSuiteError
            ? getErrorForExpectationSuiteQuery(expectationSuiteError)
            : "There was a problem loading Expectation Change Logs"
        }
      />
    )
  }

  const expectationListWtihEmptyState =
    groupList.length === 0 && search ? (
      <EmptyState title={`No Expectation found with "${search}"`} />
    ) : (
      <ExpectationList
        currentExpectationGroups={expectationGroups}
        selectedGroup={selectedAnchor}
        expectationGroupsWithChanges={expectationChanges.expectationChangesGroupedByColumn}
        viewMode={viewMode}
      />
    )

  return (
    <PageHeader headerContent={headerData} loading={expectationsLoading}>
      <CreateExpectationDrawerContextProvider
        open={openAddExpectationModal}
        onClose={() => setOpenAddExpectationModal(false)}
      >
        <CreateExpectationForSuiteDrawer expectationSuiteId={expectationSuiteId} />
      </CreateExpectationDrawerContextProvider>
      <MainContent>
        {!data?.expectationSuiteV2?.expectations?.length && !expectationsLoading ? (
          <EmptyExpectationSuite expectationSuiteId={expectationSuiteId} />
        ) : (
          <ListAndFilterLayout filter={filter}>
            {expectationsLoading
              ? new Array(3)
                  .fill(null)
                  .map((value, index) => (
                    <Skeleton key={index} active paragraph={{ rows: 5 }} title={false} loading={expectationsLoading} />
                  ))
              : expectationListWtihEmptyState}
          </ListAndFilterLayout>
        )}
      </MainContent>
    </PageHeader>
  )
}

export { ExpectationSuiteDetails }

const getErrorForExpectationSuiteQuery = (error?: ApolloError) => {
  if (error?.message.includes("not found")) {
    return "Expectation Suite not found"
  }
  return "There was a problem loading Expectation Suite"
}
