import { CaptionSemiBold } from "src/ui/typography/Text"
import { List, Space } from "antd"
import { UseDataAssets_DataAssetFragment, UseDataAssets_DatasourceFragment } from "src/api/graphql/graphql-operations"
import { getImageLogo } from "src/DataAssets/getImageLogo"
import styled, { css } from "styled-components"
import { Pill } from "src/ui/Pill/Pill"
import { formatRanAtTime } from "src/common/utils/formatTime"
import { Button } from "src/ui/Button/Button"
import { EditDataAssetButton } from "src/DataAssets/EditDataAssetButton"
import { MainContentListItemMeta } from "src/ui/List/MainContentList"
import { useState } from "react"
import { DeleteDataAssetModal } from "src/DataAssets/DeleteDataAssetModal"
import { useIsAssetEditable } from "src/common/hooks/useIsAssetEditable"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { AppLink } from "src/ui/AppLink/AppLink"
import { AppBreadcrumb } from "src/ui/AppBreadcrumb/AppBreadcrumb"
import { getDataAssetBreadcrumbs } from "src/DataAssets/AssetDetails/DataAssetBreadcrumbs"
import { SupportedDataSourceJson } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"
import { parseJSONStringConfig } from "src/DataAssets/AssetDetails/utils"

const StyledPill = styled(Pill)`
  pointer-events: none;
  ${({ theme }) => css`
    font-weight: ${theme.typography.semibold.smallLabel.fontWeight};
    font-size: ${theme.typography.semibold.smallLabel.fontSize};
    text-align: right;
  `};
`
const StyledLink = styled(AppLink)`
  width: 100%;
  display: flex;
  p {
    margin: 0;
  }
`

const StyledItem = styled(List.Item)`
  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryColors.gxAccentBackground};
  }
`

type DataAssetListItemProps = {
  asset: UseDataAssets_DataAssetFragment
  dataSource: Omit<UseDataAssets_DatasourceFragment, "assets">
  onEdit: () => void
}

export function DataAssetListItem({ asset, dataSource, onEdit }: DataAssetListItemProps) {
  const userHasEditorRole = useRequireRole("EDITOR")
  const [isModalVisible, setIsModalVisible] = useState(false)
  const isEditable = useIsAssetEditable(dataSource.type, asset.assetType ?? "")

  if (!asset.id || !asset.name) return null

  const assetLink = `/data-assets/${asset.id}/overview`
  const config = dataSource.config

  return (
    <StyledItem
      key={asset.id}
      aria-label={`Data Asset ${asset.name}`}
      actions={
        userHasEditorRole
          ? [
              // if the feature flag is on, then the query that returns this fragment is getting passed
              <EditDataAssetButton key="edit" onClick={onEdit} disabled={!isEditable} />,
              <Button
                title="Delete Data Asset"
                onClick={() => setIsModalVisible(true)}
                type="text"
                key="delete"
                icon="trash"
              />,
            ]
          : []
      }
    >
      <DeleteDataAssetModal dataAsset={asset} isVisible={isModalVisible} setIsVisible={setIsModalVisible} />
      <StyledLink to={assetLink} title="Go to Data Asset details">
        <MainContentListItemMeta
          title={asset.name}
          avatar={<img src={getImageLogo(dataSource.type)} alt={`${dataSource.type} logo`} />}
          description={
            <AppBreadcrumb
              items={getDataAssetBreadcrumbs({
                dataSourceName: dataSource.name ?? "Unknown",
                dataSourceType: dataSource.type,
                config: parseJSONStringConfig(config) as SupportedDataSourceJson,
                hasNameLogo: false,
              })}
            />
          }
        />
        <Space direction="vertical" size={[4, 8]} wrap>
          <MostRecentValidationInformation asset={asset} />
        </Space>
      </StyledLink>
    </StyledItem>
  )
}

function MostRecentValidationInformation({ asset }: { asset: UseDataAssets_DataAssetFragment }) {
  const latestRanAt = asset.lastValidatedAt
  const successStatus = asset.aggregateSuccessStatus
  if (!latestRanAt || successStatus === null) return null
  return (
    <Space direction="vertical" align="end">
      <CaptionSemiBold>{`Last validated at ${formatRanAtTime(latestRanAt as string)}`}</CaptionSemiBold>
      <StyledPill state={successStatus ? "Unselected Success" : "Unselected Failure"}>
        {successStatus ? "All passed" : "Failed"}
      </StyledPill>
    </Space>
  )
}
