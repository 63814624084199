import { AppModal, StyledCopyButton } from "src/ui/AppModal/AppModal"
import { CodeSnippetEditor } from "src/ui/CodeSnippetEditor/CodeSnippetEditor"
import { CodeSnippetAlert } from "src/ui/CodeSnippetEditor/CodeSnippetAlert"
import { copyToClipboard } from "src/common/utils/clipboard"
import { Button } from "src/ui/Button/Button"
import { useState } from "react"
import { EDIT_CHECKPOINT_SNIPPET_MODAL_TITLE } from "src/Checkpoints/words"

interface EditCheckpointSnippetProps {
  checkpointSnippet: string
  loading: boolean
}

export const EditCheckpointSnippet = ({ checkpointSnippet, loading }: EditCheckpointSnippetProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const snippetAlert = (
    <>
      <CodeSnippetAlert />
      <p>Or edit the configuration and run the following code snippet:</p>
      <CodeSnippetEditor
        value={!checkpointSnippet ? "Edit Checkpoint code snippet not found" : checkpointSnippet}
        language="python"
        readOnly
        fontSize={12}
        showLineNumbers={false}
        height="250px"
        loading={loading}
      />
      <StyledCopyButton
        icon="clipboard"
        aria-label="Copy"
        disabled={!checkpointSnippet}
        onClick={() => {
          if (checkpointSnippet) {
            copyToClipboard(checkpointSnippet)
          }
        }}
      >
        Copy snippet
      </StyledCopyButton>
    </>
  )
  return (
    <>
      <Button size="large" iconSize="16px" icon="arrow" onClick={() => setIsOpen(true)}>
        Code snippet
      </Button>

      <AppModal
        open={isOpen}
        title={EDIT_CHECKPOINT_SNIPPET_MODAL_TITLE}
        width={750}
        onCancel={() => setIsOpen(false)}
        onOk={() => setIsOpen(false)}
        cancelText="Done"
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ style: { display: "none" } }}
      >
        {snippetAlert}
      </AppModal>
    </>
  )
}
