import { ApolloError, useQuery } from "@apollo/client"
import { sortBy } from "lodash-es"
import { Checkpoint as CheckpointField } from "src/api/graphql/graphql"
import {
  CheckpointNameDocument,
  GetAllCheckpointsDocument,
  CheckpointNamesDocument,
} from "src/api/graphql/graphql-operations"
import { AssetFlowIds } from "src/Checkpoints/EditCheckpointDrawer"

type UseQueryCheckpointsType = {
  // all checkpoints in org are fetched if no ids are provided
  id?: string // Checkpoint ID to fetch a single checkpoint
  assetFlowIds?: AssetFlowIds // AssetFlowIds to fetch checkpoints for a specific asset + expectation suite
  isVisible: boolean
  onError: (e: ApolloError) => void
}

export type Checkpoint = Pick<CheckpointField, "id" | "name" | "actionList">

export const useQueryCheckpoints = ({
  id,
  assetFlowIds,
  isVisible,
  onError,
}: UseQueryCheckpointsType): [Checkpoint[] | null, boolean, () => void] => {
  const shouldSkipSingleCheckpointQuery = Boolean(!id || assetFlowIds || !isVisible)
  const shouldSkipAssetCheckpointQuery = Boolean(id || !assetFlowIds)
  const shouldSkipAllCheckpointsQuery = Boolean(id || assetFlowIds)
  const singleCheckpointQuery = useQuery(CheckpointNameDocument, {
    variables: { id: id ?? "" },
    skip: shouldSkipSingleCheckpointQuery,
    onError,
  })
  const checkpointsByAssetAndSuiteIdQuery = useQuery(GetAllCheckpointsDocument, {
    variables: {
      expectationSuiteID: assetFlowIds?.expectationSuiteId,
      assetRefID: assetFlowIds?.assetId ?? "",
    },
    skip: shouldSkipAssetCheckpointQuery, // this query requires assetId
    onError,
  })
  const allCheckpointsQuery = useQuery(CheckpointNamesDocument, {
    onError,
    skip: shouldSkipAllCheckpointsQuery,
  })

  const singleCheckpoint = singleCheckpointQuery?.data?.checkpoint ? [singleCheckpointQuery?.data?.checkpoint] : null
  const checkpointsByAssetAndSuite =
    checkpointsByAssetAndSuiteIdQuery?.data?.allCheckpointsByAssetRefIdAndOptionalExpectationSuiteId?.filter(
      filterNullCheckpoint,
    ) ?? null
  const allCheckpoints = allCheckpointsQuery?.data?.checkpoints.filter(filterNullCheckpoint) ?? null

  const checkpoints = singleCheckpoint ?? checkpointsByAssetAndSuite ?? allCheckpoints
  const skipAwareRefetch = () => {
    /**
     * Only refetch the query that was not originally skipped
     */
    !shouldSkipSingleCheckpointQuery && singleCheckpointQuery.refetch()
    !shouldSkipAssetCheckpointQuery && checkpointsByAssetAndSuiteIdQuery.refetch()
    !shouldSkipAllCheckpointsQuery && allCheckpointsQuery.refetch()
  }
  return [
    checkpoints ? sortBy(checkpoints, "name") : null,
    singleCheckpointQuery.loading || checkpointsByAssetAndSuiteIdQuery.loading || allCheckpointsQuery.loading,
    skipAwareRefetch,
  ]
}

function filterNullCheckpoint(checkpoint?: Checkpoint | null): checkpoint is CheckpointField {
  return !!checkpoint
}
