import { Alert as AlertAntD } from "antd"
import { Icon } from "src/ui/Icon"
import styled from "styled-components"
import { SpanSemiBold } from "src/ui/typography/Text"
import { useGetFeatureFlagVariations } from "src/common/hooks/useGetFeatureFlagVariations"
import { formatLocalDate, formatLocalTime } from "src/common/utils/formatTime"
import { SupportEmailLink } from "src/ui/SupportEmailLink/SupportEmailLink"

const MaintenanceAlertMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  * {
    padding: 2px;
  }
`

function MaintenanceBanner() {
  const maintenanceBannerData = useGetFeatureFlagVariations("maintenanceBannerEnabled")
  const data = maintenanceBannerData.data
  const startDate = formatLocalDate(data.startTime)
  const endDate = formatLocalDate(data.endTime)
  const spansMultipleDays = startDate !== endDate
  const startTime = formatLocalTime(data.startTime)
  const endTime = formatLocalTime(data.endTime)

  return (
    <div style={{ marginBottom: 20 }}>
      <AlertAntD
        message={
          spansMultipleDays ? (
            <MaintenanceAlertMessage>
              <span>
                Due to scheduled maintenance, GX Cloud may be intermittently unavailable from
                <SpanSemiBold>{startTime}</SpanSemiBold> on <SpanSemiBold>{startDate}</SpanSemiBold> to
                <SpanSemiBold>{endTime}</SpanSemiBold> on <SpanSemiBold>{endDate}</SpanSemiBold>. Contact
                <SupportEmailLink /> with any questions.
              </span>
            </MaintenanceAlertMessage>
          ) : (
            <MaintenanceAlertMessage>
              <span>
                Due to scheduled maintenance, GX Cloud may be intermittently unavailable from
                <SpanSemiBold>{startTime}</SpanSemiBold> to <SpanSemiBold>{endTime}</SpanSemiBold> on{" "}
                <SpanSemiBold>{endDate}</SpanSemiBold>. Contact <SupportEmailLink /> with any questions.
              </span>
            </MaintenanceAlertMessage>
          )
        }
        type="warning"
        showIcon
        icon={<Icon small name="exclamationTriangleSolid" />}
      />
    </div>
  )
}

export { MaintenanceBanner }
