import { MainContent } from "src/layout/MainContent"
import { PageHeader } from "src/ui/PageHeader"
import { HeaderTypes } from "src/ui/PageHeader/types"
import { BodyRegular } from "src/ui/typography/Text"
import styled from "styled-components"
import { GetStarted } from "src/ui/GetStarted/GetStarted"
import { DataSourceChoice, NewAssetDrawer } from "src/DataAssets/connect-to-data/NewAssetDrawer"

import { DataSourceCards } from "src/DataAssets/connect-to-data/PickNewOrExistingDataSource"
import { useState } from "react"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { ConnectToDataButton } from "src/DataAssets/connect-to-data/ConnectToDataButton"
const CenteredContainer = styled.div`
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`

export function DataAssetsEmptyScreen({
  headerData,
  loading,
  isEditorOrAdmin,
}: {
  headerData: HeaderTypes
  loading: boolean
  isEditorOrAdmin: boolean
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [dataSourceChoice, setDataSourceChoice] = useState<DataSourceChoice>()

  const onDatasourceSelect = (choice: DataSourceChoice) => {
    setIsDrawerOpen(true)
    setDataSourceChoice(choice)
  }

  const isNewDataAssetEmptyStateEnabled = useIsFeatureEnabled("dataAssetEmptyStateEnabled")
  const emptyStateTitle = isNewDataAssetEmptyStateEnabled
    ? "Connect to a Data Source to get started"
    : "Get started by adding a Data Asset."

  const showDataAssetCards = isEditorOrAdmin && isNewDataAssetEmptyStateEnabled
  const showConnectToDataButton = isEditorOrAdmin && !isNewDataAssetEmptyStateEnabled
  return (
    <PageHeader headerContent={headerData} loading={loading}>
      <MainContent>
        <CenteredContainer>
          <GetStarted large title={emptyStateTitle}>
            {showConnectToDataButton && <ConnectToDataButton />}
            {showDataAssetCards && (
              <>
                <DataSourceCards large setChoice={onDatasourceSelect} />
                <NewAssetDrawer
                  dataSourceChoiceFromEmptyState={dataSourceChoice}
                  isOpen={isDrawerOpen}
                  setIsOpen={setIsDrawerOpen}
                />
              </>
            )}
            {!isEditorOrAdmin && <BodyRegular>Only Editors and Admins can add Data Assets.</BodyRegular>}
          </GetStarted>
        </CenteredContainer>
      </MainContent>
    </PageHeader>
  )
}
