import { useState } from "react"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { Button } from "src/ui/Button/Button"
import { DeleteExpectationSuiteModal } from "src/expectationSuites/DeleteExpectationSuiteModal"

import { Space } from "antd"
import { EditExpectationSuiteDrawer } from "src/expectationSuites/EditExpectationSuiteDrawer/EditExpectationSuiteDrawer"

interface ExpectationSuiteActionsProps {
  id: string
  title: string
}

function ExpectationSuiteActions(props: ExpectationSuiteActionsProps) {
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const isEditor = useRequireRole("EDITOR")
  if (!isEditor) {
    return null
  }

  return (
    <div>
      <Space>
        <Button type="text" aria-label="Edit Expectation Suite" icon="edit" onClick={() => setShowEdit(true)} />
        <Button type="text" aria-label="Delete Expectation Suite" icon="trash" onClick={() => setShowDelete(true)} />
      </Space>

      <EditExpectationSuiteDrawer id={props.id} open={showEdit} onClose={() => setShowEdit(false)} />
      <DeleteExpectationSuiteModal id={props.id} isVisible={showDelete} setIsVisible={setShowDelete} />
    </div>
  )
}

export { ExpectationSuiteActions }
