import { Layout, Skeleton, Typography } from "antd"
import { PageHeader as PageHeaderAntD } from "@ant-design/pro-layout"
import { useNavigate } from "react-router-dom"
import { Button } from "src/ui/Button/Button"
import { Icon } from "src/ui/Icon"
import styled, { css } from "styled-components"
import { LabelRegular, SubtitleSemiBold } from "src/ui/typography/Text"
import { HeaderTypes, ValidationStatus } from "src/ui/PageHeader/types"
import { useLayoutContext } from "src/layout/layoutContext"
import { AppBreadcrumb as Breadcrumb } from "src/ui/AppBreadcrumb/AppBreadcrumb"
import { PropsWithChildren } from "react"
import { useOrganizationSlug } from "src/organizations/useOrganizationSlug"

const { Header } = Layout
const { Paragraph } = Typography

// NOTE: we have to unset the height of the header because antd sets it to 64px
const StyledHeader = styled(Header)<{ $noBottomMargin?: boolean }>`
  position: fixed;
  z-index: 1;
  padding: ${({ theme }) => `${theme.spacing.horizontal.xs} ${theme.spacing.horizontal.s}`};

  &.ant-layout-header {
    ${({ theme }) => theme.spacing.elevation.l};
    ${({ $noBottomMargin }) =>
      $noBottomMargin &&
      css`
        padding-bottom: 0;
      `}
    height: unset;
    top: 0;
    left: 0;
    position: sticky;
    z-index: 11;
  }
`

const StyledPageHeaderAntD = styled(PageHeaderAntD)`
  &.ant-page-header {
    padding: 0;
  }

  @media screen and (width <= 600px) {
    .ant-page-header-back {
      margin-top: ${({ theme }) => theme.spacing.vertical.xxxs};
    }

    .ant-page-header-heading-left {
      align-items: flex-start;
    }
  }

  .ant-page-header-heading-extra {
    display: flex;
  }

  [aria-label="back"] {
    &:hover,
    &:focus {
      /* should match link color */
      color: ${({ theme }) => theme.colors.primaryColors.gxAccentMedium};
    }
  }
`

const StyledIcon = styled(Icon)`
  ${({ theme, name }) => css`
    color: ${name === "checkCircle" ? theme.colors.success.gxSuccess : theme.colors.error.gxError};
    margin-right: 14px;
    margin-top: ${theme.spacing.vertical.xxxs};
  `}
`

const BottomContainer = styled.div<{ xxs?: boolean }>`
  display: flex;
  margin-top: ${({ theme, xxs }) => (xxs ? theme.spacing.horizontal.xxs : theme.spacing.horizontal.xs)};
`

const StyledParagraph = styled(Paragraph)`
  &.ant-typography {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.typography.regular.label};
  color: ${({ theme }) => theme.colors.neutralColorPalette.blacks.colorTextTertiary};
`

const HeaderWrapper = styled.div`
  display: flex;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (width <= 600px) {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

const StyledLabelRegular = styled(LabelRegular)`
  margin-left: 12px;

  @media screen and (width <= 600px) {
    margin-left: 0;
  }

  color: ${({ theme }) => theme.colors.neutralColorPalette.blacks.colorTextTertiary};
`

const TitleSkeleton = styled(Skeleton.Button)`
  min-width: 200px;
  max-height: 24px;
`

interface PageHeaderProps {
  headerContent: HeaderTypes
  info?: React.ReactNode
  loading?: boolean
}

function PageHeader({ headerContent, info, loading, children }: PropsWithChildren<PageHeaderProps>) {
  const { navigateInOrg } = useOrganizationSlug()
  const navigate = useNavigate()

  const {
    routeInfo: { currentNestedRoutes, pathname },
  } = useLayoutContext()
  const { rightActions, backIcon = "arrowLeft", titleIcon, noBreadCrumb, customBreadcrumbs } = headerContent
  const pageTitle =
    headerContent.title ?? currentNestedRoutes.find((route) => route.path === pathname)?.displayName ?? ""

  // TODO: consider not not making this based on presence of a footer
  const noBottomMargin = !!headerContent.footer

  return (
    <>
      <StyledHeader aria-label="Main Header" $noBottomMargin={noBottomMargin} id="main-header">
        {!noBreadCrumb && <Breadcrumb items={customBreadcrumbs} />}
        <StyledPageHeaderAntD
          {...(currentNestedRoutes.length > 3 && {
            onBack: () => {
              headerContent.navigateBackTo && headerContent.navigateBackTo.length > 0
                ? navigateInOrg(headerContent.navigateBackTo)
                : navigate(-1) // Equivalent to clicking back button
            },
          })}
          backIcon={<Icon name={backIcon} />}
          title={
            <HeaderWrapper>
              {headerContent.validationStatus && (
                <StyledIcon
                  name={headerContent.validationStatus === ValidationStatus.passed ? "checkCircle" : "closeCircle"}
                  ariaLabel="Expectation passed"
                />
              )}
              <TitleContainer>
                {titleIcon}
                <SubtitleSemiBold>{loading ? <TitleSkeleton active size="default" /> : pageTitle}</SubtitleSemiBold>
                <StyledLabelRegular>{headerContent.subtitle}</StyledLabelRegular>
              </TitleContainer>
            </HeaderWrapper>
          }
          extra={
            rightActions && "reactNode" in rightActions
              ? rightActions.reactNode // this is basically an override to render whatever we want here
              : [
                  ...(rightActions?.iconButtons?.map((iconButton) => (
                    <Button key={iconButton.icon} type="default" size="small" {...iconButton} />
                  )) ?? []),
                  rightActions?.secondaryButton && (
                    <Button key={rightActions.secondaryButton.icon} {...rightActions.secondaryButton}>
                      {rightActions.secondaryButton.children}
                    </Button>
                  ),
                  rightActions?.ctaButton && (
                    <Button key={rightActions.ctaButton.icon} {...rightActions.ctaButton}>
                      {rightActions.ctaButton.children}
                    </Button>
                  ),
                ]
          }
          footer={headerContent.footer}
        />
        {headerContent.description?.length && (
          <BottomContainer>
            <StyledParagraph>{headerContent.description}</StyledParagraph>
          </BottomContainer>
        )}
        {info}
      </StyledHeader>
      {children}
    </>
  )
}

export { PageHeader }
