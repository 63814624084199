import { CaptionSemiBold } from "src/ui/typography/Text"
import { addEmptySpacesToParam } from "src/Expectation/utils/addEmptySpacesToParam"
import { InlinePillContainer } from "src/Expectation/InlinePillContainer"
import { useEffect } from "react"
import { useAnalytics } from "src/analytics/useAnalytics"

export const ConditionalParam = ({ conditionalParam }: { conditionalParam: string }) => {
  const posthog = useAnalytics()

  useEffect(() => {
    posthog?.capture("Expectation with Conditional Param")
  }, [posthog])

  const conditionalParamWithSpaces = addEmptySpacesToParam(conditionalParam)
  return (
    <InlinePillContainer $border>
      <CaptionSemiBold $gxAccentMedium>{conditionalParamWithSpaces}</CaptionSemiBold>
    </InlinePillContainer>
  )
}
