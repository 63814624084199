import { REFRESH_AND_TRY_AGAIN_OR_CONTACT_SUPPORT } from "src/common/words"

export const EDITOR_DRAWER_TITLE_CREATE = "Create Expectation Suite"
export const EDITOR_DRAWER_TITLE_EDIT = "Edit Expectation Suite"
export const EDITOR_SUCCESS_MESSAGE = "Expectation Suite created."

// Manual creation messages
export const CREATE_EXPECTATION_SUITE_MANUAL = "Expectation Suite created."
export const CREATE_DEFAULT_CHECKPOINT = "Default Checkpoint created."

// Automatic/Generate creation messages
export const EDITOR_DRAWER_IN_PROGRESS_MESSAGE =
  "It's taking a little more time to process your data and generate Expectations"

export const EDITOR_DRAWER_FAILURE_MESSAGE = "Unable to generate Expectation Suite."
export const EDITOR_DRAWER_UNKNOWN_ERROR_MESSAGE = (
  <>
    {EDITOR_DRAWER_FAILURE_MESSAGE} {REFRESH_AND_TRY_AGAIN_OR_CONTACT_SUPPORT}
  </>
)
