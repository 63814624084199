import { Tag as AntdTag, TagProps as AntDTagProps } from "antd"
import { PropsWithChildren } from "react"
import styled, { css } from "styled-components"
import { CaptionRegular } from "src/ui/typography/Text"

type StyleProps = {
  $danger?: boolean
  $noBottomMargin?: boolean
}

const StyledTag = styled(AntdTag)<StyleProps>`
  ${({ theme, $danger, $noBottomMargin }) => css`
    margin-bottom: ${$noBottomMargin ? 0 : theme.spacing.horizontal.xxs};
    background-color: ${$danger
      ? theme.colors.error.gxErrorBackground
      : theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgPrimary};
    border-color: ${$danger
      ? theme.colors.error.gxError
      : theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder};
  `}
`

export function Tag({ $danger, $noBottomMargin, children }: PropsWithChildren<AntDTagProps & StyleProps>) {
  return (
    <StyledTag $danger={$danger} $noBottomMargin={$noBottomMargin}>
      <CaptionRegular $gxError={$danger}>{children}</CaptionRegular>
    </StyledTag>
  )
}
