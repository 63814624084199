import {
  DatasourcesWithRunsDocument,
  DatasourcesWithRunsQuery,
  UseDataAssets_DataAssetFragment,
  UseDataAssets_DatasourceFragment,
} from "src/api/graphql/graphql-operations"
import { useUrlParams } from "src/common/utils/url-params"
import { exists } from "src/common/utils/typeguards"
import { TypedDocumentNode, useQuery } from "@apollo/client"
import {
  DataAssetSortOption,
  filterDatasources,
  sortDataAssets,
  valueIsValidDataAssetSortOption,
} from "src/DataAssets/utils"
import { DataAssetSortParams } from "src/DataAssets/DataAssetsFilter"

export type AssetAndDatasourceTuple = {
  asset: UseDataAssets_DataAssetFragment
  datasource: Omit<UseDataAssets_DatasourceFragment, "assets">
}

export interface UseDataAssetsResult {
  //datasourceCount is the number of data sources before any filtering
  datasourceCount: number
  //assetCount is the number of assets before any filtering
  assetCount: number
  loading: boolean
  error: boolean
  isEmpty: boolean
  assetAndDatasourceTuples: AssetAndDatasourceTuple[]
}

export const DefaultDataAssetSortOption: DataAssetSortOption = "validation-time-desc"

function useDataAssets(
  document: TypedDocumentNode<unknown, Record<string, never>> = DatasourcesWithRunsDocument,
): UseDataAssetsResult {
  const [{ datasourceType, search, failures, order: orderUrlParam }] = useUrlParams<DataAssetSortParams>({
    failures: "",
    search: "",
    datasourceType: [],
    order: DefaultDataAssetSortOption,
  })

  const order = valueIsValidDataAssetSortOption(orderUrlParam) ? orderUrlParam : DefaultDataAssetSortOption
  const { data, loading, error } = useQuery<DatasourcesWithRunsQuery>(document)
  const onlyFailures = failures === "true"
  const datasourceList = filterDatasources(data?.datasourcesV2, datasourceType, search)

  const assetAndDatasourceTuples: AssetAndDatasourceTuple[] = sortDataAssets(
    datasourceList.flatMap(({ assets, ...datasource }) =>
      assets
        .filter(exists)
        .map((asset) => ({
          datasource,
          asset,
        }))
        .filter(({ asset }) => (onlyFailures ? asset.aggregateSuccessStatus === false : true)),
    ),
    order,
  )

  const isEmpty = !loading && data?.datasourcesV2.length === 0
  const datasourceCount = data?.datasourcesV2.length ?? 0
  const assetCount = data?.datasourcesV2.flatMap((datasource) => datasource.assets).length ?? 0

  return { error: Boolean(error), datasourceCount, assetCount, isEmpty, loading, assetAndDatasourceTuples }
}

export { useDataAssets }
