/* eslint-disable react-refresh/only-export-components */ // FIXME
import { useEffect, useState } from "react"
import { PickNewOrExistingDataSource } from "src/DataAssets/connect-to-data/PickNewOrExistingDataSource"
import { AddAssetToNewDataSource } from "src/DataAssets/connect-to-data/AddAssetToNewDataSource"
import { AddAssetToExistingDataSource } from "src/DataAssets/connect-to-data/AddAssetToExistingDataSource"
import { Drawer } from "src/ui/Drawer/Drawer"
import { DatasourceWithRunsFragment } from "src/api/graphql/graphql-operations"
import { DrawerStyles } from "antd/es/drawer/DrawerPanel"
import { SupportedDataSource, defaultValues } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"
import { CreateGXUserPage } from "src/DataAssets/connect-to-data/CreateGXUserPage"

export type DataSourceChoice =
  | {
      createNew: true
      dataSourceType: SupportedDataSource
    }
  | {
      createNew: false
      dataSourceType: SupportedDataSource
      dataSource: DatasourceWithRunsFragment
    }

type Props = {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  dataSourceChoiceFromEmptyState?: DataSourceChoice
}

export const DataSourceCrudDrawerStyles: DrawerStyles = {
  body: { padding: "0px", display: "flex", flexDirection: "column", height: "100%", width: "100%" },
}

export function NewAssetDrawer({ isOpen, setIsOpen, dataSourceChoiceFromEmptyState }: Props) {
  const [dataSourceChoice, setDataSourceChoice] = useState<DataSourceChoice | undefined>(
    () => dataSourceChoiceFromEmptyState,
  )

  const [GXUserCheckboxChecked, setGXUserCheckboxChecked] = useState(false)
  const [isOnInfoPage, setIsOnInfoPage] = useState(true)

  useEffect(() => {
    if (dataSourceChoiceFromEmptyState) {
      setDataSourceChoice(dataSourceChoiceFromEmptyState)
    }
  }, [dataSourceChoiceFromEmptyState])

  const selectedDataSource = dataSourceChoice?.dataSourceType
  const isNewDataAsset = dataSourceChoice?.createNew
  if (!isOpen) {
    return null
  }

  const onBack = () => {
    setIsOnInfoPage(true)
    setDataSourceChoice(undefined)
    setGXUserCheckboxChecked(false)
  }

  const onContinue = () => {
    setIsOnInfoPage(false)
    setGXUserCheckboxChecked(false)
  }

  return (
    <Drawer
      styles={DataSourceCrudDrawerStyles}
      open
      onClose={() => {
        setIsOpen(false)
        setDataSourceChoice(undefined)
        setGXUserCheckboxChecked(false)
        setIsOnInfoPage(true)
      }}
      title={dataSourceChoice?.createNew ? "Connect to a Data Source" : "Add a Data Asset"}
    >
      <>
        {!selectedDataSource && <PickNewOrExistingDataSource onChoice={setDataSourceChoice} />}
        {dataSourceChoice?.createNew === false && (
          <AddAssetToExistingDataSource
            onFinish={() => setIsOpen(false)}
            onBack={() => setDataSourceChoice(undefined)}
            dataSource={dataSourceChoice.dataSource}
            dataSourceType={dataSourceChoice.dataSourceType}
          />
        )}
        {isNewDataAsset && selectedDataSource && (
          <>
            {isOnInfoPage ? (
              <CreateGXUserPage
                dataSourceType={selectedDataSource}
                GXUserCheckboxChecked={GXUserCheckboxChecked}
                setGXUserCheckboxChecked={setGXUserCheckboxChecked}
                onBack={onBack}
                onContinue={onContinue}
              />
            ) : (
              <AddAssetToNewDataSource
                dataSourceType={selectedDataSource}
                onBack={() => setIsOnInfoPage(true)}
                onFinish={() => setIsOpen(false)}
                data={defaultValues[selectedDataSource]}
              />
            )}
          </>
        )}
      </>
    </Drawer>
  )
}
