import { useQuery } from "@apollo/client"
import { Table, Tag } from "antd"
import type { TableProps } from "antd"
import { useParams } from "react-router-dom"

import { graphql } from "src/api/graphql/gql"
import { PageHeader } from "src/ui/PageHeader/PageHeader"
import { DataAssetTabs } from "src/DataAssets/AssetDetails/DataAssetTabs"
import { MainContent } from "src/layout/MainContent"
import { getDataAssetBreadcrumbs } from "src/DataAssets/AssetDetails/DataAssetBreadcrumbs"
import { parseJSONStringConfig } from "src/DataAssets/AssetDetails/utils"
import { SupportedDataSourceJson } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"
import { GetExpectationsQuery, ExpectationValidationResultV2 } from "src/api/graphql/graphql"
import { theme } from "src/ui/themes/theme"

const ExpectationsTabDataAssetDocument = graphql(`
  query ExpectationsTabDataAsset($id: UUID!) {
    dataAsset(id: $id) {
      name
      config
      datasourceV2 {
        name
        type
        config
      }
    }
  }
`)

export const getExpectationsDocument = graphql(`
  query GetExpectations($input: GetExpectationsInput!) {
    getExpectations(input: $input) {
      defaultKwargValues
      domain {
        domainKwargs
        domainType
        id
      }
      geCloudId
      kwargTypes
      kwargs
      validationResults(input: { limit: 1 }) {
        runTime
        success
      }
    }
  }
`)

export const SimpleExpectationsTab = () => {
  const { assetId = "" } = useParams<{
    assetId: string
  }>()

  const { data: dataAssetData, loading: dataAssetLoading } = useQuery(ExpectationsTabDataAssetDocument, {
    variables: {
      id: assetId,
    },
    skip: !assetId,
  })
  const { data: expectationsData, loading: expectationsLoading } = useQuery(getExpectationsDocument, {
    variables: {
      input: { dataAssetId: assetId },
    },
    skip: !assetId,
  })

  const dataAssetName = dataAssetData?.dataAsset?.name ?? ""
  const dataSourceName = dataAssetData?.dataAsset?.datasourceV2.name ?? "Unknown"
  const dataSourceType = dataAssetData?.dataAsset?.datasourceV2.type
  const dataSourceConfig = parseJSONStringConfig(
    dataAssetData?.dataAsset?.datasourceV2.config ?? "{}",
  ) as SupportedDataSourceJson
  const dataAssetConfig = parseJSONStringConfig(dataAssetData?.dataAsset?.config ?? "{}")

  return (
    <PageHeader
      headerContent={{
        title: dataAssetName,
        rootPath: "data-assets",
        navigateBackTo: "/data-assets",
        customBreadcrumbs: getDataAssetBreadcrumbs({
          dataSourceName,
          dataSourceType,
          config: dataSourceConfig,
          tooltipPlacement: "right",
        }),
        footer: <DataAssetTabs currentTab="expectations" />,
      }}
      loading={expectationsLoading || dataAssetLoading}
    >
      <MainContent $noIllustration={true}>
        <Table columns={columns} dataSource={getExpectationRowData(expectationsData, dataAssetConfig)} />
      </MainContent>
    </PageHeader>
  )
}

interface DataType {
  column: string
  expectation: string
  lastRun: Omit<ExpectationValidationResultV2, "__typename">
  actions: string
}

const columns: TableProps<DataType>["columns"] = [
  {
    title: "Column",
    dataIndex: "column",
    key: "column",
  },
  {
    title: "Expectation",
    dataIndex: "expectation",
    key: "exp",
  },
  {
    title: "Last run",
    dataIndex: "lastRun",
    key: "run",
    render: (_, { lastRun }) => {
      const color = lastRun.success ? theme.colors.success.gxSuccess : theme.colors.error.gxError
      return (
        <span>
          <Tag color={color}>{lastRun.success ? "Pass" : "Fail"}</Tag> {lastRun.runTime}
        </span>
      )
    },
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
  },
]

const getExpectationRowData = (
  data: GetExpectationsQuery | undefined,
  config: { table_name?: string } | undefined,
): DataType[] => {
  if (!data?.getExpectations || !config) {
    return []
  }

  return data?.getExpectations?.map((exp) => ({
    column: config?.table_name ?? "Unknown",
    expectation: "",
    lastRun: {
      success: exp?.validationResults?.[0]?.success ?? undefined,
      runTime: exp?.validationResults?.[0]?.runTime ?? "Unknown",
    },
    actions: "",
  }))
}
