import { Tag } from "antd"
import { entries, get, orderBy, snakeCase, uniqBy } from "lodash-es"
import { RadioCardProps } from "src/ui/Radio/RadioCardGroup"
import { jsonSchemas } from "src/schemas/expectation-catalog-schemas"
import { ExpectationJsonSchema } from "src/Expectation/uiForms/ExpectationConfigForm"

export interface ExpectationCardProps extends RadioCardProps {
  value: string
  category: string
  title: string
}

export function getPathForCreatedExpectation(
  assetId: string,
  selectedExpectationSuiteId: string,
  config: string,
  jsonSchema?: ExpectationJsonSchema,
) {
  if (jsonSchema) {
    return getPathForExpectation(assetId, selectedExpectationSuiteId, config, jsonSchema)
  }
  return deprecatedGetPathForExpectation(assetId, selectedExpectationSuiteId, config)
}

function getPathForExpectation(
  assetId: string,
  selectedExpectationSuiteId: string,
  config: string,
  jsonSchema?: ExpectationJsonSchema,
) {
  const parsedConfigValue = JSON.parse(config)
  const domainType = jsonSchema?.properties.metadata.properties.domain_type.const
  let anchorTag
  if (domainType === "multicolumn") {
    const columnList = parsedConfigValue.column_list.sort()
    const encodedColumnList = columnList.map((column: string) => encodeURIComponent(column))
    anchorTag = `#${encodedColumnList.join("--")}`
  } else if (domainType === "column_pair") {
    const columnA = parsedConfigValue.column_A
    const columnB = parsedConfigValue.column_B
    const columnList = [columnA, columnB].sort()
    const encodedColumnList = columnList.map((column: string) => encodeURIComponent(column))
    anchorTag = `#${encodedColumnList.join("--")}`
  } else if (domainType === "table") {
    anchorTag = "#Table-level-Expectations"
  } else if (domainType === "column") {
    anchorTag = `#${encodeURIComponent(parsedConfigValue.column)}`
  } else {
    anchorTag = ""
  }
  return `/data-assets/${encodeURIComponent(assetId)}/expectations/expectation-suites/${encodeURIComponent(
    selectedExpectationSuiteId,
  )}${anchorTag}`
}

function deprecatedGetPathForExpectation(assetId: string, selectedExpectationSuiteId: string, config: string) {
  const parsedConfigValue = JSON.parse(config)
  let columnName: string
  const column = parsedConfigValue.kwargs.column
  if (column !== undefined) {
    columnName = `#${encodeURIComponent(column)}`
  } else {
    columnName = ""
  }
  return `/data-assets/${encodeURIComponent(assetId)}/expectations/expectation-suites/${encodeURIComponent(
    selectedExpectationSuiteId,
  )}${columnName}`
}

const SUPPORTED_EXPECTATIONS = new Set<keyof typeof jsonSchemas>([
  "expectColumnDistinctValuesToBeInSet",
  "expectColumnDistinctValuesToContainSet",
  "expectColumnDistinctValuesToEqualSet",
  "expectColumnMaxToBeBetween",
  "expectColumnMeanToBeBetween",
  "expectColumnMedianToBeBetween",
  "expectColumnMinToBeBetween",
  "expectColumnMostCommonValueToBeInSet",
  "expectColumnPairValuesAToBeGreaterThanB",
  "expectColumnPairValuesToBeEqual",
  "expectColumnProportionOfUniqueValuesToBeBetween",
  "expectColumnStdevToBeBetween",
  "expectColumnSumToBeBetween",
  "expectColumnToExist",
  "expectColumnUniqueValueCountToBeBetween",
  "expectColumnValueLengthsToBeBetween",
  "expectColumnValueLengthsToEqual",
  "expectColumnValuesToBeBetween",
  "expectColumnValuesToBeInSet",
  "expectColumnValuesToBeInTypeList",
  "expectColumnValuesToBeNull",
  "expectColumnValuesToBeOfType",
  "expectColumnValuesToBeUnique",
  "expectColumnValuesToMatchLikePattern",
  "expectColumnValuesToMatchLikePatternList",
  "expectColumnValuesToMatchRegex",
  "expectColumnValuesToMatchRegexList",
  "expectColumnValuesToNotBeInSet",
  "expectColumnValuesToNotBeNull",
  "expectColumnValuesToNotMatchLikePattern",
  "expectColumnValuesToNotMatchLikePatternList",
  "expectColumnValuesToNotMatchRegex",
  "expectColumnValuesToNotMatchRegexList",
  "expectColumnValueZScoresToBeLessThan",
  "expectCompoundColumnsToBeUnique",
  "expectMulticolumnSumToEqual",
  "expectSelectColumnValuesToBeUniqueWithinRecord",
  "expectTableColumnCountToBeBetween",
  "expectTableColumnCountToEqual",
  "expectTableColumnsToMatchOrderedList",
  "expectTableColumnsToMatchSet",
  "expectTableRowCountToBeBetween",
  "expectTableRowCountToEqual",
  "expectTableRowCountToEqualOtherTable",
])

export function getAllDataQualityIssues(): { label: string; value: string }[] {
  return orderBy(
    uniqBy(
      entries(jsonSchemas)
        .filter(([key]) => SUPPORTED_EXPECTATIONS.has(key as keyof typeof jsonSchemas))
        .map(([, value]) => {
          const issue = get(value, "schema.properties.metadata.properties.data_quality_issues.const.0", "") // TODO support multiple categories per expectation
          return {
            value: snakeCase(issue),
            label: issue,
          }
        }),
      "label",
    ),
    "label",
  )
}

export function getFilteredExpectationsCardsData(
  onExpectationClick: (value: string) => void,
  searchInput?: string,
  selectedCategoryFilter?: string,
): ExpectationCardProps[] {
  return orderBy(
    entries(jsonSchemas)
      .filter(([key]) => SUPPORTED_EXPECTATIONS.has(key as keyof typeof jsonSchemas))
      .map(([key, value]) => {
        const name = snakeCase(key)
        const category = get(value, "schema.properties.metadata.properties.data_quality_issues.const.0", "") // TODO support multiple categories per expectation
        return {
          value: name,
          title: value.schema.title,
          category,
          meta: {
            description: <Tag>{category}</Tag>,
          },
          hoverable: true,
          onClick: () => onExpectationClick(name),
        }
      })
      .filter((cardData) => snakeCase(cardData.category) === selectedCategoryFilter || !selectedCategoryFilter)
      .filter((cardData) => cardData.title.toUpperCase().includes(searchInput ? searchInput.trim().toUpperCase() : "")),
    "value",
    "asc",
  )
}
