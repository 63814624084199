import { PanelHeaderProps } from "src/ui/Collapse/CollapsePanel"
import { BodySemiBold, CaptionRegular } from "src/ui/typography/Text"
import { CheckpointActions } from "src/Checkpoints/CheckpointActions"
import styled, { css } from "styled-components"

interface CheckpointHeaderProps extends PanelHeaderProps {
  id: string
}

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`

const HeaderTopRowContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: ${theme.spacing.scale.xxs};
    line-height: 32px;
  `};
`

const StyledTitleContainer = styled.div`
  display: flex;
  gap: 15px;
`

const StyledTitle = styled(BodySemiBold)`
  overflow-wrap: anywhere;
  min-width: 50px;
`

const StyledCaptionContainer = styled.div`
  margin: auto 0;
  min-width: 50px;
`

const StyledCheckpointActions = styled.div`
  display: flex;
`

const StyledCaptionRegular = styled(CaptionRegular)`
  ${({ theme }) => css`
    color: ${theme.colors.neutralColorPalette.blacks.colorTextSecondary};
  `};
  line-height: normal;
`

export const CheckpointHeader = ({ id, title, subtitle, secondaryHeaderLine }: CheckpointHeaderProps) => {
  return (
    <HeaderContainer>
      <HeaderTopRowContainer>
        <StyledTitleContainer>
          <StyledTitle>{title}</StyledTitle>
          <StyledCaptionContainer>
            <StyledCaptionRegular>{subtitle}</StyledCaptionRegular>
          </StyledCaptionContainer>
        </StyledTitleContainer>
        <StyledCheckpointActions>
          <CheckpointActions checkpointId={id} />
        </StyledCheckpointActions>
      </HeaderTopRowContainer>
      {secondaryHeaderLine && secondaryHeaderLine}
    </HeaderContainer>
  )
}
