import React, { useState } from "react"
import { PageHeader } from "src/ui/PageHeader/PageHeader"
import { MainContent } from "src/layout/MainContent"
import { DataAssetHeaderActions } from "src/DataAssets/AssetDetails/DataAssetHeaderActions"
import { ValidateExpectationsModal } from "src/DataAssets/AssetDetails/ValidateExpectationsModal"
import { DataAssetTabs } from "src/DataAssets/AssetDetails/DataAssetTabs"
import { DataAssetQuery } from "src/api/graphql/graphql-operations"
import { useExpectationSuite } from "src/DataAssets/AssetDetails/Validations/ValidationHooks"
import { useGetSplitterData } from "src/DataAssets/AssetDetails/Splitters/useGetSplitterData"
import { getDataAssetBreadcrumbs } from "src/DataAssets/AssetDetails/DataAssetBreadcrumbs"
import { SupportedDataSourceJson } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"
import { parseJSONStringConfig } from "src/DataAssets/AssetDetails/utils"

// Define the types for the props
type ValidationsTabHeaderProps = {
  assetId: string
  dataAssetData: DataAssetQuery | undefined
  navigateBackToCheckpoints: boolean
  listLoading: boolean
  expectationSuiteId: string
}

export const ValidationsTabHeader: React.FC<React.PropsWithChildren<ValidationsTabHeaderProps>> = ({
  assetId,
  dataAssetData,
  navigateBackToCheckpoints,
  listLoading,
  children,
  expectationSuiteId,
}) => {
  const [openValidateExpectationsModal, setOpenValidateExpectationsModal] = useState(false)

  const { expectationSuiteData } = useExpectationSuite(expectationSuiteId)
  const expectationCount = expectationSuiteData?.expectationSuiteV2?.expectations.length ?? 0

  const { splitter, isSplitterSupported } = useGetSplitterData({ isVisible: true })

  const showValidateSubsetButton = !!(isSplitterSupported && splitter)

  const subtitle = `${expectationCount} Expectation${expectationCount !== 1 ? "s" : ""}`
  const dataSourceName = dataAssetData?.dataAsset?.datasourceV2.name ?? "Unknown"
  const dataSourceType = dataAssetData?.dataAsset?.datasourceV2.type
  const config = dataAssetData?.dataAsset?.datasourceV2.config ?? "{}"

  return (
    <PageHeader
      headerContent={{
        title: dataAssetData?.dataAsset?.name || "",
        rootPath: "data-assets",
        subtitle,
        navigateBackTo: navigateBackToCheckpoints ? "/checkpoints" : "/data-assets",
        rightActions: {
          reactNode: (
            <DataAssetHeaderActions
              assetId={assetId}
              showValidateSubsetButton={showValidateSubsetButton}
              noExpectations={expectationCount === 0}
              expectationSuiteId={expectationSuiteId}
              onValidateExpectationsButtonClick={() => setOpenValidateExpectationsModal(true)}
            />
          ),
        },
        customBreadcrumbs: getDataAssetBreadcrumbs({
          dataSourceName,
          dataSourceType,
          config: parseJSONStringConfig(config) as SupportedDataSourceJson,
          tooltipPlacement: "right",
        }),
        footer: <DataAssetTabs expectationSuiteId={expectationSuiteId} currentTab="validations" />,
      }}
      loading={listLoading}
    >
      <MainContent>
        {children}
        <ValidateExpectationsModal
          expectationSuiteID={expectationSuiteId}
          assetRefID={assetId}
          open={openValidateExpectationsModal}
          onCancel={() => setOpenValidateExpectationsModal(false)}
          onOk={() => setOpenValidateExpectationsModal(false)}
        />
      </MainContent>
    </PageHeader>
  )
}
