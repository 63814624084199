/* eslint-disable react-refresh/only-export-components */ // FIXME
import { Alert, ConfigProvider, Flex, Skeleton, Table } from "antd"
import {
  DataAssetWithColumnDescriptiveMetricsDocument,
  DataAssetWithColumnDescriptiveMetricsQuery,
} from "src/api/graphql/graphql-operations"
import { useQuery } from "@apollo/client"
import { formatDateForTable, formatTime } from "src/common/utils/formatTime"
import {
  formatNumber,
  formatNumberAsPercentage,
  formatNumberScaleMaxDigits as formatNumberScaleMaxDecimalDigits,
} from "src/common/utils/formatNumber"
import { CaptionRegular, SpanSemiBold } from "src/ui/typography/Text"
import { GenerateColumnDescriptiveMetricsButton } from "src/DataAssets/AssetDetails/RunColumnDescriptiveMetricsButton"
import { useJobState } from "src/common/hooks/useJobState/useJobState"
import { useEffect, useState } from "react"
import { usePreviousValue } from "src/common/hooks/usePreviousValue"
import { MetricRun, MetricValueUnion } from "src/api/graphql/graphql"
import { MetricsTableEmptyScreen } from "src/DataAssets/AssetDetails/MetricsTableEmptyScreen"
import { AgentNotConnectedModal } from "src/DataAssets/AssetDetails/AgentNotConnectedModal"
import { AppLink } from "src/ui/AppLink/AppLink"
import { SkeletonTable, SkeletonTableColumnsType } from "src/common/components/SkeletonTable"
import styled, { useTheme } from "styled-components"
export const METRIC_UNAVAILABLE = "-"

const StyledCaptionRegular = styled(CaptionRegular)`
  font-size: 14px;
`

interface MetricTabProps {
  assetId: string
}
interface MetricsTableDatum {
  key: number
  colName: string
  type: string
  rawType: string
  min: number | string // datetime values are returned as strings
  max: number | string // datetime values are returned as strings
  mean: number
  median: number
  nullPct: string
}

export function filterExtendedTypeInformation(type: string | null | undefined): string {
  // Filtering extended type information for Snowflake tables, which include extended information in parentheses (e.g. VARCHAR(16777216))
  if (type === null || type === undefined) {
    return ""
  }
  return type.split("(")[0]
}

function isQueryDataAsset(dataAssetQueryResult: DataAssetWithColumnDescriptiveMetricsQuery): boolean {
  if (!dataAssetQueryResult.dataAsset?.config) {
    return false
  }
  try {
    const parsed_config = JSON.parse(dataAssetQueryResult.dataAsset?.config)
    return parsed_config.type.toLowerCase() === "query"
  } catch {
    return false
  }
}

export function formatNumberScaleFromType(type: string | null | undefined, value: number): string {
  // Formatting number scale based on column type
  // Note this logic is snowflake specific
  if (type === null || type === undefined) {
    return ""
  }
  // FLOAT values (and synonyms FLOAT , FLOAT4 , FLOAT8, DOUBLE, DOUBLE PRECISION, REAL see https://docs.snowflake.com/en/sql-reference/data-types-numeric#float-float4-float8 and https://docs.snowflake.com/en/sql-reference/data-types-numeric#double-double-precision-real) don't have an associated scale and are formatted with a default maximum number of decimal places. The snowflake-sqlalchemy library used to compute the underlying metric converts synonyms to FLOAT or REAL, so we only need to check for those. See https://github.com/snowflakedb/snowflake-sqlalchemy/blob/v1.5.1/src/snowflake/sqlalchemy/snowdialect.py#L75-L111 for the latest version.
  if (type === "FLOAT" || type === "REAL") {
    return formatNumberScaleMaxDecimalDigits(value)
  }
  // Regex to extract scale (second num in parens) from numeric types E.g. the scale of NUMBER(38, 0) is 0
  const numericScaleRegex = ".*(\\d+, *(?<scale>\\d+)).*"
  const scale = Number(type.match(numericScaleRegex)?.groups?.scale)
  // NaN check is needed because scale is NaN for types without a scale (e.g. INT):
  if (typeof scale === "number" && !isNaN(scale)) {
    return formatNumberScaleMaxDecimalDigits(value, scale)
  }
  return formatNumberScaleMaxDecimalDigits(value)
}

export function calculateNullPercentage(nullCount: number | null | undefined, rowCount: number): string | null {
  if (nullCount === null || nullCount === undefined) {
    return null
  }
  return ((100.0 * Number(nullCount)) / rowCount).toFixed(2)
}

function convertMetricsDataToTableData(
  dataAssetQueryResult: DataAssetWithColumnDescriptiveMetricsQuery | undefined,
): MetricsTableDatum[] | undefined {
  const rowCount = Number(dataAssetQueryResult?.dataAsset?.latestMetricRun?.rowCount ?? 0)
  return dataAssetQueryResult?.dataAsset?.latestMetricRun?.metrics.map(
    (metric, index) =>
      ({
        key: index,
        colName: metric.columnName,
        type: filterExtendedTypeInformation(metric.columnDataType),
        rawType: metric.columnDataType,
        min: getMetricValue(metric.valueRangeMin, metric.valueRangeMinUnion as MetricValueUnion),
        max: getMetricValue(metric.valueRangeMax, metric.valueRangeMaxUnion as MetricValueUnion),
        mean: metric.mean,
        median: metric.median,
        nullPct: calculateNullPercentage(metric.nullCount, rowCount),
      }) as MetricsTableDatum,
  )
}

export function compareMixedTypes(a: number | string | null, b: number | string | null) {
  // Two phases of sorting:
  //   By type, then by value
  //   Ex. datetimes are grouped then sorted together, and numbers are grouped and sorted
  //   order: null, number, string

  const typeA = typeof a
  const typeB = typeof b

  if (typeA === typeB) {
    switch (typeA) {
      case "string":
        return (a as string).localeCompare(b as string)
      case "number":
      default:
        return Number(a) - Number(b)
    }
  }

  // null is special bc we want to be lower than numbers
  if (a === null || a === undefined || b === null || b === undefined) {
    return a === null || a === undefined ? -1 : 1
  }

  // basic string sort of types
  return typeA > typeB ? 1 : -1
}

function getMetricValue(
  metricValue: number | null | undefined,
  metricValueUnion: MetricValueUnion,
): number | string | null {
  // use Union type to get numeric or string (datetime) value
  return metricValueUnion?.__typename === "MetricValueFloatType"
    ? (metricValueUnion.floatValue ?? null)
    : (metricValueUnion?.stringValue ?? null)
}

function renderIfSupported(value: number | string | null, record: MetricsTableDatum) {
  // https://docs.snowflake.com/en/sql-reference/data-types-datetime
  // DATE, TIMESTAMP, TIMESTAMP_NTZ, ...
  const isTimeBasedTypeRegex = "(TIME|DATE)"

  if (record.type.match(isTimeBasedTypeRegex) || value === null || value === undefined) {
    return METRIC_UNAVAILABLE
  }
  return formatNumberScaleFromType(record.rawType, value as number)
}

function isDate(value: string) {
  const parsed = Date.parse(value)
  // #parse returns NaN when invalid date
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#return_value
  if (isNaN(parsed)) {
    return false
  }
  return true
}

export function MetricsTable({ assetId }: MetricTabProps) {
  const jobState = useJobState({
    dataAssetId: assetId,
    type: "GenerateColumnDescriptiveMetrics",
  })
  const [isAgentErrorModalVisible, setIsAgentErrorModalVisible] = useState(false)
  // Must use context holder in order to use AppLink
  // Meanwhile, 'message' shows plaintext, so does not need a context holder

  // metrics query
  const {
    data: dataAssetQueryResult,
    loading,
    refetch: refetchMetrics,
  } = useQuery(DataAssetWithColumnDescriptiveMetricsDocument, {
    variables: {
      id: assetId,
    },
  })
  const previousJobStatus = usePreviousValue(jobState?.status)

  function formatDateOrNumberValue(value: string | number, record: MetricsTableDatum) {
    if (value === null || value === undefined) {
      return METRIC_UNAVAILABLE
    }
    if (typeof value === "string") {
      if (!isDate(value)) {
        return METRIC_UNAVAILABLE
      }
      return formatDateForTable(value)
    }
    return formatNumberScaleFromType(record.rawType, value)
  }

  useEffect(() => {
    // No op, fresh page load
    if (previousJobStatus === undefined) {
      return
    }

    const currentJobStatus = jobState?.status

    const delta = currentJobStatus !== previousJobStatus

    // No change detected
    if (!delta) {
      return
    }
    // looking at currentJobStatus to see if we need to refetch metrics
    switch (currentJobStatus) {
      case "complete":
        refetchMetrics()
        break
      case "error":
        break
    }
  }, [jobState?.status, previousJobStatus, refetchMetrics])

  const theme = useTheme()
  const dataSource = convertMetricsDataToTableData(dataAssetQueryResult)

  const columns = [
    {
      title: "Column",
      dataIndex: "colName",
      key: "colName",
      defaultSortOrder: "ascend" as const,
      sorter: (a: MetricsTableDatum, b: MetricsTableDatum) => a.colName.localeCompare(b.colName),
      render: (value: string) => <StyledCaptionRegular>{value}</StyledCaptionRegular>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a: MetricsTableDatum, b: MetricsTableDatum) => a.type.localeCompare(b.type),
      render: (value: string) => <StyledCaptionRegular>{value}</StyledCaptionRegular>,
    },
    {
      title: "Min",
      dataIndex: "min",
      key: "min",
      sorter: (a: MetricsTableDatum, b: MetricsTableDatum) => compareMixedTypes(a.min, b.min),
      render: (value: string, record: MetricsTableDatum) => (
        <StyledCaptionRegular>{formatDateOrNumberValue(value, record)}</StyledCaptionRegular>
      ),
    },
    {
      title: "Max",
      dataIndex: "max",
      key: "max",
      sorter: (a: MetricsTableDatum, b: MetricsTableDatum) => compareMixedTypes(a.max, b.max),
      render: (value: string, record: MetricsTableDatum) => (
        <StyledCaptionRegular>{formatDateOrNumberValue(value, record)}</StyledCaptionRegular>
      ),
    },
    {
      title: "Mean",
      dataIndex: "mean",
      key: "mean",
      sorter: (a: MetricsTableDatum, b: MetricsTableDatum) => compareMixedTypes(a.mean, b.mean),
      render: (value: string, record: MetricsTableDatum) => (
        <StyledCaptionRegular>{renderIfSupported(value, record)}</StyledCaptionRegular>
      ),
    },
    {
      title: "Median",
      dataIndex: "median",
      key: "median",
      sorter: (a: MetricsTableDatum, b: MetricsTableDatum) => compareMixedTypes(a.median, b.median),
      render: (value: string, record: MetricsTableDatum) => (
        <StyledCaptionRegular>{renderIfSupported(value, record)}</StyledCaptionRegular>
      ),
    },
    {
      title: "Null %",
      dataIndex: "nullPct",
      key: "nullPct",
      sorter: (a: MetricsTableDatum, b: MetricsTableDatum) => Number(a.nullPct) - Number(b.nullPct),
      render: (value: string) => <StyledCaptionRegular>{formatNumberAsPercentage(value)}</StyledCaptionRegular>,
    },
  ]

  const queryAssetWarningMessage = (
    <>
      Metrics for query assets are not supported. Instead,{" "}
      <AppLink to="https://docs.snowflake.com/en/sql-reference/sql/create-view" newTab={true}>
        create a view
      </AppLink>{" "}
      in your database from your query and use that to create a table asset within GX.
    </>
  )

  const caption = (
    <div
      style={{
        backgroundColor: theme.colors.neutralColorPalette.whites.white,
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
      }}
    >
      <Caption
        latestMetricRun={dataAssetQueryResult?.dataAsset?.latestMetricRun}
        dataAssetId={assetId}
        loading={loading}
      />
    </div>
  )
  const metricsDisplay =
    dataAssetQueryResult && dataAssetQueryResult?.dataAsset?.latestMetricRun ? (
      <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]} caption={caption}>
        {caption}
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerBorderRadius: 0, // no table header border radius b/c caption row above table has top border radius of 8px
              },
            },
          }}
        >
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            scroll={{ x: "10px", y: "60vh" }} // the x value ensures that a horizontal scrollbar is not always shown -- see https://github.com/ant-design/ant-design/issues/15794
          />
        </ConfigProvider>
      </SkeletonTable>
    ) : (
      <MetricsTableEmptyScreen
        dataAssetId={assetId}
        disabled={dataAssetQueryResult && isQueryDataAsset(dataAssetQueryResult)}
      />
    )

  return loading ? (
    <></>
  ) : (
    <>
      {dataAssetQueryResult && isQueryDataAsset(dataAssetQueryResult) && (
        <Alert message={queryAssetWarningMessage} type="warning" showIcon />
      )}
      {metricsDisplay}

      <AgentNotConnectedModal isVisible={isAgentErrorModalVisible} setIsVisible={setIsAgentErrorModalVisible} />
    </>
  )
}

export function checkIfFullCdmHasBeenRun(latestMetricRun: MetricRun | null | undefined): boolean {
  // Here we are checking if the full CDMs have been run by checking if nullCount values exist, which are only calculated for the full list of metrics.
  // this is because both full CDM and Table Metrics jobs are sent to the agent as `metrics_list_requests` and the agent does not differentiate between the two.
  if (
    latestMetricRun?.metrics &&
    latestMetricRun.metrics[0].nullCount !== null &&
    latestMetricRun.metrics[0].nullCount !== undefined
  ) {
    return true
  }
  return false
}

export function Caption({
  latestMetricRun,
  dataAssetId,
  loading,
}: {
  latestMetricRun: MetricRun | null | undefined
  dataAssetId: string
  loading?: boolean
}) {
  const should_show_loading = loading || !latestMetricRun

  let cdmButtonLabel = "Refresh"
  let cdmButtonLoadingLabel = "Refreshing..."

  const hasFullCdmBeenRun = checkIfFullCdmHasBeenRun(latestMetricRun)
  cdmButtonLabel = hasFullCdmBeenRun ? "Refresh" : "Profile Data"
  cdmButtonLoadingLabel = hasFullCdmBeenRun ? "Refreshing..." : "Profiling Data..."

  // rowCount is -1 if the full CDM has not been run, or the CDM job is incomplete.
  // We want to display a dash in this case, which is handled by the formatNumber function below.
  const rowCount = (latestMetricRun?.rowCount ?? -1) > 0 ? latestMetricRun?.rowCount : undefined

  return (
    <Flex justify="space-between" style={{ padding: "20px" }}>
      <Flex gap="4px" align="center">
        <SpanSemiBold>Row Count: </SpanSemiBold>
        {should_show_loading ? (
          <Skeleton loading paragraph={false} active round style={{ minWidth: 25 }} />
        ) : (
          formatNumber(rowCount)
        )}
      </Flex>
      <Flex gap="middle" align="center">
        <Flex gap="4px" align="center">
          <CaptionRegular $colorTextTertiary>Last Fetched:</CaptionRegular>
          {should_show_loading ? (
            <Skeleton loading paragraph={false} active round style={{ minWidth: 25 }} />
          ) : (
            <CaptionRegular $colorTextTertiary>
              {!should_show_loading && formatTime(latestMetricRun.lastFetched)}
            </CaptionRegular>
          )}
        </Flex>
        <GenerateColumnDescriptiveMetricsButton
          dataAssetId={dataAssetId}
          label={cdmButtonLabel}
          loadingLabel={cdmButtonLoadingLabel}
        />
      </Flex>
    </Flex>
  )
}
