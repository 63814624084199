/* eslint-disable react-refresh/only-export-components */ // FIXME
import { useApolloClient, useQuery } from "@apollo/client"
import { ExecutableDefinitionNode } from "graphql"
import { differenceWith, groupBy, isEqual, uniqBy, values } from "lodash-es"
import { unmaskFragment } from "src/api/graphql/fragment-masking"
import { graphql } from "src/api/graphql/gql"
import { EntityType, JobFragment } from "src/api/graphql/graphql"
import { REACT_APP_JOB_POLL_INTERVAL } from "src/common/env"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
export const JobFragmentDocument = graphql(`
  fragment Job on Job {
    __typename
    id
    timeQueued
    timeStarted
    timeCompleted
    status
    errorMessage
    sourceResources {
      entityId
      entityType
    }
    createdResources {
      entityId
      entityType
    }
    jobError {
      errorCode
      errorParams
      errorStackTrace
    }
  }
`)

// gotta re-specify all the fields here instead of re-using JobFragment because of fragment masking
export const JobFragmentWithTableNamesDocument = graphql(`
  fragment JobWithTableNames on Job {
    tableNames

    __typename
    id
    timeQueued
    timeStarted
    timeCompleted
    status
    errorMessage
    sourceResources {
      entityId
      entityType
    }
    createdResources {
      entityId
      entityType
    }
    jobError {
      errorCode
      errorParams
      errorStackTrace
    }
  }
`)

export const GetJobsDocument = graphql(`
  query GetJobs($isCheckboxAssetCreationEnabled: Boolean = false) {
    jobs {
      __typename
      ...Job @skip(if: $isCheckboxAssetCreationEnabled)
      ...JobWithTableNames @include(if: $isCheckboxAssetCreationEnabled)
    }
  }
`)

export function PollForJobs() {
  const client = useApolloClient()
  const isCheckboxAssetCreationEnabled = useIsFeatureEnabled("checkboxAssetCreation")
  useQuery(GetJobsDocument, {
    variables: { isCheckboxAssetCreationEnabled },
    pollInterval: REACT_APP_JOB_POLL_INTERVAL,
    canonizeResults: true,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      getJobListPerEntity(data.jobs.map((job) => unmaskFragment(JobFragmentDocument, job))).forEach((jobs) => {
        const entityType = jobs[0].entityType
        const entityId = jobs[0].entityId
        // get rid of properties we added to facilitate grouping
        const jobsFromQuery = jobs.map(({ entityId, entityType, ...job }) => job)

        const fragment = entityTypeToFragment(entityType)
        if (fragment === undefined) {
          console.error("cacheEntityType.unknown", entityType)
          return
        }

        client.cache.updateFragment(
          {
            fragment: fragment,
            fragmentName: (fragment.definitions[0] as ExecutableDefinitionNode).name?.value,
            id: `${entityType}:${entityId}`,
          },
          // typechecking isn't quite working right here; data should be a union of specific document nodes
          (data) => {
            if (!data) {
              return undefined
            }

            // order matters here! _.difference* only returns items that are not included in second array, so use "new" data for first arg
            const updatedOrNewJobs = differenceWith(jobsFromQuery, data.jobs ?? [], isEqual)

            if (updatedOrNewJobs.length === 0) {
              return undefined
            }

            // order matters here! first item gets kept if duplicate encountered later
            const jobs = uniqBy([...jobsFromQuery, ...(data?.jobs ?? [])], "id")
            return {
              ...data,
              jobs,
            }
          },
        )
      })
    },
  })
  return null
}

type CacheEntityType = Omit<EntityType, "DataAsset"> | "AssetRef"

export function entityTypeToCacheEntityType(entityType: EntityType): CacheEntityType {
  switch (entityType) {
    case "Checkpoint":
    case "Datasource":
    case "ExpectationSuite":
    case "SuiteValidationResult":
    case "MetricRun":
    case "DraftConfig":
      return entityType
    case "DataAsset":
      return "AssetRef"
  }
}

export const DataAssetJobsFragmentDocument = graphql(`
  fragment DataAssetJobs on AssetRef {
    __typename
    jobs @client(always: true) {
      __typename
      ...Job
    }
  }
`)

export const CheckpointWithJobsFragmentDocument = graphql(`
  fragment CheckpointWithJobs on Checkpoint {
    __typename
    ...Checkpoint
    jobs @client(always: true) {
      __typename
      ...Job
    }
  }
`)

export function entityTypeToFragment(entityType: CacheEntityType) {
  switch (entityType) {
    case "AssetRef":
      return DataAssetJobsFragmentDocument
    case "Checkpoint":
      return CheckpointWithJobsFragmentDocument
  }
}

// take the jobs from the query, take the sourceResources from each job, filter out the ones we don't care about, and group by entityId
// the result is something like: [[entity1-jobX, entity1-jobY], [entity2-jobX, entity2-jobZ, entity2-jobY], [entity3-jobX, entity3-jobY, entity3-jobZ]]
export function getJobListPerEntity(jobs: JobFragment[]) {
  if (!jobs || jobs.length === 0) {
    return []
  }

  return values(
    groupBy(
      jobs.flatMap((job) =>
        job.sourceResources
          .filter(({ entityType }) => entityType === "DataAsset" || entityType === "Checkpoint")
          .map(({ entityId, entityType }) => ({
            ...job,
            entityId: entityId,
            entityType: entityTypeToCacheEntityType(entityType),
          })),
      ),
      "entityId",
    ),
  )
}
