export const RUN_CHECKPOINT_LABEL = "Run Checkpoint"
export const RUN_CHECKPOINT_IN_PROGRESS_LABEL = "Validating Checkpoint..."
export const RUN_CHECKPOINT_VIA_SNIPPET_LABEL = "Generate Snippet"

export const EDIT_CHECKPOINT_DRAWER_TITLE = "Edit Checkpoint"
export const EDIT_CHECKPOINT_SUCCESS_MESSAGE = "Checkpoint updated successfully"
export const ALERT_CREATE_SUCCESS_MESSAGE = "Alert created successfully"
export const ALERT_DELETE_SUCCESS_MESSAGE = "Alert deleted successfully"
export const ALERT_UPDATE_SUCCESS_MESSAGE = "Alert updated successfully"
export const EDIT_CHECKPOINT_ERROR_TEXT = {
  EMPTY: "Field cannot be blank.",
  DUPLICATE_NAME: "A Checkpoint with this name already exists.",
  UNEXPECTED_ERROR:
    "An unexpected error occurred when attempting to update this Checkpoint. Try again or contact support.",
  CONNECTION_TEST_FAILURE: "Ensure incoming webhook link is correct.",
}
export const ERROR_TITLE_CHECKPOINT_EDIT = "Error editing Checkpoint"
export const FAILED_TO_SAVE_ALERT = "Failed to save alert"
export const EDIT_CHECKPOINT_SNIPPET_MODAL_TITLE = "Edit Checkpoint Snippet"
export const EDIT_CHECKPOINT_NAME_TOOLTIP_TEXT =
  "A Checkpoint is the primary means for validating data in GX Cloud. Checkpoints provide a bundling of validation of data against an Expectation Suite, as well as the actions that should be taken after the validation."

export const DISABLED_ALERT_BUTTON_TOOLTIP_TEXT =
  "There are no Checkpoints associated with this Data Asset and Expectation Suite."

// Checkpoint Alert Words
export const ALERT_NAME = {
  LABEL: "Alert name",
  PLACEHOLDER: "Name your alert",
}
export const WEBHOOK = {
  LABEL: "Webhook",
  PLACEHOLDER: "https://hooks.slack.com/services/T00000000/B00000000/XXXXXXXXXXX....",
}

const EMAIL_ALERT_DESCRIPTION_END = " fails validation."
export const EMAIL_ALERT_ENABLED_DESCRIPTION = [
  "You will receive an email when any Expectation on ",
  EMAIL_ALERT_DESCRIPTION_END,
]
export const EMAIL_ALERT_DISABLED_DESCRIPTION = [
  "You will no longer receive an email when any Expectation on ",
  EMAIL_ALERT_DESCRIPTION_END,
]

type AlertType = {
  [key: string]: string
}

export const ALERT_TYPE: AlertType = {
  LABEL: "Alert type",
  ALL: "Show all",
  FAILURE: "Failures",
  SUCCESS: "Success",
}

// Form Names
export const CHECKPOINT_FORM = "checkpointForm"

// Field Names
export const CHECKPOINT_INPUT_FIELD = "checkpointInput"
export const CHECKPOINT_DROPDOWN_FIELD = "checkpointsDropdown"
export const CHECKPOINT_ALERT_NAME_FIELD = "alertName"
export const CHECKPOINT_WEBHOOK_FIELD = "webhook"
export const CHECKPOINT_ALERT_TYPE_FIELD = "alertType"

export const SLACK_ACTION = "SlackNotificationAction"
export const INPUT_NAME = "name"
