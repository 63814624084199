import { useState } from "react"
import { EditCheckpointDrawer } from "src/Checkpoints/EditCheckpointDrawer"
import { DeleteCheckpointModal } from "src/Checkpoints/DeleteCheckpointModal"
import { Button } from "src/ui/Button/Button"
import styled from "styled-components"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { RunCheckpointButton } from "src/Checkpoints/RunCheckpointButton"

interface CheckpointActionProps {
  checkpointId: string
}

const StyledButtonContainer = styled.div`
  display: flex;
  gap: 6px;
  justify-content: flex-end;
  flex-direction: row;

  @media screen and (width <= 600px) {
    flex-direction: column;
  }
`

export function CheckpointActions({ checkpointId }: CheckpointActionProps) {
  const [isEditCheckpointDrawerVisible, setIsEditCheckpointDrawerVisible] = useState(false)
  const [isDeleteCheckpointModalVisible, setIsDeleteCheckpointModalVisible] = useState(false)
  const isEditor = useRequireRole("EDITOR")

  return (
    <>
      <StyledButtonContainer>
        <RunCheckpointButton checkpointId={checkpointId} />
        {isEditor && (
          <Button
            type="text"
            title="Edit Checkpoint"
            aria-label="Edit Checkpoint"
            icon="edit"
            onClick={(e) => {
              e.stopPropagation()
              setIsEditCheckpointDrawerVisible(true)
            }}
          />
        )}
        {isEditor && (
          <Button
            type="text"
            title="Delete Checkpoint"
            aria-label="Delete Checkpoint"
            icon="trash"
            onClick={(e) => {
              e.stopPropagation()
              setIsDeleteCheckpointModalVisible(true)
            }}
          />
        )}
      </StyledButtonContainer>
      <EditCheckpointDrawer
        id={checkpointId}
        isVisible={isEditCheckpointDrawerVisible}
        setIsVisible={setIsEditCheckpointDrawerVisible}
      />
      <DeleteCheckpointModal
        isVisible={isDeleteCheckpointModalVisible}
        setIsVisible={setIsDeleteCheckpointModalVisible}
        checkpointId={checkpointId}
      />
    </>
  )
}
