import styled from "styled-components"
import { H2, H3 } from "src/ui/typography/Text"
import { useAnalytics } from "src/analytics/useAnalytics"

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  flex-grow: 1;
`
const StyledH2 = styled(H2)`
  color: ${(props) => props.theme.colors.primaryColors.gxAccentDark};
  margin-top: 16px;
  margin-bottom: 16px;
`

function NotFound() {
  const analytics = useAnalytics()
  analytics?.capture("invalid_url.accessed")

  return (
    <MainContainer>
      <StyledH2>404</StyledH2>
      <H3>Oops, we couldn&apos;t find this page...</H3>
    </MainContainer>
  )
}

export { NotFound }
