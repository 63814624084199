import { Avatar, Card, Flex, Form, Input, Space, Spin } from "antd"
import Meta from "antd/es/card/Meta"
import { Image } from "src/ui/Image"
import {
  ALERT_NAME,
  ALERT_TYPE,
  CHECKPOINT_ALERT_NAME_FIELD,
  CHECKPOINT_ALERT_TYPE_FIELD,
  CHECKPOINT_WEBHOOK_FIELD,
  WEBHOOK,
} from "src/Checkpoints/words"
import { CaptionLink, LabelSemiBold } from "src/ui/typography/Text"
import { NotifyType } from "src/api/graphql/graphql"
import styled, { css, useTheme } from "styled-components"
import { LoadingOutlined } from "@ant-design/icons"
import { RadioGroup } from "src/ui/Radio/RadioGroup.tsx"

const SubtitleWrapper = styled.span`
  ${({ theme }) => css`
    ${theme.typography.semibold.smallLabel}
  `}
`

const alertTypeRadioOptions: { label: string; value: NotifyType }[] = [
  { label: ALERT_TYPE.ALL, value: "ALL" },
  { label: ALERT_TYPE.FAILURE, value: "FAILURE" },
  { label: ALERT_TYPE.SUCCESS, value: "SUCCESS" },
]

export const CheckpointAlertForm = ({
  readOnlyAlertName,
  slackConnectionValidating,
}: {
  readOnlyAlertName?: boolean
  slackConnectionValidating: boolean
}) => {
  return (
    <>
      <Card bodyStyle={{ padding: "16px" }}>
        <Space direction="vertical" size="large">
          <Meta title="Slack" avatar={<Avatar src={<Image type="slack" />} shape="square" size={20} />} />
          <Flex vertical>
            <Form.Item
              name={CHECKPOINT_ALERT_NAME_FIELD}
              label={ALERT_NAME.LABEL}
              rules={[{ required: true }]}
              validateTrigger={["onBlur", "onSubmit"]}
            >
              {readOnlyAlertName ? (
                <LabelSemiBold>{readOnlyAlertName}</LabelSemiBold>
              ) : (
                <Input placeholder={ALERT_NAME.PLACEHOLDER} />
              )}
            </Form.Item>
            <Form.Item
              name={CHECKPOINT_WEBHOOK_FIELD}
              label={WEBHOOK.LABEL}
              rules={[{ required: true }]}
              validateTrigger={["onBlur", "onSubmit"]}
              extra={
                <SubtitleWrapper>
                  To learn more about sending Slack alerts from GX Cloud, see{" "}
                  <CaptionLink to="https://docs.greatexpectations.io/docs/cloud/alerts/manage_alerts">
                    Manage alerts
                  </CaptionLink>
                </SubtitleWrapper>
              }
            >
              <Input placeholder={WEBHOOK.PLACEHOLDER} />
            </Form.Item>
            <Form.Item
              name={CHECKPOINT_ALERT_TYPE_FIELD}
              label="Alert type"
              rules={[{ required: true }]}
              validateTrigger={["onBlur", "onSubmit"]}
              initialValue={alertTypeRadioOptions[0].value}
            >
              <RadioGroup options={alertTypeRadioOptions} buttonStyle="solid" optionType="button" />
            </Form.Item>
          </Flex>
        </Space>
      </Card>
      {slackConnectionValidating && <WebhookValidationStatusFeedback />}
    </>
  )
}

const WebhookValidationStatusFeedback = () => {
  const theme = useTheme()
  return (
    <Space>
      <Spin indicator={<LoadingOutlined style={{ color: theme.colors.primaryColors.gxAccentMedium }} />} />
      <span>Testing connection</span>
    </Space>
  )
}
