import { useEffect, useState } from "react"
import AceEditor from "react-ace"
import jsyaml from "js-yaml"
import "ace-builds/src-noconflict/mode-yaml"
import "ace-builds/src-noconflict/mode-python"
import "ace-builds/src-noconflict/mode-sql"
import "ace-builds/src-noconflict/mode-json"
import "ace-builds/src-noconflict/snippets/yaml"
import "ace-builds/src-noconflict/theme-tomorrow_night_blue"
import { Ace } from "ace-builds"
import styled from "styled-components"
import { Spin } from "antd"

export type AceEditorTheme =
  | "chrome"
  | "clouds"
  | "crimson_editor"
  | "dawn"
  | "dreamweaver"
  | "eclipse"
  | "github"
  | "iplastic"
  | "katzenmilch"
  | "kuroir"
  | "solarized_light"
  | "sqlserver"
  | "textmate"
  | "tomorrow"
  | "xcode"
  | "ambiance"
  | "chaos"
  | "clouds_midnight"
  | "cobalt"
  | "dracula"
  | "gob"
  | "gruvbox"
  | "idle_fingers"
  | "kr_theme"
  | "merbivore"
  | "merbivore_soft"
  | "mono_industrial"
  | "monokai"
  | "pastel_on_dark"
  | "solarized_dark"
  | "terminal"
  | "tomorrow_night"
  | "tomorrow_night_blue"
  | "tomorrow_night_bright"
  | "tomorrow_night_eighties"
  | "twilight"
  | "vibrant_ink"

const Loader = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
`

interface CodeSnippetError {
  mark: {
    line: number
    column: number
  }
  reason: string
}

export interface CodeSnippetEditorProps {
  language: "yaml" | "python" | "json" | "sql"
  readOnly?: boolean
  fontSize?: number
  height?: string
  width?: string
  showLineNumbers?: boolean
  loading?: boolean
  value: string
  onChange?: (val: string) => void
  showGutter?: boolean
}

function CodeSnippetEditor({
  language: mode,
  readOnly = true,
  fontSize = 20,
  height,
  width,
  showLineNumbers = true,
  loading = false,
  value = "",
  onChange,
  showGutter = true,
}: CodeSnippetEditorProps) {
  const [annotations, setAnnotations] = useState<Ace.Annotation[]>([])
  const [error, setError] = useState<CodeSnippetError | null>(null)

  useEffect(() => {
    if (error && mode === "yaml") {
      setAnnotations([
        {
          row: error.mark.line,
          column: error.mark.column,
          text: error.reason,
          type: "error",
        },
      ])
    }
    return () => setAnnotations([])
  }, [error, mode])

  const getCodeValidationErrors = (code: string) => {
    try {
      jsyaml.load(code)
      setError(null)
    } catch (e: unknown) {
      const newError = e as CodeSnippetError
      const isDifferentError =
        annotations.length > 0 &&
        annotations[0].column !== newError.mark.column &&
        annotations[0].row !== newError.mark.line &&
        annotations[0].text !== newError.reason
      if (!error || isDifferentError) setError(newError)
    }
  }

  if (loading)
    return (
      <Loader aria-label="Loading Spinner">
        <Spin />
      </Loader>
    )

  const defaultOnChangeAction = (val: string) => {
    getCodeValidationErrors(val)
    onChange?.(val)
  }

  const onChangeAction = onChange || defaultOnChangeAction

  return (
    <AceEditor
      style={{ width: "100%", borderRadius: "4px" }}
      mode={mode}
      theme="tomorrow_night_blue"
      readOnly={readOnly}
      name="template_file"
      value={value}
      fontSize={fontSize}
      height={height}
      width={width}
      annotations={annotations}
      onLoad={(editor) => {
        getCodeValidationErrors(value)
        editor.renderer.setPadding(15)
        const textInput = editor.renderer.container.getElementsByClassName("ace_text-input")[0]
        textInput.setAttribute("aria-multiline", "true")
        textInput.setAttribute("aria-label", "Code Snippet")
        textInput.setAttribute("aria-readonly", "true")
        textInput.setAttribute("role", "textbox")
        const cursor = editor.renderer.container.getElementsByClassName(
          "ace_layer ace_cursor-layer ace_hidden-cursors",
        )[0] as HTMLElement
        if (readOnly) cursor.style.display = "none"
      }}
      setOptions={{
        showLineNumbers: showLineNumbers,
        showGutter,
        tabSize: 2,
        autoScrollEditorIntoView: true,
        showPrintMargin: false,
        highlightActiveLine: false,
        minLines: 50,
      }}
      onChange={(val) => onChangeAction(val)}
      wrapEnabled={true}
    />
  )
}

export { CodeSnippetEditor }
