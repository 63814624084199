import { Flex, Tree, Input, Space, Tooltip } from "antd"
import { TreeDataNode } from "antd/lib"
import { uniq, without, xor } from "lodash-es"
import { useEffect, useMemo, useState } from "react"
import { LabelRegular } from "src/ui/typography/Text"

const { Search } = Input
type Props = {
  tableNames: Array<string>
  setSelectedTableNames: (selectedTableNames: string[]) => void
  matchingTableNames?: Array<string>
}

//random key for tree component Select all  checkbox - had to be unique from all other checkboxes
const selectAllKey = "0c32ca97-4adc-40b9-9954-fed025175040"
export const disabledSelectAllText = "All tables have been added"

export function SelectTableNames({ tableNames, setSelectedTableNames, matchingTableNames = [] }: Props) {
  const [searchValue, setSearchValue] = useState("")
  const [treeState, setTreeState] = useState<{ checked: string[]; halfChecked: string[]; disabled: string[] }>({
    checked: [...matchingTableNames],
    halfChecked: [],
    disabled: [...matchingTableNames],
  })

  useEffect(() => {
    setSelectedTableNames(without(treeState.checked, selectAllKey))
  }, [treeState, setSelectedTableNames])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    setSearchValue(value)
  }

  const treeData = useMemo(() => {
    return buildTreeData(tableNames, searchValue, matchingTableNames)
  }, [matchingTableNames, searchValue, tableNames])

  useEffect(() => {
    const displayedKeys = treeData[0]?.children?.map((node) => String(node.key)) ?? []
    const checkedKeys = treeState.checked
    const isTopItemChecked = checkedKeys.indexOf(selectAllKey) >= 0
    const isTopItemHalfChecked = treeState.halfChecked.indexOf(selectAllKey) >= 0
    const isTopItemUnchecked = !isTopItemChecked && !isTopItemHalfChecked
    const allChildrenChecked = displayedKeys.every((displayedKey) => checkedKeys.indexOf(displayedKey) >= 0)
    const someChildrenChecked =
      !allChildrenChecked && displayedKeys.some((displayedKey) => checkedKeys.indexOf(displayedKey) >= 0)
    const noChildrenChecked = !allChildrenChecked && !someChildrenChecked
    const allChildrenDisabled = displayedKeys.every((displayedKey) => treeState.disabled.indexOf(displayedKey) >= 0)

    if (isTopItemChecked && !allChildrenChecked) {
      return setTreeState({
        ...treeState,
        checked: without(treeState.checked, selectAllKey),
        halfChecked: [selectAllKey],
      })
    }
    if ((isTopItemHalfChecked || isTopItemUnchecked) && allChildrenChecked) {
      return allChildrenDisabled
        ? setTreeState({
            checked: [...treeState.checked, selectAllKey],
            disabled: [...treeState.disabled, selectAllKey],
            halfChecked: [],
          })
        : setTreeState({ ...treeState, checked: [...treeState.checked, selectAllKey], halfChecked: [] })
    }
    if (isTopItemUnchecked && someChildrenChecked) {
      return setTreeState({ ...treeState, checked: treeState.checked, halfChecked: [selectAllKey] })
    }
    if ((isTopItemHalfChecked || isTopItemChecked) && noChildrenChecked) {
      return setTreeState({ ...treeState, checked: without(treeState.checked, selectAllKey), halfChecked: [] })
    }
  }, [treeData, treeState, treeState.checked, treeState.halfChecked])

  return (
    <Flex vertical>
      <Space direction="vertical">
        <LabelRegular>Select tables to import as Data Assets</LabelRegular>
        <Search style={{ marginBottom: 8 }} placeholder="Search" onChange={onChange} />
      </Space>
      <Tree
        checkable
        checkStrictly
        defaultExpandAll
        expandedKeys={[selectAllKey, ...tableNames]}
        switcherIcon={null}
        treeData={treeData}
        blockNode
        selectable={false}
        checkedKeys={treeState}
        onCheck={(keys, info) => {
          const allChildKeys = treeData[0]?.children?.map((node) => String(node.key)) ?? []
          const currentCheckedKeys =
            "checked" in keys ? keys.checked.map((key) => String(key)) : keys.map((key) => String(key))

          if (info.checked) {
            if (info.node.key === selectAllKey) {
              return setTreeState({
                ...treeState,
                halfChecked: [],
                checked: uniq([...treeState.checked, selectAllKey, ...allChildKeys]),
              })
            }
            if (allChildKeys.length === currentCheckedKeys.filter((k) => k !== selectAllKey).length) {
              return setTreeState({
                ...treeState,
                halfChecked: [],
                checked: uniq([...treeState.checked, ...currentCheckedKeys, selectAllKey]),
              })
            }
            return setTreeState({
              ...treeState,
              halfChecked: [selectAllKey],
              checked: uniq([...treeState.checked, ...currentCheckedKeys]),
            })
          }

          // if the node is unchecked
          if (info.node.key === selectAllKey) {
            return setTreeState({
              ...treeState,
              halfChecked: [],
              checked: without(treeState.checked, selectAllKey, ...without(allChildKeys, ...treeState.disabled)),
            })
          }

          return setTreeState({
            ...treeState,
            halfChecked: currentCheckedKeys.length === 0 ? [] : [selectAllKey],
            checked: without(treeState.checked, String(info.node.key), selectAllKey),
          })
        }}
      />
    </Flex>
  )
}

function buildTreeData(tableNames: string[], searchValue: string, matchingTableNames: string[]): TreeDataNode[] {
  const children = tableNames
    .filter((name) => name.toLowerCase().includes(searchValue.toLowerCase()))
    .map((name) => {
      const isDisabled = matchingTableNames.includes(name)

      return {
        title: isDisabled ? (
          <Tooltip placement="bottom" title="Asset already exists for this table">
            {name}
          </Tooltip>
        ) : (
          name
        ),
        key: name,
        disabled: isDisabled,
      }
    })

  // xor finds things not in the intersection of the two arrays, so if xor produces an array of any length,
  // it means the arrays are not equal
  const isSelectAllKeyDisabled = xor(matchingTableNames, tableNames).length === 0
  const title = children.length === 1 ? "Select 1 matching table" : `Select all ${children.length} matching tables`

  return [
    {
      title: isSelectAllKeyDisabled ? disabledSelectAllText : title,
      key: selectAllKey,
      disabled: isSelectAllKeyDisabled,
      children,
    },
  ]
}
