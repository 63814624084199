import { Form, FormInstance, Input } from "antd"

interface TokenFormProps {
  form: FormInstance
}

export const TokenForm = ({ form }: TokenFormProps) => {
  return (
    <Form
      form={form}
      name="tokenForm"
      layout="horizontal"
      validateTrigger={["onBlur"]}
      preserve={false}
      title="Create access token form"
    >
      <Form.Item label="Token name" name="tokenName" rules={[{ required: true }]}>
        <Input placeholder="Name as it will appear (this is not the token itself)" />
      </Form.Item>
    </Form>
  )
}
