import { Alert, List, Space, message } from "antd"
import { useMutation } from "@apollo/client"

import { AppModal } from "src/ui/AppModal/AppModal"
import { Icon } from "src/ui/Icon"
import { BodyRegular, SubheaderSemiBold, SubtitleSemiBold } from "src/ui/typography/Text"
import { DatasourcesDocument, DatasourcesQuery, DeleteDatasourceDocument } from "src/api/graphql/graphql-operations"
import { useCheckpointsForDatasource } from "src/DataAssets/useCheckpointsForDatasource"
import { theme } from "src/ui/themes/theme"
import { DatasourceV2 } from "src/api/graphql/graphql"
import { MESSAGE_DURATION_SECONDS } from "src/common/config"

export const CannotDeleteDatasourceWarning = "Unable to delete Data Source"

interface DeleteDatasourceModalProps {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
  datasource: Pick<DatasourceV2, "name" | "id">
}

export const DeleteDatasourceModal = ({ isVisible, setIsVisible, datasource }: DeleteDatasourceModalProps) => {
  const [deleteDatasourceMutation, { loading: mutationInProgress, error, reset: resetMutation }] = useMutation(
    DeleteDatasourceDocument,
    {},
  )

  const {
    checkpoints,
    loading: findingCheckpoints,
    empty: noCheckpointsFound,
  } = useCheckpointsForDatasource(datasource.id, !isVisible)

  const loading = mutationInProgress || findingCheckpoints

  const deleteDatasource = () => {
    deleteDatasourceMutation({
      variables: { id: datasource.id },
      onCompleted: () => {
        message.success(`Successfully deleted ${datasource.name} Data Source`, MESSAGE_DURATION_SECONDS)
        setIsVisible(false)
      },
      onError: () => {},
      update(cache) {
        cache.updateQuery(
          {
            query: DatasourcesDocument,
          },
          (data: DatasourcesQuery | null) =>
            data?.datasourcesV2 !== null && data?.datasourcesV2 !== undefined
              ? {
                  ...data,
                  datasources: data.datasourcesV2.filter((d) => d.id !== datasource.id),
                }
              : null,
        )
        cache.evict({ id: `DatasourceV2:${datasource.id}` })
      },
    })
  }
  const onReset = () => {
    setIsVisible(false)
    error && resetMutation()
  }
  return (
    <AppModal
      open={isVisible}
      title={
        <Space>
          <Icon size={theme.spacing.scale.s} name="exclamationCircle" color={theme.colors.warning.colorWarning} />
          <SubtitleSemiBold>Confirm Deletion</SubtitleSemiBold>
        </Space>
      }
      width={550}
      okText="Delete"
      cancelText="Cancel"
      onCancel={onReset}
      okButtonProps={{
        danger: true,
        loading: loading,
        disabled: !noCheckpointsFound || loading,
      }}
      onOk={() => {
        deleteDatasource()
      }}
    >
      <Space direction="vertical">
        {error && <Alert message="Error deleting Data Source" type="error" description={error.message} />}
        {noCheckpointsFound ? null : (
          <Alert
            message={<SubheaderSemiBold>{CannotDeleteDatasourceWarning}</SubheaderSemiBold>}
            type="error"
            showIcon
            description={
              <Space direction="vertical">
                This Data Source cannot be deleted because it has Data Assets associated with the following Checkpoints:
                <List dataSource={checkpoints} renderItem={({ id, name }) => <List.Item key={id}>{name}</List.Item>} />
              </Space>
            }
          />
        )}
        <BodyRegular>
          Delete Data Source <b>{datasource.name}</b>?
        </BodyRegular>
      </Space>
    </AppModal>
  )
}
