import { ApolloError, useMutation, useQuery } from "@apollo/client"
import { message, DrawerProps, Form, Input, Flex, Skeleton } from "antd"
import { PropsWithChildren, useEffect, useState } from "react"
import {
  ALERT_CREATE_SUCCESS_MESSAGE,
  CHECKPOINT_ALERT_NAME_FIELD,
  CHECKPOINT_ALERT_TYPE_FIELD,
  CHECKPOINT_DROPDOWN_FIELD,
  CHECKPOINT_FORM,
  CHECKPOINT_INPUT_FIELD,
  CHECKPOINT_WEBHOOK_FIELD,
  EDIT_CHECKPOINT_DRAWER_TITLE,
  EDIT_CHECKPOINT_ERROR_TEXT,
  EDIT_CHECKPOINT_NAME_TOOLTIP_TEXT,
  EDIT_CHECKPOINT_SUCCESS_MESSAGE,
  ALERT_UPDATE_SUCCESS_MESSAGE,
  ERROR_TITLE_CHECKPOINT_EDIT,
  FAILED_TO_SAVE_ALERT,
} from "src/Checkpoints/words"
import { Drawer } from "src/ui/Drawer/Drawer"
import { EditCheckpointSnippet } from "src/Checkpoints/EditCheckpointSnippet"

import {
  CreateSlackNotificationDocument,
  EditCheckpointSnippetDocument,
  UpdateCheckpointDocument,
} from "src/api/graphql/graphql-operations"
import { getDomainErrors } from "src/errors/parseErrors"
import { QUICK_MESSAGE_DURATION } from "src/common/config"
import { StyledTooltipIcon } from "src/ui/Tooltip/Tooltip"
import { AlertBanner } from "src/ui/Alert/AlertBanner"
import { CheckpointNameSelect } from "src/Checkpoints/CheckpointNameSelect"
import { CheckpointAlertForm } from "src/Checkpoints/CheckpointAlertForm"
import { NotifyType } from "src/api/graphql/graphql"
import { SlackActionsList } from "src/Checkpoints/SlackActionsList"
import { useQueryCheckpoints } from "src/Checkpoints/useQueryCheckpoints"
import { getSlackActionsForSelectedCheckpoint } from "src/Checkpoints/utils"
import { graphql } from "src/api/graphql"
import { isEmpty } from "vega-lite"
import { EmailAlertCard } from "src/Checkpoints/EmailAlertCard"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"

export type AssetFlowIds = {
  assetId: string
  expectationSuiteId: string
}

export interface SlackAction {
  name: string
  action: {
    class_name: "SlackNotificationAction"
    notify_on: NotifyType
    slack_webhook: string
  }
}

interface EditCheckpointDrawerProps extends DrawerProps {
  id?: string
  assetFlowIds?: AssetFlowIds
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
  setIsDrawerButtonDisabled?: (isVisible: boolean) => void
}

export interface Error {
  title: string
  description: string
}

type SlackActionStatus = "edit" | "create" | "readOnly"

export const EditSlackActionDocument = graphql(`
  mutation EditSlackNotificationAction($input: EditSlackActionInput!) {
    editSlackNotification(input: $input) {
      checkpoint {
        id
        actionList
      }
    }
  }
`)

export const CheckSlackConnectionDocument = graphql(`
  mutation CheckSlackConnection($checkpointId: UUID!, $webhookUrl: String!) {
    checkSlackConnection(checkpointId: $checkpointId, webhookUrl: $webhookUrl) {
      status
    }
  }
`)

export function EditCheckpointDrawer({
  id = "",
  assetFlowIds,
  isVisible,
  setIsVisible,
  setIsDrawerButtonDisabled,
}: EditCheckpointDrawerProps) {
  const formID = `edit-checkpoint-form-${id}`
  const [form] = Form.useForm()
  const checkpointNameValue = Form.useWatch(CHECKPOINT_INPUT_FIELD, form)
  const checkpointSelectedValue = Form.useWatch(CHECKPOINT_DROPDOWN_FIELD, form)
  const isEmailAlertEnabled = useIsFeatureEnabled("emailAlertEnabled")

  const [error, setError] = useState<Error | null>(null)
  const [slackActionStatus, setSlackActionStatus] = useState<SlackActionStatus>("readOnly")

  const [checkpoints, checkpointQueriesLoading, refetch] = useQueryCheckpoints({
    id,
    assetFlowIds,
    isVisible,
    onError: handleErrorForCheckpoint,
  })
  const singleCheckpoint = checkpoints?.length === 1 ? checkpoints[0] : undefined
  const slackActions = checkpoints
    ? getSlackActionsForSelectedCheckpoint(singleCheckpoint?.id || checkpointSelectedValue, checkpoints)
    : []

  const initialName = singleCheckpoint?.name
  const checkpointDropdownOptions = checkpoints?.map((checkpoint) => ({
    label: checkpoint?.name,
    value: checkpoint?.id,
  }))
  const checkpointId = id || checkpointSelectedValue || checkpoints?.[0]?.id

  useEffect(() => {
    if (checkpointDropdownOptions !== undefined && checkpointDropdownOptions.length === 0) {
      setIsDrawerButtonDisabled?.(true)
    }
  }, [checkpointDropdownOptions, setIsDrawerButtonDisabled])

  const isCheckpointNameEditable = id && initialName

  const handleErrorForAlert = (e: ApolloError) => {
    setError({
      title: FAILED_TO_SAVE_ALERT,
      description: e.message || "An error occurred",
    })
  }

  function handleErrorForCheckpoint(e: ApolloError) {
    const domainErrors = getDomainErrors(e)
    domainErrors.forEach((err) => {
      if (err.code === "taken") {
        setError({
          title: ERROR_TITLE_CHECKPOINT_EDIT,
          description: EDIT_CHECKPOINT_ERROR_TEXT.DUPLICATE_NAME,
        })
      } else {
        setError({
          title: ERROR_TITLE_CHECKPOINT_EDIT,
          description: EDIT_CHECKPOINT_ERROR_TEXT.UNEXPECTED_ERROR,
        })
      }
    })
    if (e && isEmpty(domainErrors)) {
      setError({
        title: ERROR_TITLE_CHECKPOINT_EDIT,
        description: e.message,
      })
    }
  }

  const saveOrUpdateSlackAction = () => {
    const formValues = form.getFieldsValue([
      CHECKPOINT_WEBHOOK_FIELD,
      CHECKPOINT_ALERT_NAME_FIELD,
      CHECKPOINT_ALERT_TYPE_FIELD,
    ])

    switch (slackActionStatus) {
      case "create":
        createSlackNotification({
          variables: {
            input: {
              checkpointId,
              name: formValues[CHECKPOINT_ALERT_NAME_FIELD],
              webhook: formValues[CHECKPOINT_WEBHOOK_FIELD],
              notifyType: formValues[CHECKPOINT_ALERT_TYPE_FIELD],
            },
          },
        })
        break
      case "edit":
        editSlackAction({
          variables: {
            input: {
              checkpointId,
              name: formValues[CHECKPOINT_ALERT_NAME_FIELD],
              webhook: formValues[CHECKPOINT_WEBHOOK_FIELD],
              notifyType: formValues[CHECKPOINT_ALERT_TYPE_FIELD],
            },
          },
        })
        break
      default:
        break
    }
  }

  const handleSlackActionOperations = () => {
    const formValues = form.getFieldsValue([CHECKPOINT_WEBHOOK_FIELD])
    if (form.isFieldTouched(CHECKPOINT_WEBHOOK_FIELD)) {
      checkSlackConnection({
        variables: {
          checkpointId,
          webhookUrl: formValues[CHECKPOINT_WEBHOOK_FIELD],
        },
        onCompleted: (data) => {
          if (data.checkSlackConnection?.status) {
            saveOrUpdateSlackAction()
          } else {
            setError({
              title: FAILED_TO_SAVE_ALERT,
              description: EDIT_CHECKPOINT_ERROR_TEXT.CONNECTION_TEST_FAILURE,
            })
          }
        },
        onError: handleErrorForAlert,
      })
    } else {
      saveOrUpdateSlackAction()
    }
  }

  const [updateCheckpointName, { loading: updateCheckpointLoading }] = useMutation(UpdateCheckpointDocument, {
    variables: {
      input: {
        id: id,
        name: checkpointNameValue,
      },
    },
    onCompleted: () => {
      if (form.getFieldValue(CHECKPOINT_ALERT_NAME_FIELD)) {
        return handleSlackActionOperations()
      }
      message.success(EDIT_CHECKPOINT_SUCCESS_MESSAGE, QUICK_MESSAGE_DURATION)
      refetch()
    },
    onError: handleErrorForCheckpoint,
  })

  const [createSlackNotification, { loading: createSlackNotificationLoading }] = useMutation(
    CreateSlackNotificationDocument,
    {
      onCompleted: () => {
        message.success(ALERT_CREATE_SUCCESS_MESSAGE, QUICK_MESSAGE_DURATION)
        resetCardFieldsOnly()
        setSlackActionStatus("readOnly")
        refetch()
      },
      onError: handleErrorForAlert,
    },
  )

  const resetCardFieldsOnly = () => {
    form.resetFields([CHECKPOINT_ALERT_NAME_FIELD, CHECKPOINT_WEBHOOK_FIELD, CHECKPOINT_ALERT_TYPE_FIELD])
  }

  const handleReset = () => {
    error && setError(null)
    form.resetFields()
    setSlackActionStatus("readOnly")
  }

  const { data: snippetData, loading: snippetLoading } = useQuery(EditCheckpointSnippetDocument, {
    variables: {
      id: id,
    },
    skip: !isVisible || !id,
  })

  const [editSlackAction, { loading: editSlackActionLoading }] = useMutation(EditSlackActionDocument, {
    onCompleted: () => {
      message.success(ALERT_UPDATE_SUCCESS_MESSAGE, QUICK_MESSAGE_DURATION)
      resetCardFieldsOnly()
      setSlackActionStatus("readOnly")
      refetch()
    },
    onError: handleErrorForAlert,
  })

  const [checkSlackConnection, { loading: slackConnectionValidating }] = useMutation(CheckSlackConnectionDocument)

  const loading: boolean =
    checkpointQueriesLoading || updateCheckpointLoading || createSlackNotificationLoading || editSlackActionLoading

  const checkpointSnippet = snippetData?.snippets?.editCheckpoint ?? ""

  const footer = (
    <Drawer.Footer gap="8px">
      {isCheckpointNameEditable && (
        <EditCheckpointSnippet checkpointSnippet={checkpointSnippet} loading={snippetLoading} />
      )}
      {Boolean(slackActionStatus !== "readOnly") && (
        <Drawer.FooterButton
          onClick={() => {
            error && setError(null)
            resetCardFieldsOnly()
            setSlackActionStatus("readOnly")
          }}
        >
          Back
        </Drawer.FooterButton>
      )}
      <Drawer.FooterButton type="primary" htmlType="submit" disabled={loading} loading={loading} form={formID}>
        {/* Only show "Done" in ReadOnly when opening drawer from headers. */}
        {slackActionStatus === "readOnly" && !id ? "Done" : "Save"}
      </Drawer.FooterButton>
    </Drawer.Footer>
  )

  const handleSave = () => {
    error && setError(null)
    const values = form.getFieldsValue()

    if (values[CHECKPOINT_INPUT_FIELD] !== initialName && id) {
      return updateCheckpointName({
        variables: {
          input: {
            id: id,
            name: values[CHECKPOINT_INPUT_FIELD],
          },
        },
      })
    }
    if (form.getFieldValue(CHECKPOINT_ALERT_NAME_FIELD)) {
      return handleSlackActionOperations()
    }
    setIsVisible(false)
    handleReset()
  }

  return (
    <Drawer
      title={isCheckpointNameEditable ? EDIT_CHECKPOINT_DRAWER_TITLE : "Alerts"}
      onClose={() => {
        setIsVisible(false)
        handleReset()
      }}
      open={isVisible}
      onClick={(e) => e.stopPropagation()}
      footer={footer}
    >
      <Flex vertical justify="space-between" style={{ paddingBottom: "50px" }}>
        <ShowLoadingState loading={loading}>
          <Form
            id={formID}
            form={form}
            name={CHECKPOINT_FORM}
            layout="horizontal"
            onValuesChange={() => setError(null)}
            onFinish={handleSave}
          >
            <Flex gap="large" vertical>
              {isEmailAlertEnabled && assetFlowIds?.assetId && (
                <EmailAlertCard assetId={assetFlowIds?.assetId} onEmailAlertError={setError} />
              )}
              {initialName && id && (
                <Form.Item
                  label="Name"
                  name={CHECKPOINT_INPUT_FIELD}
                  initialValue={initialName}
                  rules={[{ required: true, message: "Name is required" }]}
                  tooltip={{
                    icon: <StyledTooltipIcon name="questionCircle" width="18px" />,
                    title: EDIT_CHECKPOINT_NAME_TOOLTIP_TEXT,
                  }}
                >
                  <Input />
                </Form.Item>
              )}
              {!id && checkpoints?.length && checkpoints.length > 1 && (
                <CheckpointNameSelect
                  options={checkpointDropdownOptions}
                  initialValue={checkpoints[0].id}
                  onChange={() => {
                    resetCardFieldsOnly()
                    setSlackActionStatus("readOnly")
                  }}
                  onError={handleErrorForCheckpoint}
                />
              )}
              <>
                {slackActionStatus !== "readOnly" ? (
                  <CheckpointAlertForm
                    readOnlyAlertName={slackActionStatus === "edit" && form.getFieldValue(CHECKPOINT_ALERT_NAME_FIELD)}
                    slackConnectionValidating={slackConnectionValidating}
                  />
                ) : (
                  <SlackActionsList
                    checkpointId={checkpointId}
                    refetch={refetch}
                    slackActions={slackActions}
                    onEdit={(slackAction: SlackAction) => {
                      form.setFieldsValue({
                        [CHECKPOINT_ALERT_NAME_FIELD]: slackAction.name,
                        [CHECKPOINT_WEBHOOK_FIELD]: slackAction.action.slack_webhook,
                        [CHECKPOINT_ALERT_TYPE_FIELD]: slackAction.action.notify_on.toUpperCase(),
                      })
                      setSlackActionStatus("edit")
                    }}
                    onAdd={() => {
                      resetCardFieldsOnly()
                      setSlackActionStatus("create")
                    }}
                  />
                )}
              </>
            </Flex>
          </Form>
          {error && <AlertBanner message={error.title} description={error.description} />}
        </ShowLoadingState>
      </Flex>
    </Drawer>
  )
}

const ShowLoadingState = ({ loading, children }: PropsWithChildren<{ loading: boolean }>) => {
  if (!loading) return <>{children}</>
  return (
    <div aria-label="Loading animation">
      <Skeleton active paragraph={{ rows: 4 }} title={false} />
    </div>
  )
}
