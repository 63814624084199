import { Card, Menu } from "antd"
import styled from "styled-components"
import { DownOutlined } from "@ant-design/icons"

export const FilterLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .icon {
    width: 24px;
    height: 100%;

    svg {
      vertical-align: middle;
    }
  }
`

export const CardContainer = styled(Card)`
  ${({ theme }) =>
    `border: 1px solid ${theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder};
    border-radius: ${theme.spacing.cornerRadius.medium};`};
  overflow-x: hidden;

  .ant-card-body {
    padding: 0;

    > ul {
      border: none;
      border-radius: 8px;
    }
  }
`

export const CollapsableFilterMenu = styled(Menu).attrs({ expandIcon: <DownOutlined /> })`
  &.ant-menu-inline .ant-menu-submenu-title {
    padding-right: ${({ theme }) => theme.spacing.horizontal.xxxs};
  }

  .ant-menu-sub {
    background: transparent;
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
  }

  div.ant-menu-submenu-title {
    padding-right: 15px;
    margin: 0;
    width: 100%;
    ${({ theme }) => `background-color: ${theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgSecondary};`}

    &:hover,
    &:focus, 
    &:active {
      ${({ theme }) =>
        `background-color: ${theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgSecondary} !important;`}
    }
  }

  .ant-menu-submenu {
    &:first-child {
      .ant-menu-submenu-title {
        border-radius: ${({ theme }) => theme.spacing.cornerRadius.medium}
          ${({ theme }) => theme.spacing.cornerRadius.medium} 0 0;
      }
    }

    &:not(:first-child) {
      .ant-menu-submenu-title {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &:not(:last-child) {
      .ant-menu-submenu-title {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:last-child.ant-menu-submenu-open {
      .ant-menu-submenu-title {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .ant-menu-submenu:last-child:not(.ant-menu-submenu-open) {
    .ant-menu-submenu-title {
      border-bottom-left-radius: ${({ theme }) => theme.spacing.cornerRadius.medium};
      border-bottom-right-radius: ${({ theme }) => theme.spacing.cornerRadius.medium};
    }
  }

  .ant-menu-title-content {
    margin-left: ${({ theme }) => theme.spacing.horizontal.s};

    label {
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .ant-menu-submenu-expand-icon {
    left: 10px;

    svg {
      transform: rotate(-90deg);
      transition: transform 0.24s;
    }
  }

  .ant-menu-submenu-open {
    .ant-menu-submenu-expand-icon {
      svg {
        transform: rotate(0deg);
        transition: transform 0.24s;
      }
    }
  }

  &&& .ant-menu-item {
    padding: 8px 3px 8px 24px;
    margin: 0;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.neutralColorPalette.whites.white};

    .icon {
      opacity: 0;
      transition: opacity 0.3s;
    }

    &:hover {
      border-radius: 0;

      ${({ theme }) =>
        `background-color: ${theme.colors.primaryColors.gxAccentBackground}; 
        color: ${theme.colors.neutralColorPalette.blacks.colorText};
    `}
    }

    &:active {
      ${({ theme }) =>
        `background-color: ${theme.colors.primaryColors.gxAccentBackground}; 
        color: ${theme.colors.primaryColors.gxPrimaryDark};`}
    }
  }

  .ant-menu-inline .ant-menu-item {
    margin-bottom: 0;
  }

  && .ant-menu-submenu-open .ant-menu-item-selected {
    ${({ theme }) =>
      `background-color: ${theme.colors.primaryColors.gxAccentBackground}; 
    color: ${theme.colors.primaryColors.gxPrimaryDark};`}
    border-right: none;
    font-weight: 600;
    padding-left: 10px;

    ::after {
      display: none;
    }

    .icon {
      opacity: 1;
      transition: opacity 0.3s;
      ${({ theme }) => `color: ${theme.colors.primaryColors.gxAccentMedium};`}
    }
  }
`
