import { Drawer } from "src/ui/Drawer/Drawer"
import { Button } from "src/ui/Button/Button.tsx"
import styled from "styled-components"

const StyledFooterContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.vertical.xxs};
  margin: ${({ theme }) => theme.spacing.vertical.s} 0;
`

interface AssetDrawerFooterProps {
  onBack: () => void
  onSave: () => void
  onSaveAndAdd: () => void
  saveLoading: boolean
  saveDisabled?: boolean
}

export function SelectExpectationFooter() {
  // TODO: Implement/show the custom SQL Expectation form with https://greatexpectations.atlassian.net/browse/PH-1467
  return (
    <StyledFooterContentContainer>
      <div>Want to define your own test with SQL?</div>
      <Button icon="plus">Create custom Expectation</Button>
    </StyledFooterContentContainer>
  )
}

export function SaveExpectationFooter({
  onBack,
  onSave,
  onSaveAndAdd,
  saveLoading,
  saveDisabled = false,
}: AssetDrawerFooterProps) {
  return (
    <Drawer.Footer>
      <Drawer.FooterButton type="default" onClick={() => onBack()} id="create-expectation-for-asset-back-button">
        Back
      </Drawer.FooterButton>
      <Drawer.FooterButton
        type="default"
        onClick={() => onSave()}
        loading={saveLoading}
        disabled={saveDisabled}
        id="create-expectation-for-asset-save-button"
      >
        Save
      </Drawer.FooterButton>
      <Drawer.FooterButton
        type="primary"
        onClick={() => onSaveAndAdd()}
        loading={saveLoading}
        disabled={saveDisabled}
        id="create-expectation-for-asset-save-and-add-more-button"
      >
        Save & add more
      </Drawer.FooterButton>
    </Drawer.Footer>
  )
}
