import { useState } from "react"

import { Button } from "src/ui/Button/Button"
import { AppModal, StyledCopyButton } from "src/ui/AppModal/AppModal"
import { CodeSnippetEditor } from "src/ui/CodeSnippetEditor/CodeSnippetEditor"
import { CodeSnippetAlert } from "src/ui/CodeSnippetEditor/CodeSnippetAlert"
import { copyToClipboard } from "src/common/utils/clipboard"
import { useQuery } from "@apollo/client"
import { SnippetsDocument } from "src/api/graphql/graphql-operations"

interface ModalContentProps {
  expectationSnippet: string
  loading: boolean
}

const ModalContent = ({ expectationSnippet, loading }: ModalContentProps) => (
  <>
    <CodeSnippetAlert />
    <p>Edit configuration and run the following code snippet:</p>
    <CodeSnippetEditor
      value={!expectationSnippet ? "Expectation code snippet not found" : expectationSnippet}
      language="python"
      readOnly
      fontSize={12}
      showLineNumbers={false}
      loading={loading}
      height="230px"
    />
    <StyledCopyButton
      icon="clipboard"
      disabled={!expectationSnippet || loading}
      onClick={() => {
        if (expectationSnippet) {
          copyToClipboard(expectationSnippet)
        }
      }}
    >
      Copy snippet
    </StyledCopyButton>
  </>
)

export const NewSuiteAction = () => {
  const [isVisible, setIsVisible] = useState(false)
  const { data, loading } = useQuery(SnippetsDocument, {
    errorPolicy: "all",
  })
  const expectationSnippet = data?.snippets?.createExpectationSuite ?? ""

  return (
    <>
      <Button type="primary" icon="plus" onClick={() => setIsVisible(!isVisible)}>
        New Suite
      </Button>
      <AppModal
        open={isVisible}
        onCancel={() => setIsVisible(false)}
        title="Create new Expectation Suite"
        width={750}
        cancelText="Done"
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <ModalContent expectationSnippet={expectationSnippet} loading={loading} />
      </AppModal>
    </>
  )
}
