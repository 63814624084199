import { Alert as AlertAntD } from "antd"
import styled, { css } from "styled-components"

type AlertPositionTypes = "bottom" | "top"

export interface AlertTypes {
  status: "success" | "error"
  message: string | JSX.Element
  position?: AlertPositionTypes
}

const AlertWrapper = styled.div<{ $position?: AlertPositionTypes }>`
  position: fixed;
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.horizontal.s};
    bottom: ${theme.spacing.horizontal.s};
  `}
  width: 100%;
  right: 0;
  left: 0;

  ${({ theme, $position }) =>
    $position === "bottom" &&
    `
    top: initial;
    bottom: ${theme.spacing.horizontal.s};
  `}
`

interface AlertProps {
  alertInfo?: AlertTypes
  setAlert: (alertInfo: AlertTypes | null) => void
}

function Alert({ alertInfo, setAlert }: AlertProps) {
  return (
    <AlertWrapper $position={alertInfo?.position}>
      {alertInfo ? (
        <AlertAntD
          message={alertInfo.message}
          type={alertInfo.status}
          closable
          afterClose={() => setAlert(null)}
          showIcon
          style={{ borderWidth: 0, padding: "12px" }}
        />
      ) : null}
    </AlertWrapper>
  )
}

export { Alert }
