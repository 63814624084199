import { useUrlParams } from "src/common/utils/url-params"
import { orderBy } from "lodash-es"
import { UseListItemsResult } from "src/GXAgent/useJobListItems"
import { ExpectationSuitesDocument } from "src/api/graphql/graphql-operations"
import { useQuery } from "@apollo/client"
import {
  DefaultExpectationSortOption,
  ExpectationSortParams,
  valueIsValidExpectationSortOption,
} from "src/expectationSuites/ExpectationSuiteList/ExpectationSuiteFilter"

export interface ExpectationSuiteListItemProps {
  id: string
  title: string
  expectationCount: number
  checkpointCount: number
  checkpointsURL: string
}

// useExpectationSuiteListItems performs a gql query and prepares the response data
// to be consumed by the list renderer
function useExpectationSuiteListItems(): UseListItemsResult<ExpectationSuiteListItemProps> {
  const { data, loading, error } = useQuery(ExpectationSuitesDocument)
  const [{ search, order: orderUrlParam }] = useUrlParams<ExpectationSortParams>({
    search: "",
    order: DefaultExpectationSortOption,
  })

  const order = valueIsValidExpectationSortOption(orderUrlParam) ? orderUrlParam : DefaultExpectationSortOption

  let suites = data?.expectationSuitesV2 ?? []

  // before filters are applied, check to see if any exist
  const noData = !loading && suites.length === 0

  if (search) {
    suites = suites.filter((s) => s.name.toLowerCase().includes(search.toLowerCase()))
  }
  switch (order) {
    case "expectation-count-asc":
      suites = orderBy(suites, (s) => s.expectationCount, ["asc"])
      break
    case "expectation-count-desc":
      suites = orderBy(suites, (s) => s.expectationCount, ["desc"])
      break
    case "checkpoint-count-desc":
      suites = orderBy(suites, (s) => s.checkpointCount, ["desc"])
      break
    case "checkpoint-count-asc":
      suites = orderBy(suites, (s) => s.checkpointCount, ["asc"])
      break
    case "name-desc":
      suites = orderBy(suites, (s) => s.name.toLowerCase(), ["desc"])
      break
    case "name-asc":
      suites = orderBy(suites, (s) => s.name.toLowerCase(), ["asc"])
  }

  const items = suites.map((s) => ({
    id: s.id,
    title: s.name,
    expectationCount: s.expectationCount,
    checkpointCount: s.checkpointCount,
    checkpointsURL: "checkpoints?expectationSuite=" + encodeURIComponent(s.id),
  }))

  return {
    loading,
    error,
    noData,
    items,
  }
}

export { useExpectationSuiteListItems }
