import { SupportedDataSource } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"
import { LogoAndLinkHeader } from "src/DataAssets/connect-to-data/DataCRUDDrawerHeaders"
import { Checkbox } from "antd"
import { CodeSnippetEditor } from "src/ui/CodeSnippetEditor/CodeSnippetEditor"
import {
  CreateUserInSnowflake,
  CreateUserInPostgres,
  PostgresCTA,
  SnowflakeCTA,
  PostgresCodeBlockHeight,
  SnowflakeCodeBlockHeight,
} from "src/DataAssets/connect-to-data/CreateGXUserWords"
import { Drawer, ScrollableFlex } from "src/ui/Drawer/Drawer"
import { copyToClipboard } from "src/common/utils/clipboard"
import { StyledCopyButton } from "src/ui/AppModal/AppModal"
import { useEffect } from "react"
import { useAnalytics } from "src/analytics/useAnalytics"
import { theme } from "src/ui/themes/theme"
import { AlertInfo } from "src/ui/Alert"

type InfoParts = {
  snippet: string
  cta: string
  height: number
}

const TypeToWordsMap: Record<SupportedDataSource, InfoParts> = {
  DATABRICKS_SQL: { snippet: "TODO snippet for creating databricks user", cta: "TODO cta", height: 30 },
  POSTGRES: { snippet: CreateUserInPostgres, cta: PostgresCTA, height: PostgresCodeBlockHeight },
  SNOWFLAKE: { snippet: CreateUserInSnowflake, cta: SnowflakeCTA, height: SnowflakeCodeBlockHeight },
  SQL: { snippet: "", cta: "", height: 0 },
  SQLITE: { snippet: "", cta: "", height: 0 },
}

export function CreateGXUserPage({
  dataSourceType,
  GXUserCheckboxChecked,
  setGXUserCheckboxChecked,
  onBack,
  onContinue,
}: {
  dataSourceType: SupportedDataSource
  GXUserCheckboxChecked: boolean
  setGXUserCheckboxChecked: (checked: boolean) => void
  onBack: () => void
  onContinue: () => void
}) {
  const snippet = TypeToWordsMap[dataSourceType].snippet
  const cta = TypeToWordsMap[dataSourceType].cta
  const height = TypeToWordsMap[dataSourceType].height
  const posthog = useAnalytics()

  useEffect(() => {
    if (posthog) {
      posthog.capture("add_asset.gxUser_page_viewed", { dataSourceType })
    }
    // needs to run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleContinue = () => {
    onContinue()
    posthog?.capture("add_asset.continue_clicked", { dataSourceType })
  }

  return (
    <>
      <ScrollableFlex vertical gap="middle">
        <LogoAndLinkHeader dataSourceType={dataSourceType} text="Create a user in" alert={GXUserAlert} />
        <p>{cta}:</p>
        <div style={{ minHeight: `${height}px` }}>
          <CodeSnippetEditor
            value={snippet}
            language="sql"
            height={`${height}px`}
            fontSize={12}
            showLineNumbers={false}
          />
        </div>
        <StyledCopyButton
          icon="clipboard"
          aria-label="Copy"
          disabled={!snippet}
          style={{ marginBottom: theme.spacing.vertical.xs }}
          onClick={() => {
            copyToClipboard(snippet)
            posthog?.capture("add_asset.snippet_copied", { dataSourceType })
          }}
        >
          Copy snippet
        </StyledCopyButton>
        <Checkbox
          style={{ marginBottom: theme.spacing.vertical.xs }}
          onChange={(e) => setGXUserCheckboxChecked(e.target.checked)}
        >
          I have created a GX Cloud user with access permissions
        </Checkbox>
      </ScrollableFlex>
      <Drawer.Footer>
        <Drawer.FooterButton onClick={onBack}>Back</Drawer.FooterButton>
        <Drawer.FooterButton type="primary" disabled={!GXUserCheckboxChecked} onClick={handleContinue}>
          Continue
        </Drawer.FooterButton>
      </Drawer.Footer>
    </>
  )
}

const GXUserAlert = (
  <AlertInfo message="Centralize data governance at the source by defining data quality targets using access rules." />
)
