import styled from "styled-components"
import { Flex, Typography } from "antd"
import { Icon } from "src/ui/Icon/Icon"
import { theme } from "src/ui/themes/theme"
import { BodySemiBold, LabelRegular } from "src/ui/typography/Text"
import { getImageLogo } from "src/DataAssets/getImageLogo"

import { getHumanReadableDataSourceType } from "src/DataAssets/connect-to-data/PickNewOrExistingDataSource"
import { SupportedDataSource } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"
import React from "react"

const StyledLogo = styled.img`
  width: ${({ theme }) => theme.spacing.scale.xs};
  height: ${({ theme }) => theme.spacing.scale.xs};
`

const TypeToDocsURLMap: Record<SupportedDataSource, string> = {
  DATABRICKS_SQL: "https://docs.greatexpectations.io/docs/cloud/connect/connect_databricks_sql", // Note: docs don't exist yet
  POSTGRES: "https://docs.greatexpectations.io/docs/cloud/connect/connect_postgresql",
  SNOWFLAKE: "https://docs.greatexpectations.io/docs/cloud/connect/connect_snowflake",
  SQL: "https://docs.greatexpectations.io/docs/oss/guides/connecting_to_your_data/fluent/database/connect_sql_source_data",
  SQLITE:
    "https://docs.greatexpectations.io/docs/oss/guides/connecting_to_your_data/fluent/database/connect_sql_source_data/?sql-database-type=sqlite",
}

type LogoAndLinkHeaderProps = {
  dataSourceType: SupportedDataSource
  text: string
  secondaryText?: string
  alert?: React.ReactNode
}

export function LogoAndLinkHeader({ dataSourceType, text, alert, secondaryText = "" }: LogoAndLinkHeaderProps) {
  return (
    <Flex vertical gap="large">
      <Flex gap="small" align="center">
        <StyledLogo src={getImageLogo(dataSourceType)} alt={`${dataSourceType} logo`} />
        <BodySemiBold>{`${text} ${getHumanReadableDataSourceType(dataSourceType)} ${secondaryText}`}</BodySemiBold>
        <DataSourceDocsLink dataSourceType={dataSourceType} />
      </Flex>
      {alert}
    </Flex>
  )
}

export function EditDataSourceDrawerHeader({
  dataSourceType,
  dataSourceName,
}: {
  dataSourceType: SupportedDataSource
  dataSourceName: string
}) {
  return (
    <Flex vertical gap="large">
      <Flex gap="small" align="center">
        <StyledLogo src={getImageLogo(dataSourceType)} alt={`${dataSourceType} logo`} />
        <BodySemiBold>{dataSourceName}</BodySemiBold>
        <DataSourceDocsLink dataSourceType={dataSourceType} />
      </Flex>
    </Flex>
  )
}

export function AddAssetDrawerHeader({
  dataSourceType,
  dataSourceName,
  dataSourceAddress,
}: {
  dataSourceType: SupportedDataSource
  dataSourceName: string
  dataSourceAddress?: string
}) {
  return (
    <Flex vertical gap="small">
      <Flex gap="small" align="center">
        <StyledLogo src={getImageLogo(dataSourceType)} alt={`${dataSourceType} logo`} />
        <BodySemiBold>{dataSourceName}</BodySemiBold>
      </Flex>
      {dataSourceAddress && <LabelRegular $gxPrimaryLight>{dataSourceAddress}</LabelRegular>}
    </Flex>
  )
}

type EditDataAssetDrawerHeaderProps = {
  dataSourceType: SupportedDataSource
  dataAssetName: string
}

export function EditDataAssetDrawerHeader({ dataSourceType, dataAssetName }: EditDataAssetDrawerHeaderProps) {
  return (
    <Flex vertical gap="large">
      <Flex gap="small" align="center">
        <StyledLogo src={getImageLogo(dataSourceType)} alt={`${dataSourceType} logo`} />
        <BodySemiBold>{dataAssetName}</BodySemiBold>
      </Flex>
    </Flex>
  )
}

export function DataSourceDocsLink({ dataSourceType }: { dataSourceType: SupportedDataSource }) {
  const docsURL = TypeToDocsURLMap[dataSourceType]
  return (
    <Typography.Link href={docsURL} target="_blank" rel="noopener noreferrer">
      <Icon
        size="18px"
        name="externalLink"
        color={theme.colors.neutralColorPalette.blacks.colorTextQuaternary}
        style={{ marginTop: "4px" }}
        aria-label="external data source docs link"
      />
    </Typography.Link>
  )
}
