import {
  differenceInHours,
  differenceInMinutes,
  isThisYear,
  isToday,
  isYesterday,
  parseISO,
  format,
  add,
} from "date-fns"
import {
  APP_24_HOUR_TIME_FORMAT,
  APP_24_HOUR_TIME_WITH_TIMEZONE_FORMAT,
  APP_CALENDAR_DATE_FORMAT,
  APP_CALENDAR_DATE_WITH_YEAR_FORMAT,
  APP_DATE_FORMAT,
  APP_DATE_WITH_YEAR_FORMAT,
  APP_DATETIME_FORMAT,
  APP_TIME_FORMAT,
  APP_TIME_WITH_TIMEZONE_FORMAT,
} from "src/common/config"

const recentRelativeTimeFormatter = new Intl.RelativeTimeFormat("en", {
  numeric: "always",
  style: "narrow",
})

export function formatTime(dateString: string): string {
  const date = parseISO(dateString)
  if (isNaN(date.getTime())) {
    return dateString // Return value if string is not a valid date format
  }
  const hoursSinceDate = differenceInHours(new Date(), date)
  const timeStr = Intl.DateTimeFormat([], APP_TIME_FORMAT).format(date)
  const dateStr = Intl.DateTimeFormat([], APP_DATE_FORMAT).format(date)

  if (hoursSinceDate < 1) {
    const minutesSinceDate = differenceInMinutes(new Date(), date)
    return recentRelativeTimeFormatter.format(-minutesSinceDate, "minutes")
  }
  if (hoursSinceDate < 8) {
    return recentRelativeTimeFormatter.format(-hoursSinceDate, "hours")
  }
  if (hoursSinceDate < 24) {
    return timeStr
  }

  return `${timeStr} - ${dateStr}`
}

export function formatRanAtTime(dateString: string): string {
  const date = parseISO(dateString)
  if (isNaN(date.getTime())) {
    return dateString // Return value if string is not a valid date format
  }
  const timeStr = Intl.DateTimeFormat([], APP_TIME_FORMAT).format(date)
  const dateStr = Intl.DateTimeFormat([], APP_DATE_WITH_YEAR_FORMAT).format(date)

  if (isToday(date)) {
    return `${timeStr} Today`
  }
  if (isYesterday(date)) {
    return `${timeStr} Yesterday`
  }
  return `${timeStr} ${dateStr}`
}

export function formatLocalDateWithTime(iso: string) {
  /*
  ~'this day and this time' in local timezone
  ex. "05/31/2023, 8:00:00 PM"
   */
  return Intl.DateTimeFormat([], APP_DATETIME_FORMAT).format(parseISO(iso))
}

export function formatLocalCalendarDateWithTimeAndTimeZone(iso: string) {
  const targetDate = parseISO(iso)
  let dateString = ""

  if (isToday(targetDate)) {
    dateString = "Today"
  } else if (isYesterday(targetDate)) {
    dateString = "Yesterday"
  } else if (isThisYear(targetDate)) {
    //don't display year
    dateString = Intl.DateTimeFormat([], APP_CALENDAR_DATE_FORMAT).format(targetDate)
  } else {
    // display year
    dateString = Intl.DateTimeFormat([], APP_CALENDAR_DATE_WITH_YEAR_FORMAT).format(targetDate)
  }
  const timeString = Intl.DateTimeFormat([], APP_24_HOUR_TIME_WITH_TIMEZONE_FORMAT).format(targetDate)
  return `${dateString} at ${timeString}`
}

export function formatLocalCalendarDateWithTime(iso: string) {
  /*
  TODO: update this for FE-77
  < 1 hour: X minutes ago (not yet implemented) ❌
  > 1 hour: use absolute time
    If the date is today, display “Today”: Today at 20:00 ✅
    If the date is yesterday, display “Yesterday”: Yesterday at 20:00 ✅
    Otherwise:
      If year is current year, don’t display year: April 29 at 20:00 ✅
      Otherwise, display year: April 29, 2014 at 20:00 ✅
  */
  const targetDate = parseISO(iso)
  let dateString = ""

  if (isToday(targetDate)) {
    dateString = "Today"
  } else if (isYesterday(targetDate)) {
    dateString = "Yesterday"
  } else if (isThisYear(targetDate)) {
    //don't display year
    dateString = Intl.DateTimeFormat([], APP_CALENDAR_DATE_FORMAT).format(targetDate)
  } else {
    // display year
    dateString = Intl.DateTimeFormat([], APP_CALENDAR_DATE_WITH_YEAR_FORMAT).format(targetDate)
  }
  const timeString = Intl.DateTimeFormat([], APP_24_HOUR_TIME_FORMAT).format(targetDate)

  return `${dateString} at ${timeString}`
}

export function formatLocalDate(iso: string) {
  /*
  ~'this day' in local timezone
  ex. "05/31/2023"
   */
  return Intl.DateTimeFormat([], APP_DATE_WITH_YEAR_FORMAT).format(parseISO(iso))
}

export function formatLocalTime(iso: string) {
  /*
  ~'this time and timezone' in local timezone
  ex. "8:00 PM EST"
   */
  return Intl.DateTimeFormat([], APP_TIME_WITH_TIMEZONE_FORMAT).format(parseISO(iso))
}

export function formatDateForTable(iso: string) {
  /*
  ~'this day and time' in timezone
  ex. "2020-01-01" or "2020-01-01 01:02:03"
   */
  // A TIMESTAMP_NTZ may belong to UTC, but it is not guaranteed. It could belong to a predetermined timezone not encoded into data

  function includesTimezone(iso: string) {
    return iso.includes("Z") || iso.includes("+")
  }

  function containsISODate(iso: string) {
    const regex = /\d{4}-[0-9]{2}-[0-9]{2}.*/ // "[2024-01-01]..."
    return regex.test(iso)
  }

  function cleanISO(iso: string) {
    return iso.replace("T00:00:00.000Z", "").replace(".000", "").replace("T", " ").replace("Z", "")
  }

  if (containsISODate(iso) && !includesTimezone(iso)) {
    // Snowflake's TIMESTAMP_NTZ means that there is no corresponding timezone encoded into data.
    // However, ISO8601 states that a timestamp with no timezone should be interpreted using local timezone.
    // Javascript automatically uses server timezone, which is undesirable in this scenario
    // As a result, do not parse ISO strings missing timezone
    return cleanISO(iso)
  }
  // Format ISO strings such as "Nov 19 2022"
  const reformatted = new Date(iso).toISOString()
  return cleanISO(reformatted)
}

// getFirstDayNextMonthLongFmt returns the first day of the next month
// e.g. now is 2023-09-15 => October 1, 2023
// e.g. now is 2023-12-01 => January 1, 2024
export const getFirstDayNextMonthLongFmt = (): string => {
  return format(add(new Date(), { months: 1 }), "MMMM 1, yyyy")
}
