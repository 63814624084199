import { useResetUrlParams, useUrlParams } from "src/common/utils/url-params"
import { LabelSemiBold } from "src/ui/typography/Text"
import { MenuProps, Skeleton } from "antd"
import { Button } from "src/ui/Button/Button"
import { Icon } from "src/ui/Icon"
import { map, startCase, without, concat, reject, uniqBy, orderBy } from "lodash-es"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import {
  StyledFilterSummary,
  StyledClearAllButton,
  FilterContainer,
  StyledCatHeader,
} from "src/Checkpoints/CheckpointFilter.styles"
import { useEffect, useState } from "react"
import {
  CardContainer,
  CollapsableFilterMenu,
  FilterLabelWrapper,
} from "src/ui/CollapsableFilterMenu/CollapsableFilterMenu"
import { ActiveListType, FailuresToggle } from "src/ui/Button/FailuresToggle"
import { useQuery } from "@apollo/client"
import { CheckpointFragment, CheckpointsDocument } from "src/api/graphql/graphql-operations"

interface SelectedFilter {
  id: string
  type: string
}

const FilterLoading = ({ loading }: { loading: boolean }) => {
  return <Skeleton loading={loading} active paragraph={{ rows: 15 }} title={false} />
}

export const CheckpointFilter = () => {
  const [sortedCheckpoints, setSortedCheckpoints] = useState<CheckpointFragment[] | []>([])
  const { data, loading } = useQuery(CheckpointsDocument, {
    onCompleted: (data) => {
      const checkpoints = data?.checkpoints?.filter(Boolean) ?? []
      setSortedCheckpoints(orderBy(checkpoints, (ckpt) => ckpt.name.toLowerCase(), ["asc"]))
    },
  })
  const dataAssetsWithDupes: ItemType[] = sortedCheckpoints.flatMap((c) =>
    c.validations.map((v) => ({
      key: v?.dataAsset?.id || "none", //AntD will make up a key if one isn't provided, hence adding a default empty string key
      label: (
        <FilterLabelWrapper title={v?.dataAsset?.name}>
          <span className="name">{v?.dataAsset?.name ?? "None"}</span> <Icon className="icon" name="check" />
        </FilterLabelWrapper>
      ),
    })),
  )

  const expectationSuitesWithDupes: ItemType[] = sortedCheckpoints.flatMap((c) =>
    c.validations
      .filter((v) => v?.expectationSuiteV2?.name)
      .map((v) => ({
        key: v?.expectationSuiteV2?.id ?? "",
        label: (
          <FilterLabelWrapper title={v?.expectationSuiteV2?.name ?? ""}>
            <span className="name">{v?.expectationSuiteV2?.name}</span>
            <Icon className="icon" name="check" />
          </FilterLabelWrapper>
        ),
      })),
  )

  const checkpointOptions: ItemType[] = sortedCheckpoints.map((item) => ({
    key: item.id,
    label: (
      <FilterLabelWrapper title={item.name}>
        <span className="name">{item.name}</span>
        <Icon className="icon" name="check" />
      </FilterLabelWrapper>
    ),
  }))

  const dataAssetOptions = uniqBy(dataAssetsWithDupes, "key")
  const expectationSuiteOptions = uniqBy(expectationSuitesWithDupes, "key")

  const defaultQueryParams: { [k: string]: string | string[] } = { failures: "", search: "" }

  const hasSelections = (title: string) => params[title].length > 0
  const SectionHeader = ({ title, type, count }: { title: string; type: string; count: number }) => {
    return (
      <StyledCatHeader>
        <LabelSemiBold title={startCase(title)} tabIndex={0}>
          {startCase(title)} ({count})
        </LabelSemiBold>
        {hasSelections(type) && (
          <Button
            tabIndex={0}
            title={`Clear ${startCase(title)} filters`}
            icon="closeCircleSolid"
            type="link"
            onClick={(e) => {
              e.stopPropagation()
              resetParams([type])
              setSelectedFilters(reject(selectedFilters, { type: type }))
            }}
          />
        )}
      </StyledCatHeader>
    )
  }

  const filters: ItemType[] = [
    {
      key: "checkpoint",
      label: <SectionHeader title="checkpoints" type="checkpoint" count={checkpointOptions.length} />,
      children: checkpointOptions,
    },
    {
      key: "dataAsset",
      label: <SectionHeader title="data assets" type="dataAsset" count={dataAssetOptions.length} />,
      children: dataAssetOptions,
    },
    {
      key: "expectationSuite",
      label: (
        <SectionHeader title="expectation suites" type="expectationSuite" count={expectationSuiteOptions.length} />
      ),
      children: expectationSuiteOptions,
    },
  ]

  filters.forEach((filter) => {
    if (filter && filter.key) {
      defaultQueryParams[filter.key.toString()] = []
    }
  })
  const [params, setParams] = useUrlParams(defaultQueryParams)
  const resetParams = useResetUrlParams()

  const getInitialSearchFilters = () => {
    const search: { id: string; type: string }[] = []
    for (const [key, value] of Object.entries(params)) {
      if (value !== "" && Array.isArray(value) && value.length) {
        value.forEach((query) => {
          search.push({ id: query, type: key })
        })
      }
    }
    return search
  }

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>(getInitialSearchFilters())

  const handleSelection: MenuProps["onSelect"] = ({ key, keyPath }) => {
    setParams({ [keyPath[1]]: concat(...params[keyPath[1]], key) })
    setSelectedFilters(concat(selectedFilters, { id: key, type: keyPath[1] }))
  }

  const handleDeselection: MenuProps["onDeselect"] = ({ key, keyPath }) => {
    setParams({ [keyPath[1]]: without(params[keyPath[1]], key) })
    setSelectedFilters(reject(selectedFilters, { id: key }))
  }

  const matchingCheckpoints = sortedCheckpoints.filter((cp) => params.checkpoint.includes(cp.id))
  const shouldResetFilters = !loading && params.checkpoint.length > 0 && matchingCheckpoints.length === 0

  useEffect(() => {
    if (shouldResetFilters) {
      resetParams(["checkpoint"])
      setSelectedFilters([])
    }
  }, [resetParams, shouldResetFilters])

  const handleToggleClick = (selection: ActiveListType) =>
    setParams({ failures: selection === "failures" ? "true" : "false" })

  return (
    <FilterContainer>
      <FilterLoading loading={loading} />
      {data && (
        <>
          <FailuresToggle
            onToggleClick={handleToggleClick}
            defaultValue={params.failures === "true" ? "failures" : "all"}
            disabled={false}
          />
          <div>
            <StyledFilterSummary>
              <StyledClearAllButton
                icon="closeCircleSolid"
                type="link"
                className={`${selectedFilters.length === 0 && "hidden"}`}
                onClick={() => {
                  resetParams(filters.map((f) => f?.key as string))
                  setSelectedFilters([])
                }}
                title="Clear all filters"
              >
                Clear all
              </StyledClearAllButton>
            </StyledFilterSummary>
            <CardContainer>
              <CollapsableFilterMenu
                items={filters}
                onSelect={handleSelection}
                onDeselect={handleDeselection}
                selectedKeys={map(selectedFilters, "id")}
                mode="inline"
                multiple={true}
                defaultOpenKeys={filters.map((f) => f?.key as string)}
                inlineIndent={0}
                subMenuCloseDelay={0}
              />
            </CardContainer>
          </div>
        </>
      )}
    </FilterContainer>
  )
}
