import { Pill, SuccessSelectedState } from "src/ui/Pill/Pill"
import { CheckpointRunFragment } from "src/api/graphql/graphql-operations"

type Props = {
  expanded: boolean
  run: CheckpointRunFragment
  setSelectedRunId: (runId: string) => void
  isPillSelected: boolean
  ariaLabel?: string
}

export function CheckpointRunSelector({ expanded, run, setSelectedRunId, isPillSelected, ...props }: Props) {
  const failure = run.results.some((result) => !result.success)
  const state = getSuccessSelectState(isPillSelected, failure)
  const stateText = run.results.every((result) => result.success) ? "All passed" : `Failed`

  return (
    <Pill
      aria-label={props.ariaLabel}
      state={state}
      key={run.runId}
      onSelect={(e) => {
        setSelectedRunId(run.runId)
        if (expanded) {
          e.stopPropagation()
        }
      }}
    >
      {stateText}
    </Pill>
  )
}

function getSuccessSelectState(selected: boolean, failure: boolean): SuccessSelectedState {
  if (!selected && !failure) {
    return "Unselected Success"
  }
  if (selected && !failure) {
    return "Selected Success"
  }
  if (!selected && failure) {
    return "Unselected Failure"
  }
  if (selected && failure) {
    return "Selected Failure"
  }
  throw new Error(`Invalid state`)
}
