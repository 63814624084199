import { getRenderer, ExpectationContainer } from "src/Expectation/utils"
import { StyledPreviewSection } from "src/Expectation/Preview.styles"
import { ExpectationRenderer } from "src/Expectation/ExpectationRenderer"

export function Preview({ renderer, success }: { renderer: ExpectationRenderer; success?: boolean }) {
  return (
    <StyledPreviewSection>
      <ExpectationContainer success={success}>
        <div>{getRenderer(renderer)}</div>
      </ExpectationContainer>
    </StyledPreviewSection>
  )
}
