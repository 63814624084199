import { FeedbackMessageDoNotUse } from "src/ui/FeedbackMessage"
import { LabelRegular, SubtitleSemiBold } from "src/ui/typography/Text/Text"
import { FlexContainer, WarningIcon } from "src/users/Users.styles"
import { LoadingState } from "src/ui/LoadingState"
import { AppModal } from "src/ui/AppModal/AppModal"
import { message, Space } from "antd"
import styled from "styled-components"
import { uniqBy } from "lodash-es"
import { useMutation, useQuery } from "@apollo/client"
import { DeleteExpectationSuiteDocument, ExpectationSuiteDocument } from "src/api/graphql/graphql-operations"
import { pluralize } from "src/common/utils/strings"
import { MESSAGE_DURATION_SECONDS } from "src/common/config"

interface DeleteExpectationSuiteModalProps {
  id: string
  isVisible: boolean
  setIsVisible: (val: boolean) => void
}

const UL = styled.ul`
  margin: 1rem 0;
`

const LI = styled.li`
  margin-bottom: 0.5rem;
`

const BodyWrapper = styled(FlexContainer)`
  padding: 0 ${({ theme }) => theme.spacing.scale.m};
  overflow-wrap: anywhere;
`

function DeleteExpectationSuiteModal(props: DeleteExpectationSuiteModalProps) {
  const { id, isVisible, setIsVisible } = props

  const { data, loading: qLoading } = useQuery(ExpectationSuiteDocument, {
    variables: {
      id,
    },
    skip: !isVisible,
  })

  const checkpointsAffected =
    uniqBy(
      data?.expectationSuiteV2?.validations.flatMap((v) => v.checkpoints),
      "id",
    ) ?? []

  const [deleteSuite, { loading: mLoading, error: mError }] = useMutation(DeleteExpectationSuiteDocument, {
    variables: { id },
    onCompleted: () => {
      setIsVisible(false)
      message.success("Expectation Suite deleted", MESSAGE_DURATION_SECONDS)
    },
    // onError is needed in order to pick up the mutation error status
    onError: () => undefined,
    update: (cache, { data }) => {
      if (data?.deleteExpectationSuiteV2) {
        cache.evict({ id: cache.identify(data.deleteExpectationSuiteV2) })
      }
    },
  })

  return (
    <AppModal
      title={
        <FlexContainer>
          <Space direction="horizontal" align="center">
            <WarningIcon size="28px" name="exclamationCircle" />
            <SubtitleSemiBold>Delete Expectation Suite</SubtitleSemiBold>
          </Space>
        </FlexContainer>
      }
      onOk={() => deleteSuite()}
      okButtonProps={{ danger: true, loading: mLoading, disabled: qLoading || checkpointsAffected.length > 0 }}
      okText="Delete"
      open={isVisible}
      onCancel={() => setIsVisible(false)}
      confirmLoading={mLoading}
    >
      {qLoading && <LoadingState loading absolute={false} />}
      {!qLoading && (
        <BodyWrapper>
          <div>
            <Space direction="vertical">
              {checkpointsAffected.length === 0 && (
                <LabelRegular>
                  Are you sure you want to delete <b>{data?.expectationSuiteV2?.name ?? ""}</b>?
                </LabelRegular>
              )}

              {checkpointsAffected.length > 0 && (
                <FeedbackMessageDoNotUse
                  title="Unable to delete Expectation Suite"
                  type="warning"
                  description={
                    <>
                      <LabelRegular>
                        The Expectation Suite <b>{data?.expectationSuiteV2?.name ?? ""}</b> cannot be deleted because it
                        is associated with the following {pluralize("Checkpoint", checkpointsAffected.length)}:
                      </LabelRegular>
                      <UL>
                        {checkpointsAffected.map((c) => (
                          <LI key={c.id}>
                            <LabelRegular>{c.name}</LabelRegular>
                          </LI>
                        ))}
                      </UL>
                    </>
                  }
                />
              )}
              {mError && (
                <FeedbackMessageDoNotUse
                  title="Error removing Expectation Suite"
                  type="error"
                  description="An error ocurred while removing this Expectation Suite. Try again later."
                />
              )}
            </Space>
          </div>
        </BodyWrapper>
      )}
    </AppModal>
  )
}

export { DeleteExpectationSuiteModal }
