/* eslint-disable react-refresh/only-export-components */ // FIXME
import { Select } from "antd"
import { useUrlParams } from "src/common/utils/url-params"
import { AlignCenter } from "src/expectationSuites/ExpectationSuiteList/ExpectationSuiteList.styles"
import { MainContentListHeader } from "src/ui/List/MainContentList"
import { Search } from "src/ui/Search/Search"
import { CSSProperties, ReactNode } from "react"
import { SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons"
import { useTheme } from "styled-components"
import { assertNever } from "src/DataAssets/utils"

type OrderDirection = "asc" | "desc"
type ExpectationOrderEntity = "expectation-count" | "checkpoint-count" | "name"
type ExpectationSortOption = `${ExpectationOrderEntity}-${OrderDirection}`
export type ExpectationSortParams = {
  search: string
  order: ExpectationSortOption
}
export const DefaultExpectationSortOption: ExpectationSortOption = "name-asc"

function ExpectationSuiteFilter() {
  const [params, setParams] = useUrlParams<ExpectationSortParams>({ search: "", order: DefaultExpectationSortOption })
  const sanitizedOrder = valueIsValidExpectationSortOption(params.order) ? params.order : DefaultExpectationSortOption
  const theme = useTheme()
  const iconStyle: CSSProperties = {
    fontSize: theme.typography.semibold.body.fontSize,
    marginRight: theme.spacing.horizontal.xxs,
  }
  const orderOptions: { name: string; value: ExpectationSortOption; icon: ReactNode }[] = [
    {
      name: "Suite Name",
      value: "name-asc",
      icon: <SortAscendingOutlined style={iconStyle} />,
    },
    {
      name: "Suite Name",
      value: "name-desc",
      icon: <SortDescendingOutlined style={iconStyle} />,
    },
    {
      name: "Expectation Count",
      value: "expectation-count-asc",
      icon: <SortAscendingOutlined style={iconStyle} />,
    },
    {
      name: "Expectation Count",
      value: "expectation-count-desc",
      icon: <SortDescendingOutlined style={iconStyle} />,
    },
    {
      name: "Checkpoint Count",
      value: "checkpoint-count-asc",
      icon: <SortAscendingOutlined style={iconStyle} />,
    },
    {
      name: "Checkpoint Count",
      value: "checkpoint-count-desc",
      icon: <SortDescendingOutlined style={iconStyle} />,
    },
  ]

  return (
    <MainContentListHeader
      search={
        <Search
          placeholder="Search by Expectation Suite name"
          defaultValue={params.search}
          onChange={(e) => setParams({ search: e.target.value })}
          role="search"
          aria-label="Search Expectation Suite input"
        />
      }
      sort={
        <Select
          defaultValue={orderOptions[0].value}
          style={{ minWidth: "200px" }}
          value={sanitizedOrder}
          onChange={(val: ExpectationSortOption) => setParams({ order: val })}
          aria-label="Sort expectation suite input"
        >
          {orderOptions.map((opt, i) => (
            <Select.Option value={opt.value} key={i} aria-label={opt.name}>
              <AlignCenter>
                {opt.icon}
                <span>{opt.name}</span>
              </AlignCenter>
            </Select.Option>
          ))}
        </Select>
      }
    />
  )
}

export function valueIsValidExpectationSortOption(s: string): boolean {
  const option = s as ExpectationSortOption
  switch (option) {
    case "checkpoint-count-asc":
    case "checkpoint-count-desc":
    case "expectation-count-asc":
    case "expectation-count-desc":
    case "name-asc":
    case "name-desc":
      return true
    default:
      // if you see a TypeScript error below, it's because you're missing a case statement
      // for a member of the union
      try {
        assertNever(option)
      } catch (e) {
        return false
      }
  }
}

export { ExpectationSuiteFilter }
