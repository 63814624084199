import { DataAssetQuery } from "src/api/graphql/graphql-operations"
import { SplitterType } from "src/DataAssets/AssetDetails/Splitters/ValidateSubsetDrawer"
import { isEmpty, startCase, isPlainObject, isArray, isNumber, isString } from "lodash-es"
import { useMemo } from "react"
import { VALIDATE_ERROR_EMPTY_BATCH_TEXT, VALIDATE_ERROR_TEXT } from "src/DataAssets/words"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import {
  DataAssetWithLatestMetricRunFragment,
  DateSplitterConfigInput,
  DateSplitterMethodName,
  Metric,
} from "src/api/graphql/graphql"

const isTimeBasedTypeRegex = "(TIME|DATE)"

export const useGetBatchDefinition = (splitterType?: SplitterType): string => {
  const batchDefinitionEnabled = useIsFeatureEnabled("batchDefinitionEnabled")

  if (!splitterType) {
    return ""
  }

  const formatSplitterType = (type: SplitterType) => startCase(type.replace("Splitter", "")).replace(/And/g, "-")

  if (batchDefinitionEnabled) {
    switch (splitterType) {
      case "SplitterYearAndMonthAndDay":
        return "Daily"
      case "SplitterYearAndMonth":
        return "Monthly"
      case "SplitterYear":
        return "Yearly"
      default:
        return formatSplitterType(splitterType)
    }
  }

  return formatSplitterType(splitterType)
}

export const getSplitterColumn = (splitterData: NonNullable<DataAssetQuery["dataAsset"]>["splitter"]) => {
  if (splitterData !== null && splitterData !== undefined) {
    if ("columnNames" in splitterData) {
      return {
        column: splitterData.columnNames.join(", "),
        isMulticolumn: true,
      }
    }
    return { column: splitterData.columnName, isMulticolumn: false }
  }

  return { column: "", isMulticolumn: false }
}

const isType = (type: string, data: unknown) => {
  switch (type) {
    case "array":
      return isArray(data)
    case "object":
      return isPlainObject(data)
    case "string":
      return isString(data)
    case "number":
      return isNumber(data)
    case "boolean":
      return typeof data === "boolean"
    default:
      return false
  }
}

const formatTypeLike = (data: unknown[], type: string, hasColName: boolean) => {
  let comaJoined = data.join(", ")

  switch (type) {
    case "object":
      if (hasColName || comaJoined[0] !== "{") {
        comaJoined = `{ ${comaJoined} }`
      }
      return [comaJoined]
    case "date":
      return [data.join("-")]
    case "string":
    case "number":
    case "boolean":
    case "array":
      return [comaJoined]
    default:
      return []
  }
}

export const parseBatchIdentifiers = (
  batchIdentifiers: string | undefined | null,
  splitterInfo: NonNullable<DataAssetQuery["dataAsset"]>["splitter"],
) => {
  if (!batchIdentifiers || !splitterInfo) {
    return { batch: "", restOutput: "", isMulticolumn: false }
  }

  let batchInfo
  let output: string[] = []
  const restOutput: string[] = []

  try {
    batchInfo = JSON.parse(batchIdentifiers as string)
  } catch (e) {
    console.warn(e)
    return { batch: "", restOutput: "", isMulticolumn: false }
  }

  if (!isEmpty(batchInfo)) {
    const splitterType: SplitterType = splitterInfo?.["__typename"] as SplitterType
    const dateBasedSplitters = ["SplitterYearAndMonthAndDay", "SplitterYearAndMonth", "SplitterYear"]
    const isDateBasedSplitter = dateBasedSplitters.some((dbs) => splitterType.includes(dbs))
    const isSingleColumnSplitter = "columnName" in splitterInfo
    const isMulticolumnSplitter = "columnNames" in splitterInfo

    if (isSingleColumnSplitter) {
      const columnBatchInfo = batchInfo[splitterInfo?.columnName]
      if (!columnBatchInfo) {
        return { batch: "", restOutput: "", isMulticolumn: false }
      }

      if (isDateBasedSplitter) {
        for (const [key, value] of Object.entries(columnBatchInfo)) {
          switch (key) {
            case "year":
              output[0] = String(value)
              break
            case "month":
              output[1] = String(value)
              break
            case "day":
              output[2] = String(value)
              break
          }
        }
        output = formatTypeLike(output, "date", false)
      } else if (isType("object", columnBatchInfo)) {
        for (const [key, value] of Object.entries(columnBatchInfo)) {
          output.push(`${key}: ${value}`)
        }
        output = formatTypeLike(output, "object", false)
      } else if (isType("array", columnBatchInfo)) {
        columnBatchInfo.map((value: string) => output.push(value))
        output = formatTypeLike(output, "array", false)
      } else if (
        isType("string", columnBatchInfo) ||
        isType("number", columnBatchInfo) ||
        isType("boolean", columnBatchInfo)
      ) {
        output.push(columnBatchInfo)
        output = formatTypeLike(output, "string", false)
      }
    } else if (isMulticolumnSplitter) {
      if (!splitterInfo?.columnNames[0] || !batchInfo[splitterInfo?.columnNames[0]]) {
        return { batch: "", restOutput: "", isMulticolumn: false }
      }

      Object.entries(batchInfo).forEach(([colName, colValue], index) => {
        const isFirstColumn = index === 0
        if (index > 0) {
          restOutput.push(`${colName}:`)
        }

        if (isType("object", colValue)) {
          const temp: unknown[] = []
          for (const [key, value] of Object.entries(colValue as { [s: string]: unknown })) {
            isFirstColumn ? output.push(`${key}: ${value}`) : temp.push(`${key}: ${value}`)
          }
          if (isFirstColumn) {
            output = formatTypeLike(output, "object", false)
          } else {
            restOutput.push(formatTypeLike(temp, "object", true)[0])
          }
        } else if (isArray(colValue)) {
          const temp: unknown[] = []
          colValue.map((value: string) => {
            isFirstColumn ? output.push(value) : temp.push(value)
          })
          if (isFirstColumn) {
            output = formatTypeLike(output, "array", false)
          } else {
            restOutput.push(formatTypeLike(temp, "array", true)[0])
          }
        } else if (isType("string", colValue) || isType("number", colValue) || isType("boolean", colValue)) {
          if (isFirstColumn) {
            output = formatTypeLike([colValue], "string", false)
          } else {
            restOutput.push(formatTypeLike([colValue], "string", true)[0])
          }
        }
      })
    }

    const hasBatch = output.length > 0
    const hasMultipleColumns = restOutput.length > 0
    return {
      batch: hasBatch ? `Batch: ${output.join(" ")}${hasMultipleColumns ? "..." : ""}` : "",
      restBatch: hasMultipleColumns ? restOutput.join(" ") : "",
      isMulticolumn: hasMultipleColumns,
    }
  }
}

export interface SplitterError {
  errorMessage: string
  alertLevel: "error" | "warning" | "success" | "info" | undefined
}

export const getSplitterError = (error: string): SplitterError => {
  // Check if the error message starts with the specified text
  if (
    error.length &&
    error.startsWith("Validator could not be created because BatchRequest returned an empty batch_list")
  ) {
    return {
      errorMessage: VALIDATE_ERROR_EMPTY_BATCH_TEXT,
      alertLevel: "error",
    }
  }

  // Default case for other error messages
  // You can modify this part as needed
  return {
    errorMessage: VALIDATE_ERROR_TEXT,
    alertLevel: "error", // Assuming "error" as the default alert level
  }
}

type SplitterConfig = {
  splitterMethod: string
  dateFormat: string
  picker: "year" | "month" | undefined
  splitterOptionsString: string
}

export function getSplitterConfig(input?: string | undefined): SplitterConfig {
  //currently we are only supporting three splitter types
  // more will be added in the future
  switch (input) {
    case "SplitterYearAndMonthAndDay":
      // day picker doesn't require picker to be passed
      return {
        splitterMethod: "Year-Month-Day",
        dateFormat: "YYYY-MM-DD",
        picker: undefined,
        splitterOptionsString: "splitterYearAndMonthAndDayOptions",
      }
    case "SplitterYearAndMonth":
      return {
        splitterMethod: "Year-Month",
        dateFormat: "YYYY-MM",
        picker: "month",
        splitterOptionsString: "splitterYearAndMonthOptions",
      }
    case "SplitterYear":
      return {
        splitterMethod: "Year",
        dateFormat: "YYYY",
        picker: "year",
        splitterOptionsString: "splitterYearOptions",
      }
    default:
      // for all unsupported splitter types return the splitter type
      return { splitterMethod: input || "", dateFormat: "", picker: undefined, splitterOptionsString: "" }
  }
}

export function useSplitterConfig(typename: string): SplitterConfig | undefined {
  return useMemo(() => getSplitterConfig(typename), [typename])
}

export const BATCH_DEFINITION_TEXT =
  "Before adding your first expectation, define the batch interval for your asset. Validate your data incrementally " +
  "by selecting a time-based batch interval. Validate every record in your asset by selecting Entire table."

export function getAssetsWithDateTimeColumns(
  dataAssetWithLatestMetricRun: DataAssetWithLatestMetricRunFragment | undefined,
) {
  const filteredDataAssetWithLatestMetricRun = JSON.parse(JSON.stringify(dataAssetWithLatestMetricRun))
  filteredDataAssetWithLatestMetricRun.latestMetricRun.metrics =
    filteredDataAssetWithLatestMetricRun.latestMetricRun.metrics.filter((metric: { columnDataType: string }) =>
      metric.columnDataType?.match(isTimeBasedTypeRegex),
    )
  return filteredDataAssetWithLatestMetricRun
}

export type BatchDefinition = {
  column_name?: string
  method_name?: "split_on_year" | "split_on_year_and_month" | "split_on_year_and_month_and_day"
}

type TransformBatchDefinitionDataReturn = DateSplitterConfigInput | undefined

const methodNameMapping = {
  split_on_year: "SPLIT_ON_YEAR",
  split_on_year_and_month_and_day: "SPLIT_ON_YEAR_AND_MONTH_AND_DAY",
  split_on_year_and_month: "SPLIT_ON_YEAR_AND_MONTH",
}

export const transformBatchDefinitionData = (batchDefinitionData: {
  batchDefinition?: BatchDefinition
}): TransformBatchDefinitionDataReturn => {
  const batchDefinition = batchDefinitionData?.batchDefinition
  if (batchDefinition && batchDefinition?.column_name && batchDefinition?.method_name) {
    return {
      columnName: batchDefinition?.column_name,
      methodName: methodNameMapping[batchDefinition?.method_name] as DateSplitterMethodName,
    }
  }
  return undefined
}

export function getIsDateTimeColumn(metrics: Metric[] | null | undefined) {
  if (!Array.isArray(metrics)) {
    return false
  }
  return metrics.some((metric) => metric?.columnDataType && metric.columnDataType?.match(isTimeBasedTypeRegex))
}
