import { copyToClipboard } from "src/common/utils/clipboard"
import { AppModal, StyledCopyButton } from "src/ui/AppModal/AppModal"
import { CodeSnippetEditor } from "src/ui/CodeSnippetEditor/CodeSnippetEditor"
import { CodeSnippetAlert } from "src/ui/CodeSnippetEditor/CodeSnippetAlert"

type Props = {
  isVisible: boolean
  close: () => void
  snippet: string | undefined
}

export function NewCheckpointSnippetModal({ isVisible, close, snippet }: Props) {
  return (
    <AppModal
      open={isVisible}
      title="Create Checkpoint"
      width={750}
      onCancel={(e) => {
        e.stopPropagation()
        close()
      }}
      cancelText="Done"
      cancelButtonProps={{ type: "primary" }}
      okButtonProps={{ style: { display: "none" } }}
    >
      <ModalContent snippet={snippet} />
    </AppModal>
  )
}

function ModalContent({ snippet }: { snippet: string | undefined }) {
  return (
    <>
      <CodeSnippetAlert />
      <p>Edit configuration and run the following code snippet:</p>
      <CodeSnippetEditor
        value={!snippet ? "Checkpoint code snippet not found" : snippet}
        language="python"
        readOnly
        fontSize={12}
        showLineNumbers={false}
        height="250px"
      />
      <StyledCopyButton
        icon="clipboard"
        aria-label="Copy"
        disabled={!snippet}
        onClick={() => {
          if (snippet) {
            copyToClipboard(snippet)
          }
        }}
      >
        Copy snippet
      </StyledCopyButton>
    </>
  )
}
