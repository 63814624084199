export const EXPECTATIONS_QUERY_ERROR = "Error loading Expectations"
export const CREATE_EXPECTATION_ERROR = "Failed to add Expectation"
export const UPDATE_EXPECTATION_ERROR = "Failed to update Expectation"
export const CREATE_EXPECTATION_SUCCESS = "Expectation added"
export const CREATE_EXPECTATION_DRAWER_TITLE = "New Expectation"
export const EXPECTATION_PICKER_FILTER_PLACEHOLDER = "Data quality issue"
export const EXPECTATION_PICKER_SEARCH_PLACEHOLDER = "Search"
export const EXPECTATION_PICKER_EMPTY_STATE = "We were unable to find any Expectations related to your search."
export const EXPECTATION_SUITE_HELP_TEXT =
  "Expectation Suites contain multiple Expectations for a single Data Asset. Like Expectations, they can help you better understand your data and help you improve data quality."
