import { useMutation, useQuery } from "@apollo/client"
import { Flex, Skeleton, Switch, Tag, Tooltip, notification } from "antd"
import { useCallback, useEffect, useState } from "react"
import { Schedule } from "src/api/graphql/graphql"
import { ExpectationSuiteDocument, GetOrCreateScheduleMutation } from "src/api/graphql/graphql-operations"
import { NOTIFICATION_INFINITE_DURATION_SECONDS } from "src/common/config"
import {
  DEFAULT_SCHEDULE_FREQUENCY,
  generateCronWithCyclicHours,
  getHourlyIntervalFromCron,
  getNextHour,
  getNextRunFromCron,
} from "src/common/utils/cron"
import { formatLocalCalendarDateWithTimeAndTimeZone } from "src/common/utils/formatTime"
import { Button } from "src/ui/Button/Button"
import { Icon } from "src/ui/Icon"
import { theme } from "src/ui/themes/theme"
import { LabelRegular } from "src/ui/typography/Text"
import {
  SCHEDULE_PAUSE_ERROR_DETAILS,
  SCHEDULE_PAUSE_ERROR_MESSAGE,
  SCHEDULE_UNPAUSE_ERROR_DETAILS,
  SCHEDULE_UNPAUSE_ERROR_MESSAGE,
} from "src/DataAssets/AssetDetails/Expectations/words"
import { graphql } from "src/api/graphql"
import { ExpectationSuiteDrawer } from "src/expectationSuites/ExpectationSuite/ExpectationSuiteDrawer"
import { useRequireRole } from "src/common/hooks/useRequireRole"

export const GetOrCreateScheduleDocument = graphql(`
  mutation GetOrCreateSchedule($input: GetOrCreateScheduleInput!) {
    getOrCreateSchedule(input: $input) {
      schedule {
        id
        schedule
        isEnabled
        startTime
        sourceResources {
          entityId
          entityType
        }
      }
    }
  }
`)

export const PauseScheduleDocument = graphql(`
  mutation PauseSchedule($id: UUID!) {
    pauseSchedule(id: $id) {
      id
    }
  }
`)

export const UnpauseScheduleDocument = graphql(`
  mutation UnpauseSchedule($id: UUID!) {
    unpauseSchedule(id: $id) {
      id
    }
  }
`)

interface ScheduleSummaryProps {
  assetId: string
  expectationSuiteId: string
  canActivate: boolean
}

export function ScheduleSummary({ assetId, expectationSuiteId, canActivate }: ScheduleSummaryProps) {
  const [notificationApi, notificationContextHolder] = notification.useNotification()
  const [schedule, setSchedule] = useState<Schedule>()
  const [showDrawer, setShowDrawer] = useState(false)
  const [transitionClose, setTransitionClose] = useState(false)
  const [expectationCount, setExpectationCount] = useState<number | undefined>()

  const isEditorRole = useRequireRole("EDITOR")

  const { data: suiteData } = useQuery(ExpectationSuiteDocument, {
    variables: {
      id: expectationSuiteId,
    },
  })

  const suiteName = suiteData?.expectationSuiteV2?.name
  const hasExpectations = (suiteData?.expectationSuiteV2?.expectations?.length ?? 0) > 0

  const [getOrCreateScheduleMutation, { loading }] = useMutation(GetOrCreateScheduleDocument, {
    onCompleted: (data: GetOrCreateScheduleMutation) => {
      setSchedule(data.getOrCreateSchedule?.schedule)
    },
  })
  const [pauseScheduleMutation, { loading: pauseScheduleLoading }] = useMutation(PauseScheduleDocument, {
    onCompleted: () => {
      setSchedule({ ...schedule, isEnabled: false } as Schedule)
    },
    onError: () => {
      showPauseError()
    },
  })
  const [unpauseScheduleMutation, { loading: unpauseScheduleLoading }] = useMutation(UnpauseScheduleDocument, {
    onCompleted: () => {
      setSchedule({ ...schedule, isEnabled: true } as Schedule)
    },
    onError: () => {
      showUnpauseError()
    },
  })

  const fetchSchedule = useCallback(() => {
    // Generate the values for default schedule as top of the next hour
    const nextHour = getNextHour(new Date().getHours())
    const cronExpression = generateCronWithCyclicHours({ start: nextHour, freq: DEFAULT_SCHEDULE_FREQUENCY })

    return getOrCreateScheduleMutation({
      variables: {
        input: {
          sourceResources: [
            { entityId: assetId, entityType: "DataAsset" },
            { entityId: expectationSuiteId, entityType: "ExpectationSuite" },
          ],
          schedule: cronExpression,
          startTime: nextHour,
        },
      },
    })
  }, [assetId, expectationSuiteId, getOrCreateScheduleMutation])

  useEffect(() => {
    if (assetId && expectationSuiteId) {
      fetchSchedule()
    }
  }, [assetId, expectationSuiteId, fetchSchedule, getOrCreateScheduleMutation])

  const nextRunDateTime = schedule
    ? formatLocalCalendarDateWithTimeAndTimeZone(getNextRunFromCron(schedule.schedule).toISOString())
    : ""
  const hourlyInterval = schedule ? getHourlyIntervalFromCron(schedule.schedule) : ""
  const intervalDisplayString = `Every ${hourlyInterval} hour${hourlyInterval !== 1 ? "s" : ""}`

  const setIsEnabled = useCallback(
    (checked: boolean) => {
      if (!schedule) return
      if (checked) {
        unpauseScheduleMutation({
          variables: {
            id: schedule.id,
          },
        })
      } else {
        pauseScheduleMutation({
          variables: {
            id: schedule.id,
          },
        })
      }
      setSchedule({ ...schedule, isEnabled: checked })
    },
    [pauseScheduleMutation, schedule, unpauseScheduleMutation],
  )

  const showPauseError = useCallback(() => {
    notificationApi.error({
      message: SCHEDULE_PAUSE_ERROR_MESSAGE,
      description: SCHEDULE_PAUSE_ERROR_DETAILS,
      duration: NOTIFICATION_INFINITE_DURATION_SECONDS,
      placement: "top",
    })
  }, [notificationApi])

  const showUnpauseError = useCallback(() => {
    notificationApi.error({
      message: SCHEDULE_UNPAUSE_ERROR_MESSAGE,
      description: SCHEDULE_UNPAUSE_ERROR_DETAILS,
      duration: NOTIFICATION_INFINITE_DURATION_SECONDS,
      placement: "top",
    })
  }, [notificationApi])

  useEffect(() => {
    /**
     * Covers edge cases where creating an expectation triggers a refetch
     * of the ExpectationSuiteDocument query without also triggering
     * an invalidation of the associated schedule queries.
     *
     * We only want to capture instances where expectations are added,
     * and we don't want to assume that any time ScheduleSummary loads
     * that we should mark a schedule as enabled if it's disabled,
     * so we only check for an increase in expectation counts.
     */
    const incomingCount = suiteData?.expectationSuiteV2?.expectations.length

    if (incomingCount && !expectationCount) {
      setExpectationCount(incomingCount)
      // if we have added the first expectation, we should enable the schedule.
      if (expectationCount === 1) {
        setIsEnabled(true)
      }
    }

    if (incomingCount && expectationCount && incomingCount > expectationCount) {
      setExpectationCount(incomingCount)
    }
  }, [suiteData, expectationCount, setExpectationCount, setIsEnabled])

  useEffect(() => {
    /**
     * Opening & closing the drawer without unmounting or destroying the component
     * will cause its internal Form.useForm() hook to persist any prior form instance's
     * initialValues.
     *
     * This means that Data Assets with multiple suites will be stuck editing the
     * initialValues of the first expectation suite a user opens to edit.
     *
     * The dual boolean check of `showDrawer` and `transitionClosing` enables
     * us to leverage both the nice animations AntD provides for the drawer's open/close,
     * while still destroying the component and its internal state, thus preventing
     * unintended persistence of old & irrelevant form values.
     *
     * Without the dual check, the drawer opens nicely, but instantly disappears on close.
     * See the `isVisible` property on ExpectationSuiteDrawer below for the other half.
     */
    if (transitionClose) {
      setTimeout(() => {
        setShowDrawer(false)
        setTransitionClose(false)
      }, 500)
    }
  }, [transitionClose, setShowDrawer])

  return (
    <>
      {notificationContextHolder}
      <Flex
        justify="space-between"
        wrap="wrap"
        style={{
          border: `1px solid ${theme.colors.neutralColorPalette.backgroundsAndBorders.gxBorder}`,
          borderRadius: theme.spacing.cornerRadius.medium,
          padding: theme.spacing.scale.xxs,
          marginBottom: theme.spacing.horizontal.s,
          backgroundColor: theme.colors.neutralColorPalette.whites.white,
          minWidth: "250px",
        }}
      >
        <Skeleton loading={loading && !schedule?.id} paragraph={false} active={true}>
          {schedule?.id && (
            <>
              <Flex gap={theme.spacing.horizontal.xxs} align="center">
                <Tooltip title="Validation schedule">
                  <Icon small={true} name="calendar" disabled={!schedule.isEnabled} />
                </Tooltip>
                {schedule.isEnabled ? (
                  <LabelRegular>
                    Next run {nextRunDateTime} | {intervalDisplayString}
                  </LabelRegular>
                ) : (
                  <LabelRegular $gxPrimaryLight>{intervalDisplayString}</LabelRegular>
                )}
              </Flex>
              <Flex gap={theme.spacing.horizontal.xxs} align="center">
                {schedule.isEnabled ? (
                  <Tag color="green" style={{ cursor: "default" }}>
                    Active
                  </Tag>
                ) : (
                  <Tag style={{ cursor: "default" }}>Paused</Tag>
                )}
                {isEditorRole && (
                  <>
                    <Tooltip
                      title={
                        !canActivate ? "Add an Expectation to the Expectation Suite to activate the schedule." : null
                      }
                    >
                      <Switch
                        disabled={!canActivate}
                        checked={schedule.isEnabled}
                        checkedChildren="ON"
                        unCheckedChildren="OFF"
                        onChange={setIsEnabled}
                        loading={pauseScheduleLoading || unpauseScheduleLoading}
                        aria-label="Schedule On/Off Toggle"
                      />
                    </Tooltip>
                    <Button type="text" aria-label="Edit Schedule" icon="edit" onClick={() => setShowDrawer(true)} />
                  </>
                )}
              </Flex>
            </>
          )}
        </Skeleton>
      </Flex>
      {showDrawer && (
        <ExpectationSuiteDrawer
          editable={hasExpectations}
          schedule={schedule}
          suiteTitle={suiteName ?? ""}
          setIsEnabled={setIsEnabled}
          isVisible={showDrawer && !transitionClose}
          onClose={async () => {
            await fetchSchedule()
            setTransitionClose(true)
          }}
        />
      )}
    </>
  )
}
