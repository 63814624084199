import { Alert, List as AntDList, ListProps, Space } from "antd"
import styled, { css } from "styled-components"
import { ExpectationGroup } from "src/DataAssets/AssetDetails/Expectations/ExpectationGroup"
import { Expectation } from "src/Expectation"
import { ExpectationChangesGroupResult, ExpectationGroupResult } from "src/common/utils/groupExpectations"
import { FC } from "react"
import { InteractiveExpectationFragment } from "src/api/graphql/graphql-operations"
import { removeInvalidHtmlCharacters } from "src/common/utils/removeInvalidHtmlCharacters"
import { ExpectationChanges } from "src/api/graphql/graphql"
import { ViewModes } from "src/ui/Button/ViewToggle"
import { Button } from "src/ui/Button/Button"
import { EXPECTATION_CHANGE_LOG_LIMIT } from "src/common/config"
import { EXPECTATION_CHANGE_LOG_LIMIT_TEXT } from "src/Expectation/words"
import { useParams } from "react-router-dom"
const ListItem = AntDList.Item

const StyledSpace = styled(Space)`
  width: 100%;
`

const List = styled(AntDList)`
  scrollbar-width: none;
`

const StyledListItem = styled(ListItem)<{ $isExpectationDeleted?: boolean }>`
  && {
    padding-left: ${({ theme }) => `${theme.spacing.horizontal.s}`};
    padding-right: ${({ theme }) => `${theme.spacing.horizontal.s}`};
    ${({ $isExpectationDeleted, theme }) =>
      $isExpectationDeleted &&
      css`
        background-color: ${theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgPrimary};
      `}
  }
`

const StyledExpectationGroup = styled(ExpectationGroup)<{ $isSelected?: boolean }>`
  overflow: hidden;
  ${({ $isSelected, theme }) =>
    $isSelected &&
    css`
      outline: 2px solid ${theme.colors.primaryColors.gxPrimaryLight};
      border-radius: ${theme.spacing.cornerRadius.medium};
    `}
`

const StyledAlert = styled(Alert)`
  margin: ${({ theme }) => `${theme.spacing.vertical.xxs} 0 ${theme.spacing.horizontal.s}`};
`

interface ExpectationListProps {
  currentExpectationGroups: ExpectationGroupResult[]
  expectationGroupsWithChanges?: ExpectationChangesGroupResult[]
  selectedGroup?: string
  viewMode?: ViewModes
  changeLogLimit?: number
  removeChangeLogLimit?: () => void
  changeLogWithoutLatestChangesCount?: number
}

export function ExpectationList({
  currentExpectationGroups,
  expectationGroupsWithChanges,
  selectedGroup,
  viewMode = "currentView",
  changeLogLimit,
  removeChangeLogLimit,
  changeLogWithoutLatestChangesCount,
}: ExpectationListProps) {
  const { expectationSuiteId: expectationSuiteId = "" } = useParams<{
    expectationSuiteId?: string
  }>()
  const isChangeLog = viewMode === "changeLog"
  const allCurrentExpectations = currentExpectationGroups.flatMap((group) => group[1])

  const getLatestConfiguration = (expectationChangeConfig: ExpectationChanges) => {
    const currentConfig = allCurrentExpectations.find(
      // If the expectation hasn't been deleted then let's display the current configuration from the currentExpectations
      // otherwise expectations with sets will display incorrectly.
      (expectation) => expectation.expectationId === expectationChangeConfig.expectationId,
    )
    if (currentConfig) {
      return currentConfig
    }
    // If the expectation has been deleted then let's display the latest configuration from the expectationChangeConfig
    return expectationChangeConfig.changes[0].expectationConfiguration
  }

  return (
    <>
      {isChangeLog && changeLogLimit && changeLogWithoutLatestChangesCount === EXPECTATION_CHANGE_LOG_LIMIT && (
        <StyledAlert
          message={EXPECTATION_CHANGE_LOG_LIMIT_TEXT}
          type="info"
          action={
            <Space direction="vertical">
              <Button size="small" onClick={removeChangeLogLimit}>
                Show all changes
              </Button>
            </Space>
          }
        />
      )}
      <StyledSpace direction="vertical" size="large" role="list">
        {viewMode === "changeLog" && expectationGroupsWithChanges?.length
          ? expectationGroupsWithChanges.map(([groupName, expectationGroup]) => (
              <StyledExpectationGroup
                key={groupName}
                groupName={groupName}
                isGroupDeleted={expectationGroup[0].changes[0].action === "delete"} // Deleted Expectations are sorted to the bottom so if the Expectation change at the top is deleted, so is the entire group
                $isSelected={selectedGroup === removeInvalidHtmlCharacters(groupName.toLowerCase())}
              >
                <List<FC<ListProps<ExpectationChanges>>>
                  split
                  itemLayout="vertical"
                  dataSource={expectationGroup}
                  aria-label={`list-${groupName}`}
                  renderItem={(expectationConfig) => {
                    const latestConfiguration = getLatestConfiguration(expectationConfig)
                    const isDeleted = expectationConfig.changes[0].action === "delete" // Most recent change will be at index 0
                    return (
                      <StyledListItem key={expectationConfig?.expectationId} $isExpectationDeleted={isDeleted}>
                        <Expectation
                          expectationSuiteId={expectationSuiteId}
                          queriesToRefetchOnDelete={["expectationSuite"]}
                          expectation={latestConfiguration}
                          viewMode={viewMode}
                          expectationChangeHistory={expectationConfig}
                          isExpectationDeleted={isDeleted}
                        />
                      </StyledListItem>
                    )
                  }}
                />
              </StyledExpectationGroup>
            ))
          : currentExpectationGroups.map(([groupName, expectationGroup]) => (
              <StyledExpectationGroup
                key={groupName}
                groupName={groupName}
                $isSelected={selectedGroup === removeInvalidHtmlCharacters(groupName.toLowerCase())}
              >
                <List<FC<ListProps<InteractiveExpectationFragment>>>
                  split
                  itemLayout="vertical"
                  dataSource={expectationGroup}
                  aria-label={`list-${groupName}`}
                  renderItem={(expectationConfig) => (
                    <StyledListItem key={expectationConfig?.expectationId}>
                      <Expectation
                        queriesToRefetchOnDelete={["expectationSuite"]}
                        expectation={expectationConfig}
                        expectationSuiteId={expectationSuiteId}
                        viewMode={viewMode}
                      />
                    </StyledListItem>
                  )}
                />
              </StyledExpectationGroup>
            ))}
      </StyledSpace>
    </>
  )
}
