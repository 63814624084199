import { ReactNode } from "react"
import { Collapse, CollapsePanelProps } from "antd"
import styled, { css } from "styled-components"

import { BodySemiBold, CaptionRegular } from "src/ui/typography/Text/Text"

const { Panel } = Collapse

const StyledCaptionRegular = styled(CaptionRegular)`
  ${({ theme }) => css`
    color: ${theme.colors.neutralColorPalette.blacks.colorTextSecondary};
    line-height: 18px;
  `};
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const HeaderTitleContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing.scale.xxs};
  `};
`

export interface PanelHeaderProps {
  title: string | ReactNode
  subtitle?: string
  secondaryHeaderLine?: ReactNode
  image?: ReactNode
}

export const CollapsePanelHeader = ({ title, subtitle, secondaryHeaderLine, image }: PanelHeaderProps) => {
  return (
    <HeaderContainer>
      <HeaderTitleContainer>
        {!!image && image}
        <BodySemiBold>{title}</BodySemiBold>
        <StyledCaptionRegular>{subtitle}</StyledCaptionRegular>
      </HeaderTitleContainer>
      {secondaryHeaderLine && secondaryHeaderLine}
    </HeaderContainer>
  )
}

export const CollapsePanel = ({ key, header, children, ...props }: CollapsePanelProps) => {
  return (
    <Panel key={key} header={header} {...props}>
      {children}
    </Panel>
  )
}
