import * as Sentry from "@sentry/react"
import { NODE_ENV, REACT_APP_ENV as environment, REACT_APP_GIT_SHA } from "src/common/env"
import { shouldSuppressEvent } from "src/observability/sentry/utils"
import { browserTracingIntegration } from "@sentry/react"

const enabled = Boolean(environment && NODE_ENV === "production")

const beforeSend = (event: Sentry.ErrorEvent) => {
  if (shouldSuppressEvent(event)) {
    return null
  }
  return event
}

export const initializeSentry = () => {
  Sentry.init({
    dsn: "https://ce466bb01eb641f5b838615ed4682e28@sentry.greatexpectations.io/6767185",
    environment,
    release: REACT_APP_GIT_SHA,
    beforeSend,
    integrations: [browserTracingIntegration(), Sentry.replayIntegration({ maskAllText: false })],
    enabled,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.05,
    // The sample rate for replays that begin recording immediately and last the
    // entirety of the user's session. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs. This type of replay will record up to a minute
    // of events prior to the error and continue recording until the session ends.
    replaysOnErrorSampleRate: 0,
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === "ui.click" && hint) {
        const { target } = hint.event
        if (target.ariaLabel) {
          breadcrumb.message = target.ariaLabel
        }
      }
      return breadcrumb
    },
  })
}

export { Sentry }
