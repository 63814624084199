import { Descriptions } from "antd"
import { Preview } from "src/Expectation/Preview"
import { ExpectationRenderer } from "src/Expectation/ExpectationRenderer"
import { ExpectationInfoCard } from "src/ui/Card/Card"
import { useTheme } from "styled-components"

type ExpectationInformationProps = {
  title: string
  description?: string
  renderer?: ExpectationRenderer
  category?: string
}

export const ExpectationInformation = (props: ExpectationInformationProps) => {
  const theme = useTheme()

  const preview = props.renderer ? <Preview renderer={props.renderer} /> : null

  const infoCardBody =
    props.description && props.renderer ? (
      <Descriptions
        column={1}
        layout="vertical"
        labelStyle={{ color: theme.colors.neutralColorPalette.blacks.colorText }}
      >
        <Descriptions.Item label="Description">{props.description}</Descriptions.Item>
        <Descriptions.Item label="Current Expectation">{preview}</Descriptions.Item>
      </Descriptions>
    ) : (
      props.description || preview
    )

  return (
    <ExpectationInfoCard title={props.title} category={props.category}>
      {infoCardBody}
    </ExpectationInfoCard>
  )
}
