import { useState } from "react"
import { Button } from "src/ui/Button/Button"
import { ConditionalTooltip } from "src/ui/Tooltip/ConditionalTooltip"
import { AssetFlowIds, EditCheckpointDrawer } from "src/Checkpoints/EditCheckpointDrawer"
import { DISABLED_ALERT_BUTTON_TOOLTIP_TEXT } from "src/Checkpoints/words"
import { useAnalytics } from "src/analytics/useAnalytics"

export const AlertNotificationsButton = ({ assetFlowIds }: { assetFlowIds?: AssetFlowIds }) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const posthog = useAnalytics()
  const onClick = () => {
    posthog?.capture("alerts.button_clicked")
    setIsDrawerVisible(true)
  }

  return (
    <>
      <ConditionalTooltip
        key="alertsButton"
        showTooltip={isButtonDisabled}
        tooltipText={DISABLED_ALERT_BUTTON_TOOLTIP_TEXT}
      >
        <Button icon="notifications" iconSize="16px" onClick={onClick} disabled={isButtonDisabled}>
          Alerts
        </Button>
      </ConditionalTooltip>
      <EditCheckpointDrawer
        assetFlowIds={assetFlowIds}
        isVisible={isDrawerVisible}
        setIsVisible={setIsDrawerVisible}
        setIsDrawerButtonDisabled={setIsButtonDisabled}
        title="Alerts"
        width={400}
      />
    </>
  )
}
