import { ApolloError } from "@apollo/client"
import { Form, Select } from "antd"
import { CHECKPOINT_DROPDOWN_FIELD, EDIT_CHECKPOINT_NAME_TOOLTIP_TEXT } from "src/Checkpoints/words"
import { StyledTooltipIcon } from "src/ui/Tooltip/Tooltip"

export const CheckpointNameSelect = ({
  options,
  onError,
  initialValue,
  ...rest
}: {
  options?: { label?: string; value?: string }[]
  initialValue?: string
  onError: (error: ApolloError) => void
  onChange?: (value: string) => void
}) => {
  return (
    <Form.Item
      label="Checkpoint"
      name={CHECKPOINT_DROPDOWN_FIELD}
      initialValue={initialValue}
      rules={[{ required: true, message: "Checkpoint selection is required" }]}
      tooltip={{
        icon: <StyledTooltipIcon name="questionCircle" width="18px" />,
        title: EDIT_CHECKPOINT_NAME_TOOLTIP_TEXT,
      }}
    >
      <Select
        showSearch
        defaultValue={options?.length === 1 ? options[0].value : undefined}
        filterOption={(input, option) => {
          const foundOptionIndex = option?.label ? option?.label.toLowerCase().indexOf(input.toLowerCase()) : -1
          return foundOptionIndex >= 0
        }}
        style={{ minWidth: 268 }}
        placeholder="Select Checkpoint"
        options={options}
        {...rest}
      />
    </Form.Item>
  )
}
