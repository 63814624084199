import { Alert, List, Space, message } from "antd"
import { useMutation, useQuery } from "@apollo/client"

import { AppModal } from "src/ui/AppModal/AppModal"
import { Icon } from "src/ui/Icon"
import { BodyRegular, SubheaderSemiBold, SubtitleSemiBold } from "src/ui/typography/Text"
import {
  DeleteDataAssetDocument,
  DatasourcesWithRunsDocument,
  UseDataAssets_DataAssetFragment,
  GetCheckpointsForDataAssetDocument,
} from "src/api/graphql/graphql-operations"
import { theme } from "src/ui/themes/theme"
import { findIndex } from "lodash-es"
import { MESSAGE_DURATION_SECONDS } from "src/common/config"
import { nonNull } from "src/common/utils/nonNull"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { DeleteResourceModal } from "src/ui/DeleteResourceModal/DeleteResourceModal"

export const CannotDeleteDataAssetWarning = "Unable to delete Data Asset"

interface DeleteDataAssetModalProps {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
  dataAsset: UseDataAssets_DataAssetFragment
}

export function DeleteDataAssetModal({ isVisible, setIsVisible, dataAsset }: DeleteDataAssetModalProps) {
  const isSimplifyingCloudUIv0Enabled = useIsFeatureEnabled("simplifyingCloudUIv0Enabled")

  const [deleteDataAsset, { loading: mutationInProgress, error: deleteMutationError, reset: resetMutation }] =
    useMutation(DeleteDataAssetDocument, {})
  const { data, loading: relatedCheckpointsQueryInProgress } = useQuery(GetCheckpointsForDataAssetDocument, {
    skip: !isVisible || isSimplifyingCloudUIv0Enabled,
    variables: { id: dataAsset.id },
  })

  const checkpoints = data?.allCheckpointsByAssetRefIdAndOptionalExpectationSuiteId?.filter(nonNull) ?? []
  const loading = mutationInProgress || relatedCheckpointsQueryInProgress
  const noCheckpointsFound = !relatedCheckpointsQueryInProgress && !checkpoints.length

  const onDelete = () => {
    deleteDataAsset({
      variables: { id: dataAsset.id },
      onCompleted: () => {
        message.success(`Successfully deleted ${dataAsset.name} Data Asset`, MESSAGE_DURATION_SECONDS)
        setIsVisible(false)
      },
      onError: () => {},
      update(cache, result) {
        const updatedDataSource = result.data?.deleteAsset?.datasourceV2
        if (!updatedDataSource) {
          return
        }
        cache.updateQuery({ query: DatasourcesWithRunsDocument }, (data) => {
          const updatedDataSourceIndex = findIndex(data?.datasourcesV2 ?? [], ({ id }) => id === updatedDataSource.id)
          if (!data || !(updatedDataSourceIndex >= 0)) {
            return
          }

          return {
            ...data,
            datasources: [...data.datasourcesV2].splice(updatedDataSourceIndex, 1, updatedDataSource),
          }
        })
        cache.evict({ id: `AssetRef:${dataAsset.id}` })
      },
    })
  }
  const onReset = () => {
    setIsVisible(false)
    deleteMutationError && resetMutation()
  }

  return (
    <>
      {isSimplifyingCloudUIv0Enabled ? (
        <DeleteResourceModal
          open={isVisible}
          name={dataAsset.name}
          resourceTitle="Data Asset"
          alternateResourceTitle="Data Asset, its Expectations, and its validation results"
          onCancel={onReset}
          okButtonProps={{
            loading: mutationInProgress,
          }}
          onOk={onDelete}
          errorMessage={deleteMutationError?.message}
        />
      ) : (
        <AppModal
          open={isVisible}
          title={
            <Space>
              <Icon size={theme.spacing.scale.s} name="exclamationCircle" color={theme.colors.warning.colorWarning} />
              <SubtitleSemiBold>Confirm Deletion</SubtitleSemiBold>
            </Space>
          }
          width={550}
          okText="Delete"
          cancelText="Cancel"
          onCancel={onReset}
          okButtonProps={{
            danger: true,
            loading: mutationInProgress,
            disabled: !noCheckpointsFound || loading,
          }}
          onOk={() => {
            onDelete()
          }}
        >
          <Space direction="vertical">
            {deleteMutationError && (
              <Alert message="Error deleting Data Asset" type="error" description={deleteMutationError.message} />
            )}
            {relatedCheckpointsQueryInProgress || noCheckpointsFound ? null : (
              <Alert
                message={<SubheaderSemiBold>{CannotDeleteDataAssetWarning}</SubheaderSemiBold>}
                type="error"
                showIcon
                description={
                  <Space direction="vertical">
                    This Data Asset cannot be deleted because it is associated with the following Checkpoints:
                    <List
                      dataSource={checkpoints}
                      loading={loading}
                      renderItem={({ id, name }) => <List.Item key={id}>{name}</List.Item>}
                    />
                  </Space>
                }
              />
            )}
            <BodyRegular>
              Delete Data Asset <b>{dataAsset.name}</b>?
            </BodyRegular>
          </Space>
        </AppModal>
      )}
    </>
  )
}
