import styled, { css } from "styled-components"
import { Table as BaseTable } from "antd"
import { CollapsePanel } from "src/ui/Collapse/CollapsePanel"

export const StyledTable = styled(BaseTable)<{ $isDisabled: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.neutralColorPalette.backgroundsAndBorders.gxSurfaceSecondary};

  .ant-table {
    border-bottom-left-radius: ${({ theme }) => theme.spacing.cornerRadius.medium};
    border-bottom-right-radius: ${({ theme }) => theme.spacing.cornerRadius.medium};
  }

  tbody > tr {
    line-height: 24px;
  }

  tbody.ant-table-tbody > tr:last-child > td {
    border-bottom-color: transparent;
  }

  .ant-table-tbody > tr > td.ant-table-cell:first-child {
    padding-left: ${({ theme }) => theme.spacing.horizontal.xs};
  }

  thead > tr {
    th.ant-table-cell:first-child {
      padding-left: ${({ theme }) => theme.spacing.horizontal.xs};
      width: 10%;
    }

    th {
      color: ${({ theme }) => theme.colors.neutralColorPalette.blacks.colorText};
      font-size: ${({ theme }) => theme.typography.semibold.label.fontSize};
      line-height: ${({ theme }) => theme.typography.semibold.label.lineHeight};
      font-weight: ${({ theme }) => theme.typography.semibold.label.fontWeight};

      ::before {
        /* dont show vertical separator between column cells */
        display: none;
      }

      &:nth-child(2) {
        width: 45%;
      }

      &:nth-child(3) {
        width: 45%;
      }
    }
  }

  tr.selectable {
    cursor: pointer;

    td.ant-table-cell-row-hover {
      ${({ theme }) => `
      background: ${theme.colors.primaryColors.gxAccentBackground};
      `};
    }
  }

  tr.not-selectable {
    cursor: default;

    td.ant-table-cell-row-hover {
      background: inherit;
    }
  }

  ${({ $isDisabled, theme }) =>
    $isDisabled
      ? css`
          th,
          th.ant-table-column-has-sorters:hover {
            color: ${theme.colors.neutralColorPalette.blacks.colorTextSecondary};
            cursor: default;
            pointer-events: none;
            background-color: ${theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgPrimary};
          }

          td,
          tr.ant-table-row:hover > td {
            cursor: default;
            color: ${theme.colors.neutralColorPalette.blacks.colorTextSecondary};
            background-color: ${theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgSecondary};
          }
        `
      : css`
          th,
          th.ant-table-column-has-sorters:hover {
            pointer-events: inherit;
          }
        `}
`

export const StyledCollapsePanel = styled(CollapsePanel)`
  &&.ant-collapse-item {
    .ant-collapse-header-collapsible-only {
      flex-wrap: wrap;
    }
  }

  &&& > .ant-collapse-header {
    align-items: flex-start;

    .ant-collapse-header-text {
      min-width: 0;
    }
  }
`

export const StyledSecondaryContainer = styled.div`
  display: flex;
  height: 24px;
  margin-top: 8px;
  margin-bottom: 8px;
  align-items: flex-start;
  gap: 10px;
  min-width: 0;
`

export const StyledSelectedRuntimeLbl = styled.span`
  font-size: ${({ theme }) => theme.typography.regular.caption.fontSize};
  width: 100px;
  flex-shrink: 0;
`

export const StyledPanelContents = styled.div<{ $isDisabled: boolean }>`
  padding: 10px;
  ${({ $isDisabled, theme }) =>
    $isDisabled &&
    `
      background-color: ${theme.colors.neutralColorPalette.backgroundsAndBorders.gxBgPrimary};
    `}
`
