import { ApolloError } from "@apollo/client"

const APPLICATION_ERROR = "application error"

// corresponds to the DomainError type in mercury
interface DomainError {
  code?: string
  detail?: string
  source?: string
}

function getDomainErrors(err: ApolloError): DomainError[] {
  return err.graphQLErrors
    .filter((e) => e.message === APPLICATION_ERROR)
    .flatMap((e) => e.extensions.errors as DomainError[])
}

export { getDomainErrors }
