import { TokenForm } from "src/Tokens/TokenForm"
import { useCallback, useMemo, useState } from "react"
import { Alert, Form, ModalProps } from "antd"
import { useOrganizations } from "src/organizations/useOrganizations"
import { StyledMessage, StyledModalContents, StyledPath, StyledPathRow } from "src/Tokens/Tokens.styles"
import { Button } from "src/ui/Button/Button"
import {
  IssueOrganizationApiTokenMutation,
  IssueOrganizationApiTokenMutationVariables,
  IssueUserApiTokenMutation,
  IssueUserApiTokenMutationVariables,
} from "src/api/graphql/graphql-operations"
import { copyToClipboard } from "src/common/utils/clipboard"
import { AppModal } from "src/ui/AppModal/AppModal"
import { FeedbackMessageDoNotUse } from "src/ui/FeedbackMessage"
import { useCurrentUser } from "src/common/hooks/useCurrentUser"
import { FetchResult, MutationFunction } from "@apollo/client"

type IssueOrganizationApiTokenMutationFn = MutationFunction<
  IssueOrganizationApiTokenMutation,
  IssueOrganizationApiTokenMutationVariables
>

type IssueUserApiTokenMutationFn = MutationFunction<IssueUserApiTokenMutation, IssueUserApiTokenMutationVariables>

interface TokenModalProps {
  title: string
  isModalVisible: boolean
  setIsModalVisible: (value: boolean) => void
  mutation: IssueUserApiTokenMutationFn | IssueOrganizationApiTokenMutationFn
}

interface FooterConfig {
  cancelButtonProps?: ModalProps["cancelButtonProps"]
  modalButtonText?: string
  modalButtonOnClick?: () => Promise<void> | null
  loading: boolean
}

const erroredTokenField = () => (
  <StyledModalContents>
    <FeedbackMessageDoNotUse
      title="Error"
      type="error"
      description="An error occurred when trying to generate the token. Try again later."
    />
  </StyledModalContents>
)

export const TokenModal = ({ title, isModalVisible, setIsModalVisible, mutation }: TokenModalProps) => {
  const { currentOrg } = useOrganizations()
  const { user } = useCurrentUser()
  const [form] = Form.useForm()
  const [contentConfig, setContentConfig] = useState(<TokenForm form={form} />)

  const generatedTokenField = useCallback((token: string) => {
    return (
      <StyledModalContents>
        <Alert
          message={<StyledMessage>Copy and store the token securely.</StyledMessage>}
          description="Do not share with unauthorized users or anyone outside your organization. Generate a new token if it is compromised."
          type="warning"
          showIcon
        />
        <Form layout="horizontal">
          <Form.Item label="Token">
            <StyledPathRow>
              <StyledPath value={token} />
              <Button
                icon="clipboard"
                aria-label="Copy"
                title="Copy Token"
                onClick={() => copyToClipboard(token ?? "")}
              />
            </StyledPathRow>
          </Form.Item>
        </Form>
      </StyledModalContents>
    )
  }, [])

  const closeModal = useCallback(async () => {
    setIsModalVisible(false)
    form.resetFields()
  }, [form, setIsModalVisible])

  const setCloseFooterState = useCallback(() => {
    setFooterState({
      modalButtonText: "Close",
      modalButtonOnClick: closeModal,
      cancelButtonProps: { style: { display: "none" } },
      loading: false,
    })
  }, [closeModal])

  const onCreateToken = useCallback(async () => {
    const values = await form.validateFields().catch(() => undefined)
    if (values === null || values === undefined) {
      return undefined
    }

    setFooterState({
      modalButtonText: "Generating",
      loading: true,
    })

    // temp, should go away when CLOUD-1938 is implemented
    const userInfo = { userSub: user?.sub, organizationName: currentOrg?.name }

    const isUserMutation = title.toLowerCase().includes("user")

    await mutation({
      variables: {
        input: {
          ...(isUserMutation && userInfo),
          organizationRole: "EDITOR",
          name: values.tokenName,
        },
      },
    })
      .then((response) => {
        if (response.errors) {
          setContentConfig(erroredTokenField())
          setCloseFooterState()

          console.error(`Error generating token:\n${response.errors}`)
        } else {
          if (response.data === null || response.data === undefined) {
            return
          }
          const data:
            | FetchResult<IssueUserApiTokenMutation>["data"]
            | FetchResult<IssueOrganizationApiTokenMutation>["data"] = response.data

          let token = ""
          if ((data as FetchResult<IssueUserApiTokenMutation>["data"])?.issueUserApiToken?.token) {
            token = (data as FetchResult<IssueUserApiTokenMutation>["data"])?.issueUserApiToken?.token ?? ""
          }
          if ((data as FetchResult<IssueOrganizationApiTokenMutation>["data"])?.issueOrganizationApiToken?.token) {
            token =
              (data as FetchResult<IssueOrganizationApiTokenMutation>["data"])?.issueOrganizationApiToken?.token ?? ""
          }

          if (token) {
            setContentConfig(generatedTokenField(token))
          }
          setCloseFooterState()
        }
      })
      .catch((e: { message: string }) => {
        setContentConfig(erroredTokenField())
        setCloseFooterState()

        console.error(`Error generating token:\n${e.message}`)
      })
  }, [form, title, user?.sub, currentOrg?.name, mutation, setCloseFooterState, generatedTokenField])

  const initialFooterState: FooterConfig = useMemo(() => {
    return {
      modalButtonText: "Create",
      loading: false,
      modalButtonOnClick: onCreateToken,
      cancelButtonProps: {},
    }
  }, [onCreateToken])

  const [footerState, setFooterState] = useState(initialFooterState)

  const reset = () => {
    setContentConfig(<TokenForm form={form} />)
    setFooterState(initialFooterState)
  }

  return (
    <AppModal
      open={isModalVisible}
      title={title}
      afterClose={reset}
      onCancel={() => setIsModalVisible(false)}
      cancelButtonProps={footerState.cancelButtonProps ?? {}}
      onOk={footerState.modalButtonOnClick}
      okText={footerState.modalButtonText}
      okButtonProps={{ loading: footerState.loading }}
    >
      {contentConfig}
    </AppModal>
  )
}
