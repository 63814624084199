import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"

import { useGetBatchDefinition, useSplitterConfig } from "src/DataAssets/AssetDetails/Splitters/splitterUtils"
import { UseGetSplitterDataDocument } from "src/api/graphql/graphql-operations"
import { SplitterUnion } from "src/api/graphql/graphql"
const supportedSplitterTypes = ["SplitterYearAndMonthAndDay", "SplitterYearAndMonth", "SplitterYear"]
export type SplitterType = SplitterUnion["__typename"]

export function useGetSplitterData({ isVisible }: { isVisible: boolean }) {
  const { assetId: _assetId = "" } = useParams<{
    assetId: string
    expectationSuiteId?: string
  }>()

  const assetId = _assetId ?? null
  const { data: dataAsset } = useQuery(UseGetSplitterDataDocument, {
    variables: { id: assetId },
    skip: !assetId || !isVisible,
  })

  const splitterData = dataAsset?.dataAsset?.splitter
  const splitterType = splitterData?.["__typename"] as SplitterType
  const splitter = useGetBatchDefinition(splitterType)
  const { splitterMethod, dateFormat, picker, splitterOptionsString } = useSplitterConfig(splitterType) ?? {}

  const isSplitterSupported = splitterMethod ? supportedSplitterTypes.includes(splitterType ?? "") : false

  const isMulticolumnSplitter = splitterData?.["__typename"] === "SplitterMultiColumnValue"

  const splitterColumns = isMulticolumnSplitter ? splitterData?.columnNames.join(", ") : splitterData?.columnName

  return {
    splitter,
    splitterColumns,
    isSplitterSupported,
    dateFormat,
    picker,
    splitterMethod,
    splitterOptionsString,
    isMulticolumnSplitter,
  }
}
