import { CheckpointFragment, CheckpointsDocument } from "src/api/graphql/graphql-operations"
import { useQueryParams } from "src/common/hooks/useQueryParams"
import { useQuery } from "@apollo/client"
import { sortRunsByDate } from "src/common/utils/sortByAscDate"

type WithSortKey<T> = T & { sortKey: number }
type SortableCheckpoint = WithSortKey<CheckpointFragment>

export interface UseCheckpointsResult {
  checkpoints: CheckpointFragment[]
  loading: boolean
  error: boolean
  zeroBeforeFilter: boolean
  zeroAfterFilter: boolean
}

// useCheckpoints performs a gql query and prepares the response data
// to be consumed by the list renderer
export function useCheckpoints(): UseCheckpointsResult {
  const {
    loading: queryLoading,
    data,
    error: queryError,
  } = useQuery(CheckpointsDocument, {
    fetchPolicy: "cache-and-network",
  })
  const queryParams: URLSearchParams = useQueryParams()
  const selectedCheckpoints = queryParams.getAll("checkpoint")
  const selectedDataAssets = queryParams.getAll("dataAsset")
  const selectedExpectationSuites = queryParams.getAll("expectationSuite")
  const failuresOnly = queryParams.get("failures") === "true"
  const filterByAssetsOrSuites = selectedDataAssets.length > 0 || selectedExpectationSuites.length > 0

  const checkpoints: SortableCheckpoint[] = (data?.checkpoints ?? [])
    .reduce((acc: SortableCheckpoint[], checkpoint) => {
      if (!checkpoint) {
        return acc
      }

      const checkpointIsIncluded = selectedCheckpoints.includes(checkpoint.id)

      if (selectedCheckpoints.length > 0 && !checkpointIsIncluded) {
        return acc
      }

      // For each checkpoint, filter the Data Assets and Expectation Suites and only return a Validation
      // result if it matches both the Data Asset and Expectation Suite selected (if applicable).
      const filteredValidations = checkpoint.validations.filter((validation) => {
        const isDataAssetMatch =
          !selectedDataAssets.length ||
          (validation.dataAsset && selectedDataAssets.includes(validation.dataAsset.id)) ||
          (validation.dataAsset === null && selectedDataAssets.includes("none"))
        const isExpectSuiteMatch =
          !selectedExpectationSuites.length ||
          (validation.expectationSuiteV2 && selectedExpectationSuites.includes(validation.expectationSuiteV2.id))
        return isDataAssetMatch && isExpectSuiteMatch
      })

      // Remove Checkpoints that don't have any validations that match the filter criteria when filtering by Assets or Suites
      if (filteredValidations.length === 0 && filterByAssetsOrSuites) {
        return acc
      }

      const filteredRuns = checkpoint.runs
        .filter((run) => {
          if (!failuresOnly) {
            return true
          }
          return run.results.some(({ success }) => success === false)
        })
        .sort(sortRunsByDate("desc"))

      const filteredCheckpoint = {
        ...checkpoint,
        validations: filteredValidations,
        runs: filteredRuns,
        sortKey: filteredRuns.length ? Date.parse(filteredRuns[0].ranAt) : 0,
      }

      return acc.concat(filteredCheckpoint)
    }, [])
    .filter((checkpoint) => (failuresOnly ? checkpoint.runs.length > 0 : true)) // When failuresOnly is true, remove checkpoints that don't have any failed runs
    .sort((a, b) => b.sortKey - a.sortKey) // Sort checkpoints by most recent run

  const error = queryError !== undefined
  const loading = queryLoading && !data && !error
  const zeroBeforeFilter = data?.checkpoints?.length === 0
  const zeroAfterFilter = checkpoints.length === 0

  return { checkpoints, loading, error, zeroBeforeFilter, zeroAfterFilter }
}
