import { Drawer } from "src/ui/Drawer/Drawer"
import { Alert, Skeleton } from "antd"
import { EditDataAssetForm } from "src/DataAssets/EditDataAssetForm"
import { DataSourceCrudDrawerStyles } from "src/DataAssets/connect-to-data/NewAssetDrawer"
import { useQuery } from "@apollo/client"
import { GetDataAssetWithConfigDocument } from "src/api/graphql/graphql-operations"
import {
  DataAssetConfigData,
  getJSONSchema,
  getUISchema,
} from "src/DataAssets/connect-to-data/schemas/data-asset-schemas"
import { SupportedDataSource } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"

type Props = {
  isOpen: boolean
  onClose: () => void
  afterClose: () => void
  assetId?: string
  dataSourceType?: SupportedDataSource
}

export function EditDataAssetDrawer({ isOpen, onClose, assetId, dataSourceType, afterClose }: Props) {
  const {
    loading,
    data,
    error: queryError,
  } = useQuery(GetDataAssetWithConfigDocument, {
    variables: { id: assetId ?? "" }, // this coalesce to empty string doesn't feel great but should be impossible; alternative is to call a lazy query in a useEffect which also feels weird
    skip: !assetId,
  })

  const config: DataAssetConfigData | null = parseConfig(data?.dataAsset?.config)
  const assetType = config?.["type"]

  const jsonSchema = assetType && dataSourceType && getJSONSchema(dataSourceType, assetType)
  const uiSchema = assetType && dataSourceType && getUISchema(dataSourceType, assetType)
  const error = (queryError ?? (jsonSchema && uiSchema)) ? null : "Invalid Data Asset configuration"

  return (
    <Drawer
      styles={DataSourceCrudDrawerStyles}
      size="large"
      open={isOpen}
      title="Edit Asset"
      onClose={onClose}
      afterOpenChange={(open) => {
        if (!open) {
          afterClose()
        }
      }}
    >
      <Skeleton style={{ padding: "24px" }} active loading={loading} paragraph={{ rows: 5 }} />
      {!loading && error && <Alert message={error} />}
      {!loading && !error && config && jsonSchema && uiSchema && data?.dataAsset && dataSourceType && (
        <EditDataAssetForm
          schema={jsonSchema}
          uiSchema={uiSchema}
          data={config}
          dataAsset={data.dataAsset}
          dataSourceType={dataSourceType}
          onClose={onClose}
        />
      )}
    </Drawer>
  )
}

function parseConfig(jsonString?: string | null) {
  if (!jsonString) {
    return null
  }
  try {
    return JSON.parse(jsonString) as DataAssetConfigData
  } catch (e) {
    return null
  }
}
