import { Empty } from "antd"
import { ChangeEvent, PropsWithChildren, useState } from "react"
import {
  DrawerPage,
  SelectedExpectation,
  useCreateExpectationDrawerContext,
} from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawerContext"
import { FeedbackMessageDoNotUse } from "src/ui/FeedbackMessage"
import {
  EXPECTATION_PICKER_EMPTY_STATE,
  EXPECTATIONS_QUERY_ERROR,
} from "src/expectationSuites/ExpectationSuiteDetails/CreateExpectationDrawer/words"
import { getFilteredExpectationsCardsData } from "src/Expectation/CreateExpectationDrawer/utils"
import { FilterExpectations } from "src/Expectation/CreateExpectationDrawer/FilterExpectations"
import { SearchExpectations } from "src/Expectation/CreateExpectationDrawer/SearchExpectations"
import { PickerHeaderWithFilterOptions } from "src/Expectation/CreateExpectationDrawer/PickerHeaderWithFilterOptions"
import { RadioCardGroup } from "src/ui/Radio/RadioCardGroup"
import { useAnalytics } from "src/analytics/useAnalytics"

interface DrawerContentProps {
  onSelectExpectation: (selectedExpectation: SelectedExpectation) => void
}

function ExpectationsQueryErrorHandler(props: PropsWithChildren<{ error: boolean; title: string }>): JSX.Element {
  if (props.error) {
    return <FeedbackMessageDoNotUse title={props.title} type="error" />
  }
  return <>{props.children}</>
}

function ExpectationPicker({ onSelectExpectation }: DrawerContentProps) {
  const { pageNumber, suiteId, suiteName, selectedExpectation } = useCreateExpectationDrawerContext()

  const posthog = useAnalytics()

  const handleExpectationClick = (clickedExpectationValue: string) => {
    const newSelectedExpectation = filteredExpectationsCardsData.find((filteredOption) => {
      return filteredOption.value === clickedExpectationValue
    })
    if (newSelectedExpectation) {
      onSelectExpectation(newSelectedExpectation)
      posthog?.capture("expectation.selected", {
        expectationType: newSelectedExpectation.title,
        suiteId: suiteId,
        suiteName: suiteName,
        categories: [newSelectedExpectation.category],
        searchTerms: searchInput,
        filtersApplied: [selectedCategoryFilter],
        selectionPosition: filteredExpectationsCardsData.indexOf(newSelectedExpectation),
      })
    }
  }

  const [searchInput, setSearchInput] = useState<string>("")
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState<string | undefined>(undefined)

  if (pageNumber !== DrawerPage.ExpectationPicker) return null

  const filteredExpectationsCardsData = getFilteredExpectationsCardsData(
    handleExpectationClick,
    searchInput,
    selectedCategoryFilter,
  )

  return (
    <PickerHeaderWithFilterOptions
      searchOptions={
        <SearchExpectations
          value={searchInput}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchInput(e.target.value)
          }}
        />
      }
      filterOptions={
        <FilterExpectations
          value={selectedCategoryFilter}
          onSelect={(value: string) => {
            setSelectedCategoryFilter(value)
          }}
        />
      }
    >
      <ExpectationsQueryErrorHandler title={EXPECTATIONS_QUERY_ERROR} error={false}>
        <RadioCardGroup
          value={selectedExpectation.value}
          cards={filteredExpectationsCardsData}
          emptyState={{
            image: Empty.PRESENTED_IMAGE_SIMPLE,
            description: EXPECTATION_PICKER_EMPTY_STATE,
          }}
        />
      </ExpectationsQueryErrorHandler>
    </PickerHeaderWithFilterOptions>
  )
}

export { ExpectationPicker }
