import styled from "styled-components"
import { CaptionRegular } from "src/ui/typography/Text/Text"

export const ListItemSubtitle = styled(CaptionRegular)`
  display: inline-block;
`

export const InfoDivider = styled(CaptionRegular)`
  display: inline-block;
  margin: ${({ theme }) => {
    return `${theme.spacing.horizontal.xxxs} ${theme.spacing.horizontal.xxs} 0 ${theme.spacing.horizontal.xxs}`
  }};
`

export const AlignCenter = styled.div`
  display: flex;
  align-items: center;
`
