import { useState } from "react"
import { StringValueType } from "src/api/graphql/graphql"
import { Button } from "src/ui/Button/Button"
import styled, { css } from "styled-components"
import { Tag } from "src/ui/Tag"
import { ParamHistory, ParamTypes } from "src/Expectation/Expectation"
import { addDynamicStyledText } from "src/Expectation/utils/addDynamicStyledText"
import { formatNumber } from "src/common/utils/formatNumber"
import { Param, UpdatedParam } from "src/ui/Param/Param"

const StyledExpectation = styled.div<{ $isExpectationDeleted?: boolean; $isDeletionAction?: boolean }>`
  ${({ $isDeletionAction }) => css`
    ${$isDeletionAction &&
    css`
      text-decoration: line-through;
    `}
  `}
`

const renderDynamicTextTag = (
  evrConfig?: { danger?: boolean },
  isExpectationDeleted?: boolean,
  isDeletionAction?: boolean,
) =>
  function RenderDynamicTextTag(value: string | number | string[], id?: string, history?: ParamHistory) {
    let newValue = value
    if (typeof value === "number") {
      newValue = formatNumber(value)
    }
    if (evrConfig?.danger !== undefined) {
      return (
        <Tag key={id} $danger={evrConfig.danger}>
          {newValue}
        </Tag>
      )
    }
    if (history || isExpectationDeleted || isDeletionAction) {
      return (
        <UpdatedParam
          key={id}
          $isParamDeleted={history?.removed}
          $isParamAdded={history?.added}
          $isOnDarkBackground={isExpectationDeleted}
          $isDeletionAction={isDeletionAction}
        >
          {newValue}
        </UpdatedParam>
      )
    }
    return <Param key={id}>{newValue}</Param>
  }
interface StringRenderComponentProps {
  value: StringValueType
  evrConfig?: { danger?: boolean }
  isExpectationDeleted?: boolean
  isDeletionAction?: boolean
}

const SeeAllButton = styled(Button)`
  display: inline-flex;
`

function formatStringNumber(input: string): boolean {
  if (isNaN(Number(input)) || input.length > 16) {
    return true
  }
  return false
}

function StringRenderComponent({
  value: { template, params: stringParams },
  evrConfig,
  isExpectationDeleted,
  isDeletionAction,
}: StringRenderComponentProps) {
  if (typeof stringParams !== "string") {
    throw new Error("type error: unable to parse stringParams. expected string, got " + typeof stringParams)
  }
  const params = JSON.parse(stringParams) as ParamTypes
  const [truncate, setTruncate] = useState<boolean>(
    Object.keys(params).length > 10 || (params.column_list?.value?.length ?? 0) > 7,
  )

  if (!formatStringNumber(String(template))) {
    template = formatNumber(String(template))
  }

  function getRenderedString() {
    if (template) {
      return addDynamicStyledText({
        template: template,
        params,
        getDynamicStyleTag: renderDynamicTextTag(evrConfig, isExpectationDeleted, isDeletionAction),
        truncate,
        isExpectationDeleted,
        isDeletionAction,
      })
    }
  }

  if (!template) return null
  const renderedString = getRenderedString()

  return (
    <StyledExpectation aria-label="Expectation summary" $isDeletionAction={isDeletionAction}>
      {renderedString}
      {truncate && (
        <SeeAllButton size="small" type="text" onClick={() => setTruncate(false)}>
          See all
        </SeeAllButton>
      )}
    </StyledExpectation>
  )
}

export { StringRenderComponent }
