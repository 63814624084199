import { JsonFormsUISchemaRegistryEntry, JsonSchema, RuleEffect } from "@jsonforms/core"
import type { UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { theme } from "src/ui/themes/theme"
import { SnowflakeDataSourceJsonSchema } from "src/DataAssets/connect-to-data/schemas/json-schemas/snowflake-data-source-json-schema"
import { Icon } from "src/ui/Icon/Icon"
import { getSchemaAndDatabaseFromSnowflakeConnectionString } from "src/DataAssets/connect-to-data/select-table-names/get-address-from-data-source"

export const SnowflakeDataSourcePage1UISchema: UISchema<typeof SnowflakeDataSourceJsonSchema> = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/type",
      rule: {
        effect: RuleEffect.HIDE,
        condition: {},
      },
    },
    {
      type: "Control",
      scope: "#/properties/name",
      label: "Data Source name",
    },
    {
      type: "Group",
      elements: [
        {
          type: "Control",
          label: {
            text: "Connection details",
            type: "Text",
            textProps: { style: { ...theme.typography.semibold.body } },
          },
          scope: "#/properties/connection_string",
          options: {
            optionType: "segmented",
            subschemaTitleToLabelMap: {
              ConnectionDetails: "Input parameters",
              "Connection String": "Connection string",
            },
          },
        },
      ],
    },
  ],
}

export const SnowflakeDataSourcePage2UISchema: UISchema<typeof SnowflakeDataSourceJsonSchema> = {
  type: "VerticalLayout",
  elements: [
    {
      label: "Data Asset",
      type: "Control",
      scope: "#/properties/assets",
      options: {
        removeButtonProps: {
          type: "text",
          icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
          children: "",
        },
      },
    },
  ],
}

const connectionStringUISchema: UISchema<(typeof SnowflakeDataSourceJsonSchema.properties.connection_string.oneOf)[1]> =
  {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#",
        label: "Connection string",
        options: {
          type: "password",
          required: true,
          placeholderText: `snowflake://USER:PASSWORD@ACCOUNT_IDENTIFIER/DATABASE/SCHEMA?warehouse=WAREHOUSE&role=ROLE`,
        },
        formItemProps: {
          rules: [
            {
              validator(rule, value: unknown) {
                if (typeof value !== "string") {
                  return Promise.reject(new Error("Please enter a valid connection string"))
                }
                const { schema, database } = getSchemaAndDatabaseFromSnowflakeConnectionString(value) ?? {}
                if (!schema) {
                  return Promise.reject(new Error("Snowflake connection string must specify a schema"))
                }
                if (!database) {
                  return Promise.reject(new Error("Snowflake connection string must specify a database"))
                }
                return Promise.resolve()
              },
            },
          ],
          tooltip: {
            title: (
              <span>
                See{" "}
                <a
                  href="https://docs.greatexpectations.io/docs/oss/guides/connecting_to_your_data/fluent/database/connect_sql_source_data?sql-database-type=snowflake#determine-your-connection-string"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: `${theme.colors.neutralColorPalette.whites.white}`, textDecoration: "underline" }}
                >
                  GX Snowflake documentation
                </a>{" "}
                for connection strings format.
              </span>
            ),
          },
        },
      },
    ],
  }

export const ConnectionStringUISchemaRegistryEntry: JsonFormsUISchemaRegistryEntry = {
  uischema: connectionStringUISchema,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  tester: (schema: JsonSchema, schemaPath: string, path: string) => {
    const rank = schema.title === "Connection String" && schema.type === "string" ? 2 : -1
    // console.log("ConnectionString Tester", { rank, schema, schemaPath, path })
    return rank
  },
}

const connectionDetailsUISchema: UISchema<typeof SnowflakeDataSourceJsonSchema.definitions.ConnectionDetails> = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/account",
      label: "Account identifier",
      options: {
        placeholderText: "orgname-accountname or accountname.region",
        rules: [],
      },
      formItemProps: {
        tooltip: {
          title: (
            <span>
              Find your account identifier in{" "}
              <a
                href="https://docs.snowflake.com/en/user-guide/admin-account-identifier"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: `${theme.colors.neutralColorPalette.whites.white}`, textDecoration: "underline" }}
              >
                Snowflake documentation.
              </a>
            </span>
          ),
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/user",
      label: "Username",
    },
    {
      type: "Control",
      scope: "#/properties/password",
      label: "Password",
      options: { type: "password" },
    },
    wrapInHorizontalLayout<typeof SnowflakeDataSourceJsonSchema.definitions.ConnectionDetails>(
      {
        type: "Control",
        scope: "#/properties/database",
        label: "Database",
        formItemProps: {
          tooltip: "Database name in Snowflake where the schema is located.",
        },
      },
      {
        type: "Control",
        scope: "#/properties/schema",
        label: "Schema",
        formItemProps: {
          tooltip: "Schema name within the Snowflake database. The assets you want to test will come from this schema.",
        },
      },
    ),
    wrapInHorizontalLayout<typeof SnowflakeDataSourceJsonSchema.definitions.ConnectionDetails>(
      {
        type: "Control",
        scope: "#/properties/warehouse",
        label: "Warehouse",
      },
      {
        type: "Control",
        scope: "#/properties/role",
        label: "Role",
      },
    ),
  ],
}

export const ConnectionDetailsUISchemaRegistryEntry: JsonFormsUISchemaRegistryEntry = {
  uischema: connectionDetailsUISchema,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  tester: (schema: JsonSchema, schemaPath: string, path: string) => {
    const rank = schema.title === "ConnectionDetails" && schema.type === "object" ? 2 : -1
    // console.log("TableAssetUISchema Tester", { rank, schema, schemaPath, path })
    return rank
  },
}

function wrapInHorizontalLayout<T>(...elements: UISchema<T>[]) {
  return {
    type: "HorizontalLayout",
    elements,
  }
}
