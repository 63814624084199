import { cloneDeep, get } from "lodash-es"
import { ColumnExpectationFormData, ExpectationFormData } from "src/schemas/expectation-catalog-types"
import { DataAssetWithLatestMetricRunFragment, Metric, MetricValueUnion } from "src/api/graphql/graphql"
import {
  DataAssetWithLatestMetricRunConfig,
  ExpectationJsonSchema,
} from "src/Expectation/uiForms/ExpectationConfigForm"
import { JSONFormData } from "@great-expectations/jsonforms-antd-renderers"
import { jsonSchemas } from "src/schemas/expectation-catalog-schemas"

const isColumnExpectationFormData = (formData?: ExpectationFormData): formData is ColumnExpectationFormData => {
  return formData !== undefined && "column" in formData
}

export const getJsonSchemaWithCDMValues = (
  jsonSchema: ExpectationJsonSchema,
  config?: DataAssetWithLatestMetricRunConfig,
) => {
  if (!config) return jsonSchema

  const metricRun = getMetricRunFromConfig(config)
  if (!metricRun) return jsonSchema

  const clonedJsonSchema = cloneDeep(jsonSchema)

  switch (clonedJsonSchema.title) {
    case "Expect table row count to be between":
      // default doesn't exist on schemas (we are adding it now)
      ;(clonedJsonSchema.properties.min_value as Record<string, unknown>).default = metricRun?.rowCount
      ;(clonedJsonSchema.properties.max_value as Record<string, unknown>).default = metricRun?.rowCount
      break
    case "Expect table row count to equal":
      ;(clonedJsonSchema.properties.value as Record<string, unknown>).default = metricRun?.rowCount
      break
    case "Expect column values to be in type list":
      ;(clonedJsonSchema.properties.type_list as Record<string, unknown>).default = metricRun?.metrics.map(
        (metric: Metric) => metric.columnDataType,
      )
      break
  }
  return clonedJsonSchema
}

const getMetricRunFromConfig = (config?: Record<string, unknown>) => {
  return (config?.dataAssetWithLatestMetricRun as DataAssetWithLatestMetricRunFragment)?.latestMetricRun
}

const getMetricFromConfig = (config?: Record<string, unknown>, columnName?: string) => {
  return getMetricRunFromConfig(config)?.metrics.find((metric: Metric) => metric.columnName === columnName)
}

export const getDataWithCDMValues = (
  expectationClass: string,
  data: ColumnExpectationFormData,
  config?: DataAssetWithLatestMetricRunConfig,
) => {
  if (!config) return data

  const metricRun = getMetricRunFromConfig(config)
  if (!metricRun) return data

  const metric = getMetricFromConfig(config, data.column)
  if (!metric) return data

  // cloneDeep is required to trigger a re-render on JsonForms
  // see: https://github.com/rjsf-team/react-jsonschema-form/issues/517#issuecomment-958421395
  // const updatedData = cloneDeep(data)

  if (metric && data) {
    switch (expectationClass) {
      case "ExpectColumnMeanToBeBetween": {
        const updatedData = cloneDeep(data as JSONFormData<typeof jsonSchemas.expectColumnMeanToBeBetween.schema>)
        if (metric?.mean) {
          updatedData.min_value = metric.mean
          updatedData.max_value = metric.mean
        }
        return updatedData
      }
      case "ExpectColumnMedianToBeBetween": {
        const updatedData = cloneDeep(data as JSONFormData<typeof jsonSchemas.expectColumnMedianToBeBetween.schema>)
        if (metric?.median) {
          updatedData.min_value = metric.median
          updatedData.max_value = metric.median
        }
        return updatedData
      }
      case "ExpectColumnMinToBeBetween": {
        const minMetricValue = getMetricValue(metric?.valueRangeMin, metric?.valueRangeMinUnion as MetricValueUnion)

        const updatedData = cloneDeep(data as JSONFormData<typeof jsonSchemas.expectColumnMinToBeBetween.schema>)
        if (minMetricValue) {
          updatedData.min_value = minMetricValue
          updatedData.max_value = minMetricValue
        }
        return updatedData
      }
      case "ExpectColumnMaxToBeBetween": {
        const maxMetricValue = getMetricValue(metric?.valueRangeMax, metric?.valueRangeMaxUnion as MetricValueUnion)

        const updatedData = cloneDeep(data as JSONFormData<typeof jsonSchemas.expectColumnMaxToBeBetween.schema>)
        if (maxMetricValue) {
          updatedData.min_value = maxMetricValue
          updatedData.max_value = maxMetricValue
        }
        return updatedData
      }
      case "ExpectColumnValuesToBeNull": {
        const updatedData = cloneDeep(data as JSONFormData<typeof jsonSchemas.expectColumnValuesToBeNull.schema>)
        if (metricRun?.rowCount > 0 && metric?.nullCount) {
          updatedData.mostly = metric?.nullCount / metricRun?.rowCount
        }
        return updatedData
      }
      case "ExpectColumnValuesToNotBeNull": {
        const updatedData = cloneDeep(data as JSONFormData<typeof jsonSchemas.expectColumnValuesToNotBeNull.schema>)
        if (metricRun?.rowCount > 0 && metric?.nullCount) {
          updatedData.mostly = 1 - metric?.nullCount / metricRun?.rowCount
        }
        return updatedData
      }
      case "ExpectColumnValuesToBeOfType": {
        const updatedData = cloneDeep(data as JSONFormData<typeof jsonSchemas.expectColumnValuesToBeOfType.schema>)
        if (metric?.columnDataType) {
          updatedData.type_ = metric.columnDataType?.split("(")[0]
        }
        return updatedData
      }
      case "ExpectColumnValuesToBeInTypeList": {
        const updatedData = cloneDeep(data as JSONFormData<typeof jsonSchemas.expectColumnValuesToBeInTypeList.schema>)
        if (
          metric?.columnDataType &&
          updatedData.type_list &&
          updatedData.type_list instanceof Array &&
          updatedData.type_list.length > 0
        ) {
          updatedData.type_list[0] = metric.columnDataType?.split("(")[0]
        }
        return updatedData
      }
      default: {
        const updatedData = cloneDeep(data)
        if (metric?.columnName) {
          updatedData.column = metric.columnName
          return updatedData
        }
        return null
      }
    }
  }
}

const getMetricValue = (metricValue: number | null | undefined, metricValueUnion: MetricValueUnion) => {
  return metricValueUnion?.__typename === "MetricValueFloatType"
    ? (metricValueUnion.floatValue ?? null)
    : (metricValueUnion?.stringValue ?? null)
}

export function handleChangeWithCDMValues(
  expectationClass: string,
  previousValue?: Record<string, unknown>,
  newValue?: ExpectationFormData,
  config?: DataAssetWithLatestMetricRunConfig,
) {
  const columnPreviousValue = getColumnName(previousValue)
  const columnNewValue = getColumnName(newValue)

  if (columnNewValue && columnNewValue !== columnPreviousValue) {
    if (isColumnExpectationFormData(newValue)) {
      return getDataWithCDMValues(expectationClass, newValue, config)
    }
  }
  return newValue
}

function getColumnName(value?: ExpectationFormData): string | null {
  return get(value, "kwargs.column") ?? get(value, "column") ?? null
}
