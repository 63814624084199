/* eslint-disable react-refresh/only-export-components */ // FIXME
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react"
import { ControlProps, JsonFormsRendererRegistryEntry, rankWith, scopeEndsWith, or } from "@jsonforms/core"
import { Metric } from "src/api/graphql/graphql"
import { useMemo } from "react"
import { NumericControl, TextControl } from "@great-expectations/jsonforms-antd-renderers"

export const MinMaxRegistryEntry: JsonFormsRendererRegistryEntry = {
  tester: rankWith(
    90, //increase rank as needed
    or(scopeEndsWith("#/properties/min_value"), scopeEndsWith("#/properties/max_value")),
  ),
  renderer: withJsonFormsControlProps(MinMaxControl),
}

// This control is designed to be used with min_value and max_value kwargs that can be either a number or a date string.
// The control will conditionally render a TextControl or NumericControl depending on the data type of the selected column (DATETIME, DATE, DECIMAL, etc).
function MinMaxControl(props: ControlProps) {
  const ctx = useJsonForms()
  const data = ctx.core?.data // this object contains the selected column name
  const columnDataType = useMemo(
    () =>
      props.config.dataAssetWithLatestMetricRun?.latestMetricRun?.metrics.find((metric: Metric) => {
        return metric.columnName === data?.column
      })?.columnDataType,
    [props.config.dataAssetWithLatestMetricRun?.latestMetricRun?.metrics, data?.column],
  )
  const isTimeBasedTypeRegex = "(TIME|DATE)"

  return columnDataType?.match(isTimeBasedTypeRegex) ? <TextControl {...props} /> : <NumericControl {...props} />
}
