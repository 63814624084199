import { sortBy } from "lodash-es"
import { SlackAction } from "src/Checkpoints/EditCheckpointDrawer"
import { Checkpoint } from "src/Checkpoints/useQueryCheckpoints"

const SLACK_ACTION_NAME = "SlackNotificationAction"

export function getSlackActionsForSelectedCheckpoint(
  selectedCheckpointId: string,
  checkpoints?: Array<Checkpoint>,
): SlackAction[] | [] {
  const actions =
    checkpoints
      ?.find((checkpoint) => {
        return checkpoint?.id === selectedCheckpointId
      })
      ?.actionList?.map<SlackAction>((action) => JSON.parse(action || "{}"))
      .filter((action) => {
        return action?.action?.class_name === SLACK_ACTION_NAME
      }) || []
  return sortBy(actions, "name")
}
