import React from "react"
import { Checkbox, Col, Form, Row } from "antd"
import { StyledCol, StyledInput } from "src/ui/OnboardingSurvey/styles"

const tools = [
  { value: "Python", label: "Python" },
  {
    value: "SQL",
    label: "SQL",
  },
  { value: "Git (Github, Gitlab, etc)", label: "Git (Github, Gitlab, etc)" },
  { value: "Notebooks (Jupyter, Streamlit, Hex, etc)", label: "Notebooks (Jupyter, Streamlit, Hex, etc)" },
  {
    value: "Data orchestrators (Airflow, Dagster, Prefect, etc.)",
    label: "Data orchestrators (Airflow, Dagster, Prefect, etc.)",
  },
  {
    value: "Tools for containerizing code (Docker, Kubernetes, Argo, etc.)",
    label: "Tools for containerizing code (Docker, Kubernetes, Argo, etc.)",
  },
  {
    value: "Data catalog (Alation, Datahub, OpenMetadata, etc.)",
    label: "Data catalog (Alation, Datahub, OpenMetadata, etc.)",
  },
  { label: "Other", value: "Other", colProps: { span: 4 } },
]

interface ToolsUtilsProps {
  showOther: boolean
}

export const ToolsUtils: React.FC<ToolsUtilsProps> = ({ showOther }) => {
  return (
    <Row justify="start">
      {tools.map(({ value, label, colProps }) => (
        <StyledCol key={value} span={24} {...colProps}>
          <Checkbox value={value}>{label}</Checkbox>
        </StyledCol>
      ))}
      {showOther && (
        <Col style={{ marginTop: "2px" }} span={10}>
          <Form.Item
            style={{ height: "0px" }}
            name="otherToolsLanguages"
            rules={[{ required: showOther, message: "Answer is required" }]}
          >
            <StyledInput autoComplete="off" aria-label="Tools languages input" />
          </Form.Item>
        </Col>
      )}
    </Row>
  )
}
