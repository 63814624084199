import { message } from "antd"
import styled from "styled-components"
import { useMutation } from "@apollo/client"
import { useState } from "react"
import { QUICK_MESSAGE_DURATION } from "src/common/config"
import { ALERT_DELETE_SUCCESS_MESSAGE } from "src/Checkpoints/words"
import { graphql } from "src/api/graphql/gql"
import { Button } from "src/ui/Button/Button"
import { DeleteResourceModal } from "src/ui/DeleteResourceModal/DeleteResourceModal"
import { useOrganizationSlug } from "src/organizations/useOrganizationSlug"

const DeleteButton = styled(Button)`
  margin: ${({ theme }) => `0 ${theme.spacing.scale.xxxs}`};
`

type DeleteExpectationSuiteButtonProps = {
  expectationSuiteId: string
  expectationSuiteName: string
  redirectPath?: string | null
}

const DeleteExpectationSuiteDocument = graphql(`
  mutation DeleteExpectationSuite($id: UUID!) {
    deleteExpectationSuiteV2(id: $id) {
      id
    }
  }
`)

export const DeleteExpectationSuiteButton = ({
  expectationSuiteId,
  expectationSuiteName,
  redirectPath,
}: DeleteExpectationSuiteButtonProps) => {
  const [isVisible, setIsVisible] = useState(false)
  const { navigateInOrg } = useOrganizationSlug()
  const [deleteExpectationSuite, { loading: deleteExpectationSuiteActionLoading, error, reset }] = useMutation(
    DeleteExpectationSuiteDocument,
    {
      onCompleted: () => {
        message.success(ALERT_DELETE_SUCCESS_MESSAGE, QUICK_MESSAGE_DURATION)
        if (redirectPath) {
          navigateInOrg(redirectPath)
        }
        setIsVisible(false)
      },
      // onError is needed in order to pick up the mutation error status
      onError: () => undefined,
      update: (cache, { data }) => {
        if (data?.deleteExpectationSuiteV2) {
          cache.evict({ id: cache.identify(data.deleteExpectationSuiteV2) })
        }
      },
    },
  )

  return (
    <>
      <DeleteButton
        type="text"
        title="Delete Suite"
        aria-label="Delete Suite"
        icon="trash"
        onClick={() => setIsVisible(true)}
      />
      <DeleteResourceModal
        open={isVisible}
        onCancel={() => {
          reset()
          setIsVisible(false)
        }}
        onOk={() => deleteExpectationSuite({ variables: { id: expectationSuiteId } })}
        confirmLoading={deleteExpectationSuiteActionLoading}
        resourceTitle="Expectation Suite"
        alternateResourceTitle="Expectation Suite and its validation results"
        name={expectationSuiteName}
        errorMessage={error?.message ?? undefined}
      />
    </>
  )
}
